import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocaleService } from '../api/locale.service';
import { LoggerService } from '../api/logger.service';
import { RequestService } from '../api/request.service';
import { LocatorService } from './locator.service';
import { PlatformService } from '../api/platform.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/app-settings';

type GoogleTranslateApiResponse = {
  data: {
    translations: {
      detectedSourceLanguage: string,
      model: string,
      translatedText: string,
    }[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly API_GoogleTranslate = 'https://translation.googleapis.com/language/translate/v2';
  public availableLanguages = [];
  languages = [];
  selectedLanguage = {
    value: 'en',
    description: 'English',
  };
  private onTranslationsChangeSource = new Subject<void>();
  public onTranslationsChange = this.onTranslationsChangeSource.asObservable();

  private get req() { return LocatorService.injector.get(RequestService); }

  constructor(
    private l: LoggerService,
    private locale: LocaleService,
    private http: HttpClient,
  ) {
    l.log('+', 'LanguageService');
  }

  /** @param keyword __string__ Raktažodis, kurio vertimą reikia gauti.
   *  @returns Vertimas, jeigu buvo rastas. Kitu atveju raktinis žodis.
   */
  get(keyword): string {
    const translation = localStorage.getItem(keyword);
    if (translation === null) {
      return keyword;
    }
    return translation;
  }

  public hasLanguage(): boolean {
    const lang = localStorage.getItem('lang');
    return lang !== null && lang !== '';
  }

  public saveUserLanguage() {
    // const rs = this.injector.get(ApiRequestService);
    // rs.post('language/set', { language: localStorage.getItem('lang') }, true).subscribe(
    //   data => {
    //   },
    //   error => {
    //     this.l.log('Klaida nustatant kalba ' + error);
    //   }
    // );
  }

  public applyTranslations(translationString: string, translationVersion: string, languageCode: string) {
    const translations = JSON.parse(translationString);
    this.applyTranslationsInternal(translations, translationVersion, languageCode);
  }

  public applyTranslationsInternal(translations: { [key: string]: string }, translationVersion: string, languageCode: string) {
    const keywords = Object.keys(translations);
    for (const iKeyword of keywords) {
      let translation: string = translations[iKeyword];
      translation = translation.replace(/\&apos;/g, '\'');
      localStorage.setItem(iKeyword, translation.replace(/\&quot;/g, '"'));
    }
    localStorage.setItem('lang_version', translationVersion);
    localStorage.setItem('lang', languageCode);
    this.selectedLanguage.value = languageCode;
    this.selectedLanguage.description = this.locale.getLocaleName(languageCode);
    this.locale.locale = languageCode;

    this.l.log('Apdorota raktažodžių: ' + keywords.length, 'Lang');
    this.onTranslationsChangeSource.next();
  }

  public async setLanguage(languageCode: string): Promise<void> {
    const res = await this.req.misc.translations({ language: languageCode }).toPromise();
    if (!res.success) { throw new Error('Failed to get translations'); }
    this.applyTranslations(res.translations, res.version, res.code);
    const platform = LocatorService.injector.get(PlatformService);
    if (platform.isAndroid()) {
      try {
        platform.androidHandler().languageUpdated(this.selectedLanguage.value);
      } catch (e) {
        platform.androidHandler().languageUpdated();
      }
    } else if (platform.isApple()) {
      platform.appleHandler().languageUpdated.postMessage(this.selectedLanguage.value);
    }
  }

  public populateAvailablLanguagesList() {
    const savedLanguages = localStorage.getItem('language-list');
    if (savedLanguages !== null) {
      this.availableLanguages = JSON.parse(savedLanguages);
    }
  }

  public translateTextsToUserLanguage(texts: string[], targetLanguage: string): Promise<string[]> {
    return new Promise((resolve) => {
      if(targetLanguage === 'en') {
        resolve(texts);
        return;
      }

      return this.translateViaGoogleTranslate(texts, targetLanguage).then(resolve);
    });
  }

  private translateViaGoogleTranslate(texts: string[], targetLanguage: string): Promise<string[]> {
    return new Promise((resolve) => {
      if(!AppSettings.translationApiKey) {
        resolve(texts);
        return;
      }

      let params = new HttpParams().set('target', targetLanguage).set('key', AppSettings.translationApiKey);
      texts.forEach(text => params = params.append('q', text));

      return this.http.post<GoogleTranslateApiResponse>(this.API_GoogleTranslate, null, { params }).subscribe({
        next: response => {
          const translations = response.data.translations;
          resolve(translations.map(t => t.translatedText));
        },
        error: () => resolve(texts),
      });
    });
  }

}
