import permission from './permission';
import company from './company';
import user from './user';
import tag from './tag';
import system from './system';
import deviceSetupTemplates from './device-setup-templates';
import settings from './settings';
import config from './../requests/config';
import login from './login';
import language from './language';
import subscriptions from './subscriptions';
import changelog from './changelog';
import cloudNotifications from './cloud-notifications';
import dashboard from './dashboard';

export default {
    permission,
    company,
    user,
    tag,
    system,
    deviceSetupTemplates,
    settings,
    config,
    login,
    language,
    subscriptions,
    changelog,
    cloudNotifications,
    dashboard,
};
