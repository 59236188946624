import { Injectable } from '@angular/core';
import { Changelog, CreateChangelogRequest, CreateChangelogResponse, DeleteChangelogRequest, GetChangelogsResponse, UpdateChangelogRequest } from 'src/api/v3/changelog';
import requests from 'src/api/v3/requests';
import { CloudNotificationService } from './cloud-notification.service';
import { AuthService } from '../api/auth.service';
import { Subscription } from 'rxjs';
import { ApiTranslation } from 'src/api/v3/language';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {
  public changelogs:                  Map<number, Changelog> = new Map();
  public showTranslationForIds:       Set<number> = new Set();
  public translations:                Map<number, ApiTranslation> = new Map();
  public isFetchingChangelogs:        boolean = false;
  public initialFetchComplete:        boolean = false;
  public hasMore:                     boolean = true;
  private loadedCount:                number = 0;
  private cleanUpSubscriber:          Subscription;
  private changelogsUpdateSubscriber: Subscription;

  constructor(
    private cloudNotificationService: CloudNotificationService,
    private auth: AuthService,
  ) {
    this.cleanUpSubscriber = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.resetServiceParams();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
    this.changelogsUpdateSubscriber?.unsubscribe();
  }

  public fetchChangelog(id: number): Promise<void> {
    return new Promise((resolve) => {
      requests.changelog.getChangelog({ id }).subscribe({
        next: (response) => this.handleChangelogResponse(response, false),
        complete: () => resolve(),
        error: () => resolve(),
      });
    });
  }

  public fetchChangelogs(): void {
    if(this.isFetchingChangelogs) return;

    this.isFetchingChangelogs = true;
    const req = { loaded: this.loadedCount };
    requests.changelog.getChangelogs(req).subscribe({
      next: (response) => this.handleGetChangelogsResponse(response),
      complete: () => {
        this.isFetchingChangelogs = false;
        this.initialFetchComplete = true;
      },
      error: () => this.isFetchingChangelogs = false,
    });
  }

  public async create(req: CreateChangelogRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.changelog.createChangelog(req).subscribe({
        next: (response) => {
          this.handleChangelogResponse(response, true);
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  public async update(req: UpdateChangelogRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.changelog.updateChangelog(req).subscribe({
        next: (response) => {
          this.handleChangelogResponse(response, true);

          this.handleCloudNotificationInChangelogUpdateRequest(req);
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  public async delete(req: DeleteChangelogRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.changelog.deleteChangelog(req).subscribe({
        next: (response) => {
          if(response.success) {
            const changelog = this.changelogs.get(req.id);
            if(changelog.cloud_notification) {
              this.cloudNotificationService.deleteNotificationFromSet(changelog.cloud_notification);
            }
            this.changelogs.delete(req.id);
          }
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  private handleGetChangelogsResponse(response: GetChangelogsResponse) {
    if(response.success) {
      response.data.forEach((changelog) => {
        if(changelog.cloud_notification) {
          this.cloudNotificationService.setActiveNotification(changelog.cloud_notification, true);
        }
        this.changelogs.set(changelog.id, changelog);
      });
      this.loadedCount += response.data.length;
      this.hasMore = response.hasMore;
    }
  }

  private handleChangelogResponse(response: CreateChangelogResponse, sort: boolean = false) {
    if(!response.success) return;

    const changelog = response.data;
    this.handleChangelog(changelog, sort);
  }

  private handleChangelog(changelog: Changelog, sort: boolean = false, handleNotification: boolean = true) {
    if(handleNotification) {
      this.handleChangelogNotification(changelog);
    } else {
      const existingChangelog = this.changelogs.get(changelog.id);
      const cloudNotification = existingChangelog?.cloud_notification;
      if(cloudNotification) {
        changelog.cloud_notification = cloudNotification;
      }
    }
    this.changelogs.set(changelog.id, changelog);
    if(!sort) return;

    const sortedChangelogs = new Map([...this.changelogs.entries()].sort((a, b) => b[1].date - a[1].date));
    this.changelogs = sortedChangelogs;
  }

  private handleChangelogNotification(changelog: Changelog) {
    if(!changelog.cloud_notification || !changelog.cloud_notification.active) return;

    this.cloudNotificationService.setNotification(changelog.cloud_notification, true);
  }

  private handleCloudNotificationInChangelogUpdateRequest(req: UpdateChangelogRequest) {
    const changelog = this.changelogs.get(req.id);
    if(!changelog) return;

    if(changelog.cloud_notification && !req.cloud_notification_enabled) {
      this.cloudNotificationService.deleteNotificationFromSet(changelog.cloud_notification);
    }
  }

  private resetServiceParams() {
    this.changelogs.clear();
    this.isFetchingChangelogs = false;
    this.initialFetchComplete = false;
    this.hasMore = true;
    this.loadedCount = 0;
  }

  public updateChangelogParams(changelog: Changelog) {
    if(this.changelogs.has(changelog.id)) {
      this.handleChangelog(changelog, true, false);
    }
  }

}
