import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo, AuthService } from 'src/app/api/auth.service';
import { LoggerService } from 'src/app/api/logger.service';
import { RegionService } from 'src/app/api/region.service';
import { SystemsService } from 'src/app/services/systems.service';
import { LoginService } from '../../login.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { LocatorService } from 'src/app/services/locator.service';
import requests from 'src/api/v3/requests';
import { TagService } from 'src/app/api/tag.service';
import { AppSettings } from 'src/app-settings';
import { GlobalService } from 'src/app/services/global.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-account-switcher',
    templateUrl: './account-switcher.component.html',
    styleUrls: ['./account-switcher.component.scss'],
    imports: [CommonModule]
})
export class AccountSwitcherComponent implements OnInit, OnChanges {
  public readonly AppSettings = AppSettings;
  private readonly routesAllowingActiveAccountSelect = ['/login/accounts'];
  @Output() selected = new EventEmitter<void>();
  @Input() public forgetMode = false;
  public availableAccounts: AccountInfo[] = [];
  public tallView = false;

  constructor(
    public auth: AuthService,
    private login: LoginService,
    private router: Router,
    public region: RegionService,
    private systems: SystemsService,
    private l: LoggerService,
    public us: UserService,
    private ls: LanguageService,
    private api: ApiRequestService,
    private msg: MessageboxService,
    private g: GlobalService,
  ) {
    if(this.auth.mergedRegionAccounts.length === 0) {
      this.auth.loadMergedRegionAccounts();
    }
  }

  public readonly trans = (key: string) => this.ls.get(key);

  ngOnInit(): void {
    this.tallView = !this.routesAllowingActiveAccountSelect.includes(this.router.url);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.forgetMode) {
      if(this.forgetMode) {
        this.availableAccounts = this.auth.availableAccounts;
      }
    };
  }

  public async click(account: AccountInfo) {
    if (this.forgetMode) {
      this.auth.forgetAccount(account);
      this.availableAccounts.splice(this.availableAccounts.indexOf(this.availableAccounts.find(a => a.id === account.id && a.region.id === account.region.id)), 1);
      return;
    }
    await this.switchAccount(account);
  }

  public async switchAccount(account: AccountInfo) {
    this.msg.close();
    if(this.us.currentUser) {
      if (account.active && account.region.id === this.region.ActiveRegion.id) {
        this.selected.emit();
        if(this.routesAllowingActiveAccountSelect.includes(this.router.url)) {
          await this.router.navigate(this.g.resolveRootRoute());
        }
        return;
      }
    }
    this.us.clearUserData();
    if (!account.token) {
      this.login.email = account.email;
      this.login.password = '';
      this.selected.emit();
      this.auth.switchAccount(account);
      this.router.navigateByUrl('/login/password');
      return;
    }
    this.auth.switchAccount(account);
    try {
      await this.doRefresh();
    } catch (e) {
      this.l.log('Refresh failed', 'AccountSwitcherComponent', { e });
    }
    this.selected.emit();
    this.region.updateActiveRegionName(this.us.userRegions);
    await this.router.navigate(this.g.resolveRootRoute());
  }

  private async doRefresh() {
    this.l.log('Refresh', 'AccountSwitcherComponent');
    const result = await requests.user.me({ systemId: 0, areaId: 0, getSystemStatus: false }).toPromise();
    if (result.success) {
      const { success, lastSystem, tags, ...user } = result;
      this.us.isCurrentUserFetched = true;
      this.us.setCurrentUserFromRaw(user);
      const tagService = LocatorService.injector.get(TagService);
      tags.forEach(t => tagService.ingestTag(t));
      this.auth.registerCurrentUserData.next(this.us.currentUser);
      this.us.change();
      this.us.allUsersLoaded = false;
      if(this.us.currentUser.language !== localStorage.getItem('lang')) {
        this.api.post('/translations', {
          language: this.us.currentUser.language,
          version: '-1',
        }, false).subscribe((data) => {
          if (data.success) {
            this.ls.selectedLanguage.value = data.code;
            this.ls.applyTranslations(data.translations, data.version, data.code);
            this.api.post('/language/set', { language: this.ls.selectedLanguage.value }, true);
            if ( !this.us.currentUser.permissions.permissions.systems.view && !this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
              this.systems.loadSystems();
            }
          }
        });
      } else {
        if ( !this.us.currentUser.permissions.permissions.systems.view && !this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
          this.systems.loadSystems();
        }
      }
    } else {
      this.l.log('Refresh failed', 'AccountSwitcherComponent', { result });
      return;
    }
  }
}
