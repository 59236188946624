import { Injectable } from '@angular/core';
import requests from 'src/api/v3/requests';
import { DashboardWidgetTimeframe, DashboardWidgetTimeframeTimestamp, DashboardWidgetType } from '../models/dashboard-types';
import { DashboardWidgetData, GetWidgetDataResponse, GetWidgetDataResponseData, SystemStatusesWidgetData } from 'src/api/v3/dashboard';
import { Observable, Subject, Subscription } from 'rxjs';
import { CurrentUserData } from 'src/api/v3/common';
import { AppSettings } from 'src/environments/environment';
import { PermissionRole } from 'src/api/v3/permission';
import { AuthService } from '../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private cleanUpSubscriber:      Subscription;
  private widgetDataChange:       Subject<DashboardWidgetType> = new Subject<DashboardWidgetType>();
  public loadingWidgets:          Set<DashboardWidgetType> = new Set();
  public widgets:                 Map<DashboardWidgetType, DashboardWidgetData> = new Map();
  public timeframeTimestamps:     Map<DashboardWidgetTimeframe, DashboardWidgetTimeframeTimestamp> = new Map();
  public onWidgetDataChange:      Observable<DashboardWidgetType> = this.widgetDataChange.asObservable();

  constructor(auth: AuthService) {
    this.populateTimeframeTimestamps();
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  private clear() {
    this.loadingWidgets.clear();
    this.widgets.clear();
  }

  public canViewDashboard(currentUser: CurrentUserData): boolean {
    return AppSettings.dashboardEnabled && [PermissionRole.Company, PermissionRole.SuperAdmin].includes(currentUser?.permissions?.role);
  }

  public getWidgetData(types: DashboardWidgetType[]): Promise<GetWidgetDataResponse> {
    this.addWidgetTypesToLoadingWidgetsSet(types);

    return new Promise((resolve, reject) => {
      requests.dashboard.getWidgetData({ types }).subscribe({
        next: (response) => {
          if(response.success) {
            this.handleGetWidgetDataResponse(response.data);
          }
          this.removeWidgetTypesFromLoadingWidgetsSet(types);
          resolve(response);
        },
        error: () => {
          this.removeWidgetTypesFromLoadingWidgetsSet(types);
          reject();
        },
      });
    });
  }

  public getCompanySystemStatusWidgetData(company_id: number): Promise<SystemStatusesWidgetData | false> {
    return new Promise((resolve) => {
      requests.dashboard.getWidgetData({ types: [DashboardWidgetType.SYSTEM_STATUS], company_id }).subscribe({
        next: (response) => {
          if(response.success) {
            resolve(response.data[DashboardWidgetType.SYSTEM_STATUS] as SystemStatusesWidgetData);
          }
          resolve(false);
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }

  private addWidgetTypesToLoadingWidgetsSet(types: DashboardWidgetType[]) {
    types.forEach((type) => {
      this.loadingWidgets.add(type);
    });
  }

  private removeWidgetTypesFromLoadingWidgetsSet(types: DashboardWidgetType[]) {
    types.forEach((type) => {
      this.loadingWidgets.delete(type);
    });
  }

  private handleGetWidgetDataResponse(data: GetWidgetDataResponseData): void {
    Object.keys(data).forEach((type) => {
      this.widgets.set(type as DashboardWidgetType, data[type]);
      this.widgetDataChange.next(type as DashboardWidgetType);
    });
  }

  private populateTimeframeTimestamps(): void {
    const now = Date.now();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.TODAY, {
      start: today.getTime() / 1000,
      end: now / 1000,
    });

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.YESTERDAY, {
      start: yesterday.getTime() / 1000,
      end: today.getTime() / 1000,
    });

    const thisWeek = new Date(today);
    thisWeek.setDate(thisWeek.getDate() - thisWeek.getDay());
    thisWeek.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.THIS_WEEK, {
      start: thisWeek.getTime() / 1000,
      end: now / 1000,
    });

    const lastWeek = new Date(thisWeek);
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.LAST_WEEK, {
      start: lastWeek.getTime() / 1000,
      end: thisWeek.getTime() / 1000,
    });

    const thisMonth = new Date(today);
    thisMonth.setDate(1);
    thisMonth.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.THIS_MONTH, {
      start: thisMonth.getTime() / 1000,
      end: now / 1000,
    });

    const lastMonth = new Date(thisMonth);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.LAST_MONTH, {
      start: lastMonth.getTime() / 1000,
      end: thisMonth.getTime() / 1000,
    });

    const thisYear = new Date(today);
    thisYear.setMonth(0, 1);
    thisYear.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.THIS_YEAR, {
      start: thisYear.getTime() / 1000,
      end: now / 1000,
    });

    const lastYear = new Date(thisYear);
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setHours(0, 0, 0, 0);

    this.timeframeTimestamps.set(DashboardWidgetTimeframe.LAST_YEAR, {
      start: lastYear.getTime() / 1000,
      end: thisYear.getTime() / 1000,
    });
  }
  
}
