import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TBackgroundType } from 'src/app/models/background-types';
import { LanguageAware } from '../language-aware';
import { HomeOutputsComponent } from '../home/home-outputs/home-outputs.component';

@Component({
    selector: 'app-quick-outputs',
    templateUrl: './quick-outputs.component.html',
    styleUrls: ['./quick-outputs.component.scss'],
    imports: [HomeOutputsComponent]
})
export class QuickOutputsComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.outputs'),
      backUrl: '*',
      showSettings: true,
    });
    localStorage.setItem('ref', 'quick');
    this.headerBar.onActionButtonClicked = () => {
      this.router.navigate([...this.g.getHomeUrl(), 'settings', 'outputs']);
    };
  }

  ngOnInit(): void {}
}
