import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { DateTime } from 'luxon';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { BackButtonComponent } from 'src/app/ui/back-button/back-button.component';
import { DropdownComponent, DropdownItem } from "../../../../ui/dropdown/dropdown.component";
import { StringFormInputComponent } from "../../../../ui/form-input/form-input.component";
import { NameValueComponent } from "../../../../components/name-value/name-value.component";
import { ToggleButtonComponent } from "../../../../components/toggle-button/toggle-button.component";
import { DatetimeInputComponent } from 'src/app/ui/datetime-input/datetime-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/permission';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { Subscription } from 'rxjs';
import { CloudNotificationService } from 'src/app/services/cloud-notification.service';
import { DateService } from 'src/app/services/date.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { Changelog, ChangelogContentItem, CreateChangelogRequest, UpdateChangelogRequest } from 'src/api/v3/changelog';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { CASCADE_FLAG, CloudNotification, CloudNotificationType, CreateCloudNotificationRequest, UpdateCloudNotificationRequest, VisibilityValue } from 'src/api/v3/cloud-notifications';
import { DesktopFooterComponent, FooterButton } from "../../../../ui/desktop-footer/desktop-footer.component";
import { TeleportModule } from 'src/app/teleport/teleport.module';

type Type = 'changelog' | 'notification';

@Component({
    selector: 'app-changelog-item',
    imports: [BackButtonComponent, CommonModule, InlineSVGModule, DropdownComponent, StringFormInputComponent, NameValueComponent, ToggleButtonComponent, DatetimeInputComponent, DesktopFooterComponent, TeleportModule],
    templateUrl: './changelog-item.component.html',
    styleUrl: './changelog-item.component.scss'
})
export class ChangelogItemComponent extends LanguageAware {
  public readonly desktopView:        boolean =  this.platform.isDesktopView();
  public readonly isNew:              boolean = this.ar.routeConfig.path.endsWith('new');
  public itemInEditId:                number = -1;
  public changelogItemTypes:          DropdownItem<string>[] = [];
  public notificationItemTypes:       DropdownItem<string>[] = [];
  public typesList:                   DropdownItem<Type>[] = [];
  public visibilityValueList:         DropdownItem<number>[] = [];
  public type:                        Type | undefined;
  public startDate:                   DateTime = DateTime.local().startOf('day').plus({ days: 0 });
  public expirationDate:              DateTime = DateTime.local().startOf('day').plus({ days: 7 });
  public changelogTextItems:          ChangelogContentItem[] = [];
  public notificationText:            string = '';
  public changelogHeadline:           string = '';
  public videoUrl:                    string = '';
  public notificationType:            CloudNotificationType = 'info';
  public toasterNotificationEnabled:  boolean = false;
  public pushNotificationEnabled:     boolean = false;
  public customHeadlineEnabled:       boolean = false;
  public visibilityValueCascaded:     boolean = false;
  public isRequestLoading:            boolean = false;
  public visibilityValue:             VisibilityValue = VisibilityValue.SystemMaster;
  private visibility:                 number | null = null;
  private subscriptions:              Subscription[] = [];
  public footerButtons:               FooterButton[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private ar: ActivatedRoute,
    private router: Router,
    private messagebox: MessageboxService,
    private changelogService: ChangelogService,
    private cloudNotificationService: CloudNotificationService,
    private dateService: DateService,
    private editService: EditSystemService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.setInitialParams();
  }
  
  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
    this.cloudNotificationService.isVisiblePreview = false;
    this.cloudNotificationService.previewNotification = null;
    if(!this.isNew) {
      this.editService.endComponentEdit(TEditableComponent.Changelog);
      this.editService.endComponentEdit(TEditableComponent.CloudNotification);
    }
  }

  public get showNotificationPreview(): boolean {
    return this.cloudNotificationService.isVisiblePreview;
  }

  public get isSuperAdmin(): boolean {
    return this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin;
  }

  public get notificationPermissions(): { canCreate: boolean, canDelete: boolean } {
    return {
      canCreate: this.isSuperAdmin,
      canDelete: this.isSuperAdmin,
    };
  }

  public get changelogPermissions(): { canCreate: boolean, canDelete: boolean } {
    return {
      canCreate: this.isSuperAdmin,
      canDelete: this.isSuperAdmin
    };
  }

  public get canDeleteItem(): boolean {
    return !this.isNew && (this.isChangelog ? this.changelogPermissions.canDelete : this.notificationPermissions.canDelete);
  }

  public get isChangelog(): boolean {
    return this.type === 'changelog';
  }

  public get shouldStartNow(): boolean {
    return this.startDate <= DateTime.local();
  }

  public get showVisibilitySelection(): boolean {
    return (this.isChangelog && (this.pushNotificationEnabled || this.toasterNotificationEnabled)) || !this.isChangelog;
  }

  public get changelogDate(): string {
    return this.formatDate(this.startDate.toSeconds());
  }

  public get userLanguage(): string {
    return this.us.currentUser.language ?? 'en';
  }

  private get contentText(): string | null {
    return this.isChangelog ? this.changelogHeadline : this.notificationText;
  }

  private async setInitialParams() {
    this.setHeaderParams();
    this.setFooterParams();
    this.setDropdownItems();
    
    if(this.isNew) {
      this.setInitialNewItemParams();
    } else {
      this.setInitialExistingItemParams();
    }

    this.setVisibilityValueList();
  }

  private setInitialNewItemParams() {
    this.setTypeParams();
    this.changelogTextItems.push({ type: 'new', text: '' });
  }

  private setInitialExistingItemParams() {
    const changelogId = this.ar.snapshot.paramMap.get('changelogId');
    const changelogIdInt = parseInt(changelogId, 10);
    if(!isNaN(changelogIdInt)) {
      this.setExistingChangelogParams(changelogIdInt);
    } else {
      const notificationId = this.ar.snapshot.paramMap.get('notificationId');
      const notificationIdInt = parseInt(notificationId, 10);
      if(!isNaN(notificationIdInt)) {
        this.setExistingNotificationParams(notificationIdInt);
      }
    }
  }

  private async setExistingChangelogParams(id: number) {
    let changelog = this.editService.getEditableComponent(TEditableComponent.Changelog);
    if(changelog === null) {
      await this.changelogService.fetchChangelog(id);
      changelog = this.changelogService.changelogs.get(id);
    }
    if(!changelog) return;
    this.setComponentParamsForChangelogType(changelog);
  }

  private async setExistingNotificationParams(id: number) {
    let notification = this.editService.getEditableComponent(TEditableComponent.CloudNotification);
    if(notification === null) {
      notification = await this.cloudNotificationService.fetchNotification(id);
    }
    if(!notification) {
      this.router.navigate([...this.g.resolveRootRoute(), 'help', 'changelog']);
      return;
    };
    this.setComponentParamsForNotificationType(notification);
  }

  private setComponentParamsForChangelogType(changelog: Changelog) {
    this.itemInEditId = changelog.id;
    this.type = 'changelog';
    this.startDate = DateTime.fromSeconds(changelog.date);
    this.expirationDate = null;
    this.changelogTextItems = changelog.content;
    this.changelogHeadline = changelog.headline ?? '';
    this.videoUrl = changelog.video_url ?? '';
    this.toasterNotificationEnabled = changelog.cloud_notification && changelog.cloud_notification.active;
    this.pushNotificationEnabled  = false;
    this.customHeadlineEnabled = changelog.headline !== null;
    if(changelog.cloud_notification) {
      this.visibilityValue = changelog.cloud_notification.visibility & ~CASCADE_FLAG;
      this.visibilityValueCascaded = (changelog.cloud_notification.visibility & CASCADE_FLAG) !== 0;
      this.setVisibilityValue();
    }
  }

  private setComponentParamsForNotificationType(notification: CloudNotification) {
    this.itemInEditId = notification.id;
    this.type = 'notification';
    this.startDate = DateTime.fromSeconds(notification.valid_from);
    this.expirationDate = notification.valid_until === null ? null : DateTime.fromSeconds(notification.valid_until);
    this.notificationText = notification.content ?? '';
    this.notificationType = notification.type;
    this.videoUrl = notification.video_url ?? '';
    this.pushNotificationEnabled  = false;
    this.visibilityValue = notification.visibility & ~CASCADE_FLAG;
    this.visibilityValueCascaded = (notification.visibility & CASCADE_FLAG) !== 0;
    this.setVisibilityValue();
  }

  private openSaveChangesMessageboxPrompt(): void {
    const headerText = this.trans('general.confirm');
    const messageContent = this.isChangelog ? this.trans('help.changelog.createPrompt') : this.trans('help.cloudNotifications.createPrompt');
    this.messagebox.open({
      buttons: this.messagebox.buttons.CustomButton1Cancel,
      button1Text: this.trans('help.postIt'),
      headerText,
      messageContent,
    }, (messagebox) => {
      const button1Clicked = messagebox.button1Clicked.subscribe(() => {
        this.saveChanges();
      });
      this.subscriptions.push(button1Clicked);
    });
  }

  public startDateChanged(date: DateTime) {
    this.startDate = date;
    this.onValueChanged();
  }

  public expirationDateChanged(date: DateTime) {
    this.expirationDate = date;
    this.onValueChanged();
  }

  public onAddTextItem() {
    this.changelogTextItems.push({ type: 'new', text: '' });
  }

  public onVisibilityValueChange(value: VisibilityValue) {
    this.visibilityValue = value;

    this.setVisibilityValue();
    this.onValueChanged();
  }

  public onVisibilityCascadedValueChange(value: boolean) {
    this.visibilityValueCascaded = value;

    this.setVisibilityValue();
    this.onValueChanged();
  }

  public onTypeChange() {
    this.resetPreviewParam();
    this.onValueChanged();
  }

  public onDelecteChangelogTextItemClick(index: number) {
    this.changelogTextItems.splice(index, 1);
  }

  private setHeaderParams() {
    if( !this.desktopView ) {
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('help.changelog.title'),
      });
    }
  }

  private setFooterParams() {
    if(this.desktopView) {
      this.setFooterButtons();
    } else {
      this.footerBar.showFooter(this.trans('help.post'), '', true, false);
      this.footerBar.onButton1Click = () => {
        this.openSaveChangesMessageboxPrompt();
      };
    }
  }

  private setFooterButtons() {
    this.footerButtons = [
      { label: this.trans('settings.buttons.save'),
        loadingLabel: this.trans('settings.buttons.saving'),
        loadingCompleteLabel: this.trans('settings.buttons.saved'),
        callback: () => { this.openSaveChangesMessageboxPrompt(); }
      },
    ];
  }

  private setTypeParams() {
    if(this.isSuperAdmin) {
      this.type = 'changelog';
    } else if (this.notificationPermissions.canCreate) {
      this.type = 'notification';
    } else {
      this.router.navigate([...this.g.resolveRootRoute()]);
      return;
    }
    this.setTypesList();
  }

  private setVisibilityValueList() {
    this.visibilityValueList = [
      { value: VisibilityValue.SystemMaster,                  label: this.trans('permissions.defaults.names.' + PermissionRole.SystemMaster) },
      { value: VisibilityValue.Company,                       label: this.trans('permissions.defaults.names.' + PermissionRole.Company) },
      { value: VisibilityValue.Installer,                     label: this.trans('permissions.defaults.names.' + PermissionRole.Installer) },
      { value: VisibilityValue.CompanyInstaller,              label: this.trans('permissions.defaults.names.' + PermissionRole.Company) + ' + ' + this.trans('permissions.defaults.names.' + PermissionRole.Installer) },
      { value: VisibilityValue.CompanyInstallerSystemMaster,  label: this.trans('permissions.defaults.names.' + PermissionRole.Company) + ' + ' + this.trans('permissions.defaults.names.' + PermissionRole.Installer) + ' + ' + this.trans('permissions.defaults.names.' + PermissionRole.SystemMaster) },
      { value: VisibilityValue.All,                           label: this.trans('help.allUsers') },
    ];
  }

  private setTypesList() {
    this.typesList = [];
    if(this.isSuperAdmin) {
      this.typesList.push({ label: this.trans('help.changelog.create'), value: 'changelog' });
    }
    if(this.notificationPermissions.canCreate) {
      this.typesList.push({ label: this.trans('help.cloudNotifications.create'), value: 'notification' });
    }
  }
  
  private async saveChanges() {
    const validationSuccess = this.validate();
    if(!validationSuccess) return;

    this.isRequestLoading = true;
    let returnToChangelog = false;
    if(this.isChangelog) {
      returnToChangelog = await this.saveChangelogChanges();
    } else {
      returnToChangelog = await this.saveCloudNotificationChanges();
    }
    this.isRequestLoading = false;
    if(this.desktopView) {
      setTimeout(() => {
        if(returnToChangelog) this.router.navigate([...this.g.resolveRootRoute(), 'help', 'changelog']);
      }, 1000);
      return;
    }
    if(returnToChangelog) this.router.navigate([...this.g.resolveRootRoute(), 'help', 'changelog']);
  }

  private async saveChangelogChanges(): Promise<boolean> {
    if(this.isNew) {
      const req = this.generateChangelogRequestParams();
      return await this.changelogService.create(req);
    } else {
      const req = this.generateChangelogRequestParams();
      return await this.changelogService.update({ id: this.itemInEditId, ...req });
    }
  }

  public onDeleteClick(): void {
    if(!this.canDeleteItem) return;

    const messageText = this.isChangelog ? this.trans('help.changelog.deletePrompt') : this.trans('help.cloudNotifications.deletePrompt');
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: `${messageText}: ${this.trans('general.areYouSure')}`,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.isChangelog ? this.deleteChangelog() : this.deleteCloudNotification();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteChangelog(): Promise<void> {
    const success = await this.changelogService.delete({ id: this.itemInEditId });
    if(success) {
      this.router.navigate([...this.g.resolveRootRoute(), 'help', 'changelog']);
    }
  }

  private async deleteCloudNotification(): Promise<void> {
    const success = await this.cloudNotificationService.delete({ id: this.itemInEditId });
    if(success) {
      this.router.navigate([...this.g.resolveRootRoute(), 'help', 'changelog']);
    }
  }

  private generateChangelogRequestParams(): CreateChangelogRequest | UpdateChangelogRequest {
    return {
      headline: this.contentText,
      content: this.changelogTextItems,
      date: this.shouldStartNow ? (Math.floor(Date.now() / 1000) - 60) : this.startDate.toSeconds(),
      video_url: this.videoUrl === '' ? null : this.videoUrl,
      cloud_notification_enabled: this.toasterNotificationEnabled,
      send_push: this.pushNotificationEnabled,
      visibility: this.visibility,
    }
  }

  private generateCloudNotificationRequestParams(): CreateCloudNotificationRequest | UpdateCloudNotificationRequest {
    return {
      type: this.notificationType,
      content: this.contentText,
      valid_from: this.shouldStartNow ? (Math.floor(Date.now() / 1000) - 60) : this.startDate.toSeconds(),
      valid_until: this.expirationDate === null ? null : this.expirationDate.toSeconds(),
      video_url: this.videoUrl === '' ? null : this.videoUrl,
      send_push: this.pushNotificationEnabled,
      visibility: this.visibility,
    }
  }

  private async saveCloudNotificationChanges(): Promise<boolean> {
    if(this.isNew) {
      const req = this.generateCloudNotificationRequestParams();
      return await this.cloudNotificationService.create(req as CreateCloudNotificationRequest);
    } else {
      const req = this.generateCloudNotificationRequestParams();
      return await this.cloudNotificationService.update({ id: this.itemInEditId, ...req });
    }
  }

  public onPreviewValueChange(value: boolean) {
    this.cloudNotificationService.isVisiblePreview = value;
    this.onValueChanged();
  }

  public onPushNotificationEnabledValueChange(value: boolean) {
    this.pushNotificationEnabled = value;
    this.onValueChanged();
  }

  public onCustomHeadlineEnabledValueChange(value: boolean) {
    if(value === false) this.changelogHeadline = '';
    this.customHeadlineEnabled = value;
    this.onValueChanged();
  }

  public onToasterNotificationEnabledValueChange(value: boolean) {
    this.toasterNotificationEnabled = value;
    if(value === false) {
      this.onPreviewValueChange(value);
    }
    this.onValueChanged();
  }

  private resetPreviewParam() {
    if(this.type === 'changelog' && !this.toasterNotificationEnabled) {
      this.onPreviewValueChange(false);
    }
  }

  public onNotificationItemTypeChange(value: CloudNotificationType) {
    this.notificationType = value;
    this.onValueChanged();
  }

  public onValueChanged() {
    this.setNotificationPreviewParams();
  }

  private setNotificationPreviewParams() {
    this.cloudNotificationService.previewNotification = {
      id: 0,
      type: this.isChangelog ? 'info' : this.notificationType,
      content: this.contentText,
      origin: this.isChangelog ? 'changelog' : null,
      valid_from: this.startDate.toSeconds(),
      valid_until: null,
      video_url: this.videoUrl,
      active: true,
      visibility: null,
      company_id: 0,
      created_at: 0,
      updated_at: 0,
      changelog: null,
    }
  }

  private formatDate(ts: number) {
    const dateFromSeconds = new Date(ts * 1000);
    return this.dateService.formatDate(dateFromSeconds);
  }

  private setDropdownItems(): void {
    this.setChangelogItemTypeDropdownItems();
    this.setNotificationItemTypeDropdownItems();
  }

  private setChangelogItemTypeDropdownItems(): void {
    this.changelogItemTypes = [
      { label: this.trans('help.changelog.types.new'),      value: 'new'},
      { label: this.trans('help.changelog.types.update'),   value: 'update'},
      { label: this.trans('help.changelog.types.fix'),      value: 'fix'},
    ];
  }

  private setNotificationItemTypeDropdownItems(): void {
    this.notificationItemTypes = [
      { label: this.trans('help.cloudNotifications.types.info'),    value: 'info'},
      { label: this.trans('help.cloudNotifications.types.warning'), value: 'warning'},
      { label: this.trans('help.cloudNotifications.types.news'),   value: 'news'},
    ];
  }

  private setVisibilityValue() {
    let visibilityValue = this.visibilityValue;
    if (this.visibilityValueCascaded) {
      visibilityValue |= CASCADE_FLAG;
    }
    this.visibility = visibilityValue;
  }

  private validate(): boolean {
    if(this.isChangelog) {
      return this.validateChangelog();
    } else {
      return this.validateNotification();
    }
  }

  private validateChangelog(): boolean {
    if(this.changelogTextItems.length === 1 && this.changelogTextItems[0].text === '') {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        headerText: this.trans('general.titleError'),
        iconType: this.messagebox.iconType.Error,
        messageContent: this.trans('help.changelog.errors.noItems')
      });
      return false;
    }
    return true;
  }

  private validateNotification(): boolean {
    if(this.notificationText === '') {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        headerText: this.trans('general.titleError'),
        iconType: this.messagebox.iconType.Error,
        messageContent: this.trans('help.cloudNotifications.errors.noText')
      });
      return false;
    }
    return true;
  }

}
