import { httpGet, httpPost } from '../http';
import { objectToParams, Region } from '../v3/common';
import { CommonRequest, CommonResponse } from './common';
import { TDeviceInfo } from 'src/app/models/device-info';
import { GV17Device } from 'src/app/configurators/gv17/src/app/models/types';
import { RegisteredDevice } from '../v3/system';

export enum ConfigType {
  SP3 = 'sp3',
  SP4 = 'sp4',
  GV17 = 'gv17',
  FC = 'fc',
  G16 = 'g16',
  WP17 = 'wp17',
  G17 = 'g17',
}
const write = (params: ConfigWriteRequestParams) => httpPost<CommonResponse, ConfigWriteRequestParams>('/v3/api/config/write', params);
const read = (params: CommonConfigRequest) => httpGet<ConfigReadRequestResponse, URLSearchParams>('/v3/api/config/read', objectToParams(params));
const readGDataForSetup = (params: InfoRequest) => httpGet<GSetupDataRequestResponse, URLSearchParams>('/v3/api/read-data-for-device-setup', objectToParams(params));
const info = (params: InfoRequest, silent: boolean) => httpGet<InfoRequestResponse, URLSearchParams>('/v3/api/config/info', objectToParams(params), { silentOnError: silent });
const writeGSetupData = (params: InfoRequest & GDeviceSetupData) => httpPost<GSetupWriteRequestResponse, InfoRequest & GDeviceSetupData>('/v3/api/write-data-for-device-setup', params);

type CommonConfigRequest = CommonRequest & { fwVersion: string };
type InfoRequest = Omit<CommonRequest, 'adminCode'> & { srv: number; subsrv?: number };
type ConfigReadRequestResponse = CommonResponse & { data?: GV17Device };
type ConfigWriteRequestParams = CommonConfigRequest & { config: GV17Device };
type GSetupDataRequestResponse = CommonResponse & {
  data: {
    panel: {
      value: string;
      value_manual: string;
      value_auto: string;
      list: {
        value: string;
        canRemoteControl: boolean;
        canReceiveEvents: boolean;
        text: string;
        pcCodeNeeded: boolean;
      }[];
      tone_setting: { value: string; text: string }[];
      tone_auto_value: { value: string; text: string }[];
      tone_manual_value: { value: string; text: string }[];
      directCellValue: string;
    };
    extraFeaturesValue: string;
  };
};
type GSetupWriteRequestResponse = CommonResponse & { registered?: RegisteredDevice };

export type GDeviceSetupData = {
  objectNumber: string;
  moduleId: string | undefined;
  directCapable: boolean;
  directControlCellValue: string;
  panelKey: string;
  directControl: boolean;
  receiveEvents: boolean;
  panelPassword: string | undefined;
  lineNumber: string;
  receiverNumber: string;
  primary: {
    communicationMode: number;
    domain: string;
    port: string;
    encryptionKey: string;
  };
  backupEnabled: boolean;
  backup?: {
    communicationMode: number;
    domain: string;
    port: string;
    encryptionKey: string;
  };
  pingEnabled: boolean;
  pingMinutes: number;
  pingSeconds: number;
  testEnabled: boolean;
  testHours: number;
  testMinutes: number;
  backupAfter: number;
  primaryAfterMinutes: number;
  primaryAfterSeconds: number;
  telephonePanel: string | undefined;
  manualValue: string | undefined;
  autoValue: string | undefined;
  templateUsed: boolean;
  cloudEnabled: boolean;
  extraFeaturesValue: string;
  usePanelAccountId: boolean;
  waitForAck: boolean;
  force_reboot: boolean;
};

type InfoRequestResponse = CommonResponse & {
  data: TDeviceInfo & {
    isInForeignRegion: boolean;
  };
  foreignSystem: any[] | null; // kazkada reiketu nurodyti pilna struktura.
  ownRegion: Region | null;
  srv: number;
};

export default {
  info,
  read,
  write,
  writeGSetupData,
  readGDataForSetup,
};
