<div *ngIf="isVisible" id="notificationsWrapper" [ngClass]="{ '-mb-1': isDesktopView, 'pb-1': !isDesktopView }">
    <div [ngClass]="{ 'px-2 mt-2': isDesktopView }">
        <ng-container *ngIf="isDesktopView; else MobileView">
            <div class="relative z-1" [ngClass]="[ getBannerTheme(cloudNotification), 'container rounded-t', hasMoreActiveNotifications && showAllActiveNotifications ? 'rounded-b-none' : 'rounded-b' ]">
                <div class="flex flex-1 justify-left w-full overflow-auto scrollbar-none gap-1">
                    <div class="flex gap-05">
                        <p class="text-banner">{{ getBannerText() }}</p>
                        <p *ngIf="showTranslateButton && !isTranslationShowing()" (click)="onTranslateClick(); $event.stopPropagation();" class="text-translate">{{ trans('help.translateTo') }} {{ userLanguageDescription }}</p>
                        <p *ngIf="showTranslateButton && isTranslationShowing()" (click)="onSeeOriginalTextClick(); $event.stopPropagation();" class="text-translate">{{ trans('help.viewOriginal') }}</p>
                    </div>
                    <div *ngIf="isBannerButtonVisible()" class="flex items-center">
                        <app-button (click)="onButtonClick()" [type]="'filled-secondary'" [rounded]="'partial'">{{ bannerButtonText }}</app-button>
                    </div>
                    <div *ngIf="bannerVideoExists(cloudNotification)" class="container-video pl-3">
                        <div (click)="onVideoButtonClick(cloudNotification)" class="icon-video" [inlineSVG]="'assets/images/youtube.svg'"></div>
                        <p (click)="onVideoButtonClick(cloudNotification)" class="bold pointer color-gray-500">{{ bannerVideoButtonText }}</p>
                    </div>
                </div>
                <div class="flex items-center px-1">
                    <p *ngIf="!showAllActiveNotifications && otherActiveNotifications.length > 0" (click)="onDismissClick()" class="link bold pointer">{{ trans('help.cloudNotifications.dismissAll') }}</p>
                    <p *ngIf="showAllActiveNotifications || otherActiveNotifications.length === 0" (click)="onDismissClick(cloudNotification)" class="link bold pointer">{{ trans('help.cloudNotifications.dismiss') }}</p>
                </div>
                <ng-container *ngIf="hasMoreActiveNotifications">
                    <div (click)="onNotificationsClick()" [ngClass]="{ 'notifications': true }">
                        <div class="notifications-mask"></div>
                        <p *ngIf="!showAllActiveNotifications" class="my-0">{{ activeNotificationsCount }}</p>
                        <span *ngIf="!showAllActiveNotifications" class="icon-arrow" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
                        <span *ngIf="showAllActiveNotifications" class="icon-arrow icon-arrow-180" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #MobileView>
            <div class="relative z-1" [ngClass]="[ getBannerTheme(cloudNotification), 'container-mobile rounded-t', hasMoreActiveNotifications && showAllActiveNotifications ? 'rounded-b-none' : 'rounded-b' ]">
                <div class="w-full">
                    <div class="flex gap-1 items-center p-1">
                        <p class="my-0 text-banner">{{ getBannerText() }}</p>
                        <div class="ml-auto flex gap-1">
                            <p *ngIf="!showAllActiveNotifications && otherActiveNotifications.length > 0" (click)="onDismissClick()" class="my-0 nowrap link bold pointer">{{ trans('help.cloudNotifications.dismissAll') }}</p>
                            <p *ngIf="showAllActiveNotifications || otherActiveNotifications.length === 0" (click)="onDismissClick(cloudNotification)" class="my-0 link bold pointer">{{ trans('help.cloudNotifications.dismiss') }}</p>
                            <ng-container *ngIf="hasMoreActiveNotifications">
                                <div (click)="onNotificationsClick()" [ngClass]="{ 'notifications': true }">
                                    <div class="notifications-mask"></div>
                                    <p *ngIf="!showAllActiveNotifications" class="my-0">{{ activeNotificationsCount }}</p>
                                    <span *ngIf="!showAllActiveNotifications" class="icon-arrow" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
                                    <span *ngIf="showAllActiveNotifications" class="icon-arrow icon-arrow-180" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="isBannerButtonVisible() || bannerVideoExists(cloudNotification) || showTranslateButton" class="w-full">
                    <div class="flex gap-1 px-1 pb-1">
                        <div class="flex-1 flex justify-left gap-05">
                            <div *ngIf="isBannerButtonVisible()" class="flex items-center">
                                <app-button (click)="onButtonClick()" [type]="'filled-secondary'" class="button-mobile" [rounded]="'partial'">{{ bannerButtonText }}</app-button>
                            </div>
                            <div *ngIf="bannerVideoExists(cloudNotification)" class="container-video">
                                <div (click)="onVideoButtonClick(cloudNotification)" class="icon-video-mobile" [inlineSVG]="'assets/images/youtube.svg'"></div>
                                <p (click)="onVideoButtonClick(cloudNotification)" class="my-0 bold pointer color-gray-500">{{ bannerVideoButtonText }}</p>
                            </div>
                        </div>
                        <p *ngIf="showTranslateButton && !isTranslationShowing()" (click)="onTranslateClick(); $event.stopPropagation();" class="my-0 text-translate-mobile">{{ trans('help.translateTo') }} {{ userLanguageDescription }}</p>
                        <p *ngIf="showTranslateButton && isTranslationShowing()" (click)="onSeeOriginalTextClick(); $event.stopPropagation();" class="my-0 text-translate-mobile">{{ trans('help.viewOriginal') }}</p>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div *ngIf="showAllActiveNotifications">
        <div *ngFor="let notification of otherActiveNotifications; let last = last" [@activeNotificationList] [ngClass]="{ 'px-2': isDesktopView }">
            <ng-container *ngIf="isDesktopView; else AllNotificationsMobileView">
                <div class="relative" [ngClass]="[ getBannerTheme(notification), 'container', last ? 'rounded-b' : 'rounded-b-none' ]">
                    <div class="flex flex-1 justify-left w-full gap-1">
                        <div class="flex gap-05">
                            <p class="text-banner">{{ getBannerText(notification) }}</p>
                            <p *ngIf="showTranslateButton && !isTranslationShowing(notification)" (click)="onTranslateClick(notification); $event.stopPropagation();" class="text-translate">{{ trans('help.translateTo') }} {{ userLanguageDescription }}</p>
                            <p *ngIf="showTranslateButton && isTranslationShowing(notification)" (click)="onSeeOriginalTextClick(notification); $event.stopPropagation();" class="text-translate">{{ trans('help.viewOriginal') }}</p>
                        </div>
                        <div *ngIf="isBannerButtonVisible(notification)" class="flex items-center">
                            <app-button (click)="onButtonClick()" [type]="'filled-secondary'" [rounded]="'partial'">{{ bannerButtonText }}</app-button>
                        </div>
                        <div *ngIf="bannerVideoExists(notification)" class="container-video pl-3">
                            <div (click)="onVideoButtonClick(notification)" class="icon-video" [inlineSVG]="'assets/images/youtube.svg'"></div>
                            <p (click)="onVideoButtonClick(notification)" class="bold pointer color-gray-500">{{ bannerVideoButtonText }}</p>
                        </div>
                    </div>
                    <div class="flex items-center px-1">
                        <p (click)="onDismissClick(notification)" class="link bold pointer">{{ trans('help.cloudNotifications.dismiss') }}</p>
                    </div>
                </div>
            </ng-container>
            <ng-template #AllNotificationsMobileView>
                <div class="relative" [ngClass]="[ getBannerTheme(notification), 'container-mobile', last ? 'rounded-b' : 'rounded-b-none' ]">
                    <div class="w-full">
                        <div class="flex gap-1 items-center p-1">
                            <p class="my-0 text-banner">{{ getBannerText(notification) }}</p>
                            <div class="ml-auto flex gap-1">
                                <p (click)="onDismissClick(notification)" class="my-0 link bold pointer">{{ trans('help.cloudNotifications.dismiss') }}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isBannerButtonVisible(notification) || bannerVideoExists(notification) || showTranslateButton" class="w-full">
                        <div class="flex gap-1 px-1 pb-1">
                            <div class="flex-1 flex justify-left gap-05">
                                <div *ngIf="isBannerButtonVisible(notification)" class="flex items-center">
                                    <app-button (click)="onButtonClick()" [type]="'filled-secondary'" class="button-mobile" [rounded]="'partial'">{{ bannerButtonText }}</app-button>
                                </div>
                                <div *ngIf="bannerVideoExists(notification)" class="container-video">
                                    <div (click)="onVideoButtonClick(notification)" class="icon-video-mobile" [inlineSVG]="'assets/images/youtube.svg'"></div>
                                    <p (click)="onVideoButtonClick(notification)" class="my-0 bold pointer color-gray-500">{{ bannerVideoButtonText }}</p>
                                </div>
                            </div>
                            <p *ngIf="showTranslateButton && !isTranslationShowing(notification)" (click)="onTranslateClick(notification); $event.stopPropagation();" class="my-0 text-translate-mobile">{{ trans('help.translateTo') }} {{ userLanguageDescription }}</p>
                            <p *ngIf="showTranslateButton && isTranslationShowing(notification)" (click)="onSeeOriginalTextClick(notification); $event.stopPropagation();" class="my-0 text-translate-mobile">{{ trans('help.viewOriginal') }}</p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
