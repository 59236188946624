export enum DashboardWidgetType  {
    SYSTEMS = 'systems',
    SYSTEM_STATUS = 'system_status',
};

export enum DashboardWidgetTimeframe  {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    THIS_WEEK = 'thisWeek',
    LAST_WEEK = 'lastWeek',
    THIS_MONTH = 'thisMonth',
    LAST_MONTH = 'lastMonth',
    THIS_YEAR = 'thisYear',
    LAST_YEAR = 'lastYear'
};

export type DashboardWidgetParams = {
    type: DashboardWidgetType;
    header: DashboardWidgetHeaderParams;
    content: DashboardWidgetContentParams;
    footer: DashboardWidgetFooterParams;
}

type DashboardWidgetHeaderParams = {
    title: string;
    subTitle?: string;
    icon: DashboardWidgetHeaderIcon;
    trend: DashboardWidgetHeaderTrend;
    showTotalCount: boolean;
}

type DashboardWidgetContentParams = {
    total: number;
    timeframe?: DashboardWidgetTimeframeData;
    systemStatus?: DashboardSystemStatusData;
} | null;

type DashboardWidgetFooterParams = {
    link: { label: string; route: string[] } | null;
    timeframe: DashboardWidgetTimeframe | null;
} | null;

type DashboardWidgetHeaderIcon = {
    src: string;
    theme: string;
} | null;

type DashboardWidgetHeaderTrend = {
    show: boolean;
    isInverted: boolean;
} | null;

export type DashboardSystemStatusData = {
    online: number;
    offline: number;
    inactive: number;
};

export type DashboardWidgetTimeframeData = {
    current_period: {
        count: number;
        timeframe: DashboardWidgetTimeframe;
    },
    past_period: {
        count: number;
        timeframe: DashboardWidgetTimeframe;
    },
};

export type DashboardWidgetTimeframeTimestamp = { start: number; end: number; };