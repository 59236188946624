import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SensorService } from 'src/app/api/system/sensor.service';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { LoaderDotsV2Component } from 'src/app/components/loader-dots-v2/loader-dots-v2.component';

@Component({
    selector: 'app-home-sensors',
    templateUrl: './home-sensors.component.html',
    styleUrls: ['./home-sensors.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, InlineSVGModule, LoaderDotsV2Component]
})
export class HomeSensorsComponent extends LanguageAware implements OnInit, OnDestroy {
  public visibleSensors = [];
  private changeSubscriber = null;
  private currentSystem = 0;

  constructor(
    cdRef: ChangeDetectorRef,
    public senS: SensorService,
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.tag = 'HomeSensors';
    this.log('+');

    const that = this;
    this.loadVisibleSensors();
    this.changeSubscriber = this.systems.onActiveSystemChange.subscribe(() => {
      if ( !this.systems.activeSystem ) { return; }
      that.handleSystemChange();
    });

    if (this.visibleSensors.length > 0) {
      this.currentSystem = this.systems.activeSystem?.id ?? 0;
      senS.startWatcher();
    } else {
      senS.stopSensorWatch();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.changeSubscriber !== null) {
      this.changeSubscriber.unsubscribe();
      this.changeSubscriber = null;
    }
    this.senS.stopSensorWatch();
  }

  private loadVisibleSensors() {
    this.visibleSensors = [];
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined) {
      this.log('systemConfig neegzistuoja.');
    } else {
      this.log('systemConfig.visibleSensors', systemConfig.visibleSensors);
    }
    if (systemConfig === undefined || systemConfig.visibleSensors.length === 0) {
      this.log('Filtruojam.', this.systems.activeSystem.sensors);
      this.visibleSensors = this.systems.activeSystem.sensors.filter((s) => s.enabled);
      return;
    }
    for (const iSensor of this.systems.activeSystem.sensors) {
      if (iSensor.enabled && systemConfig.visibleSensors.indexOf(iSensor.queue_no) !== -1) {
        this.visibleSensors.push(iSensor);
      }
    }
  }

  private handleSystemChange() {
    if (this.systems.activeSystem === null) {
      return;
    }
    this.loadVisibleSensors();
    if (this.currentSystem !== this.systems.activeSystem.id) {
      this.currentSystem = this.systems.activeSystem.id;
      this.senS.startWatcher(true);
    }
  }
}
