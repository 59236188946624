import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, AfterViewInit, ViewContainerRef, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { ErrorResponse } from 'src/api/v3/common';
import { PermissionRole } from 'src/api/v3/permission';
import requests from 'src/api/v3/requests';
import { CameraService } from 'src/app/api/system/camera.service';
import { EventService } from 'src/app/api/system/event.service';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { FragmenterComponent } from 'src/app/components/fragmenter/fragmenter.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { OpenVideoHandlerCameraObject, TCamera } from 'src/app/models/camera';
import { TEventData } from 'src/app/models/event-data';
import { CameraViewComponent } from 'src/app/popups/camera-view/camera-view.component';
import { DateService } from 'src/app/services/date.service';
import { EventsService } from 'src/app/services/events.service';
import { LocatorService } from 'src/app/services/locator.service';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    imports: [CommonModule, FragmenterComponent, InlineSVGModule]
})
export class EventsComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fragmentLoader') fragmentLoader: FragmenterComponent;
  public lastSynced = '';
  public groupedEvents: any[] = [];
  private eventSubscription = null;
  private refreshSubscriber = null;
  public that = this;
  private eventCountBeforeGrouping = 0;

  constructor(
    cdRef: ChangeDetectorRef,
    public dt: DateService,
    private ev: EventsService,
    ws: WebSocketService,
    private es: EventsService,
    public reactions: ReactionService,
    private router: Router,
    private r: ActivatedRoute,
    private cameraService: CameraService,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      backUrl: '*',
      showDotMenu: this.us.currentUser.permissions.role === PermissionRole.GeneralUser,
    });
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.eventsHideUnhide'),
        action: () => { this.router.navigate(['../event-control'], { relativeTo: this.r }); },
      },
    ]);
    this.groupedEvents = this.getGroupedEvents();
    this.eventSubscription = ws.onEventReceived.subscribe(() => {
      this.groupedEvents = this.getGroupedEvents();
    });
    this.refresher.enableRefresher();
    this.refreshSubscriber = this.refresher.onRefresh.subscribe(() => {
      this.loadEvents();
    });
    if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin && this.systems.activeSystem.events.length < 2) {
      this.loadEvents();
    }
  }

  ngOnInit(): void {
    this.lastSynced = this.trans('events.titles.syncTime') + ': ' + this.dt.formatDateTime(new Date());
  }

  ngAfterViewInit(): void {
    this.fragmentLoader.isEnabled = this.eventCountBeforeGrouping % this.es.eventPageSize === 0;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
    if (this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  public getGroupedEvents(): any[] {
    let eventListToUse = [];
    if (this.systems.activeSystem.eventConfiguration.length === 0) {
      eventListToUse = this.systems.activeSystem.events;
    } else {
      eventListToUse = this.systems.activeSystem.events.filter((e) => this.systems.activeSystem.eventConfiguration.indexOf(e.reaction) !== -1);
    }
    if (this.fragmentLoader !== undefined) {
      this.fragmentLoader.isEnabled = eventListToUse.length % this.es.eventPageSize === 0;
    }
    this.eventCountBeforeGrouping = eventListToUse.length;

    return this.ev.groupEvents(eventListToUse, 0, 0);
  }

  private loadEvents() {
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const systemId = this.systems.activeSystem.id;
    requests.system.events.getEvents({ system_id: systemId }).subscribe(
      (result) => {
        if (result.success) {
          const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
          if (system === undefined) {
            this.miniStatus.hide();
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = this.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          system.events = convertedEvents;
          this.groupedEvents = this.getGroupedEvents();
        } else {
          this.toaster.postError((result as ErrorResponse).error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  public loadEventChunk(context: any, callback?: any) {
    context.miniStatus.show(context.trans('general.pleaseWait'));
    if ( !context.systems.activeSystem ) {
      context.miniStatus.hide();
      return;
    }
    const systemId = context.systems.activeSystem.id;
    const lastEventId = context.systems.activeSystem.events.length === 0 ? 0 : context.systems.activeSystem.events[context.systems.activeSystem.events.length - 1].id;
    requests.system.events.getEvents({ system_id: systemId, offsetEvents: lastEventId }).subscribe(
      (result) => {
        if (result.success) {
          const system = context.systems.getSystem(systemId) || context.systemService.systems.get(systemId);
          if (system === undefined) {
            context.miniStatus.hide();
            if (callback !== undefined) {
              callback();
            }
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = context.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          if ( Object.getOwnPropertyDescriptor(system, 'events')?.hasOwnProperty('set') ) {
            const eventService = LocatorService.injector.get(EventService);
            for (const iEvent of convertedEvents ) {
              eventService.ingestEventData(iEvent);
            }
          } else {
            system.events.push(...convertedEvents);
          }
          context.groupedEvents = context.getGroupedEvents();
        } else {
          context.toaster.postError((result as ErrorResponse).error);
        }
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      },
      (error) => {
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      }
    );
  }

  public eventClicked(event: TEventData, $event: MouseEvent) {
    let foundDiv = $event.target as HTMLElement;
    while (foundDiv && (foundDiv as HTMLElement).tagName.toLowerCase() !== 'div') {
      foundDiv = (foundDiv as HTMLElement).parentElement;
    }
    if ( event.latitude && foundDiv && (foundDiv.classList.contains('e-event-icon') || foundDiv.classList.contains('e-event-coord')) ) {
      try {
        if ( this.platform.isAndroid() ) {
          this.platform.androidHandler().openMapProvider(event.latitude, event.longitude, event.userName);
        } else if ( this.platform.isApple() ) {
          this.platform.appleHandler().openMapProvider.postMessage({ lat: event.latitude, lon: event.longitude, user: event.userName });
        } else {
          const mapUrl = `https://www.google.com/maps?q=${event.latitude},${event.longitude}`;
          window.open(mapUrl, '_blank');
        }
      } catch (e) { }
      return;
    } else if (event.alarmType) {
      const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === event.zoneId);
      if ( !zone ) {
        return;
      }

      // Pridėtas apribojimas iki vienos kameros (.slice(0, 1)). Ateityje ribojimas gali keistis.
      const assignedCameras = this.systems.activeSystem.cameras.filter((c) => c.assignedZones.includes(zone.queue_no)).slice(0, 1);
      if(assignedCameras.length > 0) {
        if( this.platform.isMobile() ) {
          let camerasToUse = [];
          assignedCameras.forEach((camera) => {
            const cameraObject = this.cameraService.getCameraObjectForVideoHandler(camera);
            camerasToUse.push(cameraObject);
          });
          this.cameraService.openMobileCameraView(camerasToUse);
        } else {
          this.openDesktopCameraView(assignedCameras);
        }
      }
    }
  }

  private openDesktopCameraView(cameras: TCamera[]) {
    const cameraViewComponentRef = this.viewContainerRef.createComponent(CameraViewComponent, {
      injector: this.injector
    });
    this.cameraService.openCameraView(cameras, cameraViewComponentRef);
  }
}
