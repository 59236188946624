import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { AreaService } from 'src/app/services/area.service';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from 'src/app/general/checkbox/checkbox.component';
import { PasswordEditComponent } from 'src/app/components/password-edit/password-edit.component';

@Component({
    selector: 'app-area-keypad',
    templateUrl: './area-keypad.component.html',
    styleUrls: ['./area-keypad.component.scss'],
    imports: [InlineSVGModule, CommonModule, CheckboxComponent, PasswordEditComponent],
    animations: [
        trigger('showKeypad', [
            state('hidden', style({ opacity: 0, transform: 'translateY(100%)' })),
            state('visible', style({ opacity: 1, transform: 'translateY(0px)' })),
            transition('hidden => visible', animate('0.2s linear')),
        ]),
    ]
})
export class AreaKeypadComponent extends LanguageAware implements OnInit {
  public isVisible = 'hidden';
  @Input() minLength = 0;
  @Input() maxLength = 0;
  /**
   * Galimi tipai:
   * inline - klaviatūra nuo 0 iki 9 su confirm ir cancel, bet delete. Rodoma kažkokio komponento viduje.
   * slideout - klaviatūra nuo 0 iki 9 su confirm, cancel ir delete. Išvažiuoja iš ekrano apačios.
   */
  @Input() keypadType = 'inline';
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() confirmClicked: EventEmitter<any> = new EventEmitter();
  @Output() pinComplete: EventEmitter<any> = new EventEmitter();
  public doRememberPin = false;
  public pinMask = '';
  public pin = '';
  public pressedButton = -1;

  constructor(cdRef: ChangeDetectorRef, private areaService: AreaService) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
  }

  ngOnInit(): void {
    const that = this;
    setTimeout(() => {
      that.isVisible = 'visible';
    }, 50);
  }

  public rememberClicked(newVal: boolean) {
    this.doRememberPin = !this.doRememberPin;
    this.l.log('rememper pin', 'Keypad', {
      doRememberPin: this.doRememberPin,
      newVal,
    });
  }

  public onCancelClick() {
    this.areaService.keypadCancelled();
    this.cancelClicked.emit();
  }

  public onOkClick() {
    if (this.minLength !== 0 && this.pin.length < this.minLength) {
      this.toaster.postError(this.trans('mobileapp.errors.pinTooShort'));
      return;
    }
    this.areaService.pinConfirmed(this.pin, this.doRememberPin);
    this.confirmClicked.emit({
      pin: this.pin,
      remember_pin: this.doRememberPin,
    });
  }

  public digitClicked(which: string) {
    if (which !== '10' && which !== '11' && this.maxLength !== 0 && this.pin.length >= this.maxLength) {
      this.toaster.postError(this.trans('mobileapp.errors.pinTooShort'));
      return;
    }
    if (which === '10') {
      this.pin = this.pin.substring(0, this.pin.length - 1);
    } else if (which === '11') {
      this.onOkClick();
    } else {
      try {
        if ( this.platform.isAndroid() ) {
          this.platform.androidHandler().doVibrate(50);
        } else if ( this.platform.isApple() ) {
          this.platform.appleHandler().doVibrate.postMessage(100);
        } else {
          navigator.vibrate(100);
        }
      } catch (ve) {}
      this.pin = this.pin + which;
    }
    this.pinMask = ''.padEnd(this.pin.length, '●');
  }

  onNumberDown(num: number) {
    this.pressedButton = num;
  }

  onNumberUp() {
    this.pressedButton = -1;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.onCancelClick();
    } else if ( event.code === 'Backspace' ) {
      this.digitClicked('10');
    } else if ( event.code === 'Enter' ) {
      this.digitClicked('11');
    } else if ( event.code === 'NumpadEnter' ) {
      this.digitClicked('11');
    } else if ( event.code.startsWith('Digit') ) {
      this.digitClicked(event.code[event.code.length-1]);
    } else if ( event.code.startsWith('Numpad') ) {
      const digit = Number(event.code[event.code.length-1]);
      if ( !isNaN(digit) ) { this.digitClicked(event.code[event.code.length-1]); }
    }
  }
}
