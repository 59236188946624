import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loader-dots',
    templateUrl: './loader-dots.component.html',
    styleUrls: ['./loader-dots.component.scss'],
    imports: []
})
export class LoaderDotsComponent implements OnInit {
  @Input() width = '50px';
  @Input() height = '50px';
  @Input() isWhite = false;

  constructor() {}

  ngOnInit(): void {}
}
