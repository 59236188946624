import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { Subscription } from 'rxjs';
import { TSystemData } from 'src/app/models/system-data';
import { MessageBoxButtons } from 'src/app/popups/messagebox/messagebox-config';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';
import { LanguageAware } from 'src/app/general/language-aware';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { DateService } from 'src/app/services/date.service';
import { LoginService } from 'src/app/login/login.service';
import { TEventData } from 'src/app/models/event-data';
import { EventsService } from 'src/app/services/events.service';
import requests from 'src/api/v3/requests';
import { SaveInstallerLogoResponse } from 'src/api/v3/user';
import { PermissionRole } from 'src/api/v3/permission';
import { CompanyPageLayoutComponent } from '../../components/company-page-layout/company-page-layout.component';
import { CommonModule } from '@angular/common';
import { TeleportModule } from 'src/app/teleport/teleport.module';
import { DesktopFooterComponent } from 'src/app/ui/desktop-footer/desktop-footer.component';
import { ButtonComponent } from 'src/app/ui/button/button.component';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { DropdownComponent, DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { StringFormInputComponent } from 'src/app/ui/form-input/form-input.component';
import { FormErrorComponent } from 'src/app/ui/form-error/form-error.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { countryList } from 'src/environments/countrylist';
import { LocaleService } from 'src/app/api/locale.service';
import { BackButtonComponent } from "../../../ui/back-button/back-button.component";
import { CompanyLogoComponent } from "../../../components/company-logo/company-logo.component";
type userSettingsData = {
  name: string;
  phone: string;
  DateFormat: number;
  country: string | null;
};

@Component({
    selector: 'app-company-account-settings',
    templateUrl: './company-account-settings.component.html',
    styleUrls: ['./company-account-settings.component.scss'],
    imports: [CommonModule, TeleportModule, DesktopFooterComponent, ButtonComponent, MessageboxComponent, DropdownComponent, StringFormInputComponent, FormErrorComponent, InlineSVGModule, BackButtonComponent, CompanyLogoComponent]
})
export class CompanyAccountSettingsComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  public userSettingsData: userSettingsData;
  private initialUserSettingsData: string;
  private subscriptions: Subscription[] = [];
  public currentLanguage: string;
  public languageItems = [];
  public footerButtons = [];
  public inputChanged = false;
  public isRequestLoading = false;
  public showChangePasswordComponent = false;
  private delayDuration = 2000;
  public password = '';
  public newPassword = '';
  public ownLogo = false;
  public logoChanged = false;
  public roleSupportsLogo = false;
  public fileToUploadUrl: any = null;
  private fileToUpload: File | null = null;
  public changePasswordMessageboxButtons: MessageBoxButtons = MessageBoxButtons.CustomButton1Button2Link;
  public val = new ValidatorBuilder<{phone: string}>()
    .regex('phone', /^[0-9+\-\s]+$/, `${this.trans('validation.phoneInvalid')}`)
    .build().bindContext(this);
  public valPasswordChange = new ValidatorBuilder<{password: string; newPassword: string}>()
    .required('password', this.trans('general.required'))
    .required('newPassword', this.trans('general.required'))
    .custom('newPassword', customPasswordValidatorBuilder({
      tooShort: () => this.trans('auth.errors.passwordTooShort'),
      noLowerCase: () => this.trans('auth.errors.passwordNoLowercase'),
      noUpperCase: () => this.trans('auth.errors.passwordNoUppercase'),
      noNumber: () => this.trans('auth.errors.passwordNoNumber'),
    }))
    .build().bindContext(this);
  public countryList: DropdownItem<string>[];

  constructor(
    cdRef: ChangeDetectorRef,
    private messagebox: MessageboxService,
    private router: Router,
    private auth: AuthService,
    private google: GoogleAuthService,
    public dt: DateService,
    private login: LoginService,
    private events: EventsService,
    private locale: LocaleService,
  ) {
    super(cdRef);
    this.userSettingsData = {
      name: this.us.currentUser.name,
      phone: this.us.currentUser.phone ? this.us.currentUser.phone : '',
      DateFormat: this.us.currentUser.date_format,
      country: this.us.currentUser.country,
    };
    this.currentLanguage = this.us.currentUser.language ?? 'en';
    this.initialUserSettingsData = JSON.stringify(this.userSettingsData);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: (code: string) => this.locale.getCountryName(code),
    }));
    this.countryList.unshift({
      value: null,
      label: this.trans('users.labels.selectCountry'),
      default: true,
    });
    if(this.ls.availableLanguages.length > 0) {
      this.populateLanguageDropdownItems();
    } else {
      this.processLanguagesData();
    }
    if(this.dt.dateTimeDropdownFormats.length === 0) {
      this.dt.populateDateTimeDropdownFormats();
    }
    this.setFooterButtons();
    this.roleSupportsLogo = this.us.currentUser.permissions.role === PermissionRole.Company;
    this.ownLogo = this.us.currentUser.belongsToCompany?.logo !== this.us.currentUser.logo;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  private async onSettingsSave(): Promise<void> {
    const validationSuccessful = await this.val.validate(this.userSettingsData);
    if ( ! validationSuccessful ) { return; }
    this.isRequestLoading = true;
    await this.changeLanguage();
    if(this.initialUserSettingsData !== JSON.stringify(this.userSettingsData) || this.logoChanged) {
      const settingsSaved = await this.saveUserSettings();
      if(settingsSaved) {
        if ( this.logoChanged ) {
          const result = await this.saveLogo();
          if ( !result ) {
            this.miniStatus.hide();
            return;
          }
        }
        this.us.currentUser.name = this.userSettingsData.name;
        this.us.currentUser.phone = this.userSettingsData.phone;
        this.us.currentUser.date_format = this.userSettingsData.DateFormat;
        this.us.currentUser.country = this.userSettingsData.country;
        this.initialUserSettingsData = JSON.stringify(this.userSettingsData);
        
        this.us.saveCurrentUser();
      }
    }
    this.isRequestLoading = false;
    await new Promise<void>(() => setTimeout(() => {
      this.onInputChange();
    }, this.delayDuration));
  }

  private async changeLanguage(): Promise<void> {
    return new Promise(async (resolve) => {
      if(this.currentLanguage !== this.us.currentUser.language) {
        this.us.currentUser.language = this.currentLanguage;
        await this.postLanguageChange();
        resolve();
      }
      resolve();
    });
  }

  public onInputChange(): void {
    this.inputChanged = this.initialUserSettingsData !== JSON.stringify(this.userSettingsData) || this.currentLanguage !== this.us.currentUser.language;
  }

  public async onDeleteProfileClick(): Promise<void> {
    const messageBody = `
      <p align='center'><b>${this.trans('users.labels.profileDeletionInfo')}</b></p>
      ${this.trans('users.labels.profileDeletionInfo2')}<br/>
      ${this.getOwnedSystems().map(system => `<div class="systemsWrap"><img class="icon" src="assets/images/area-general-icon.svg"/>${system.name}</div>`).join('')}
    `;
    this.messagebox.open({
      buttons: this.messagebox.buttons.CustomButton1Cancel,
      headerText: this.trans('users.labels.deleteProfile'),
      messageContent: messageBody,
      button1Text: this.trans('general.delete'),
      iconType: this.messagebox.iconType.Error
    }, (messagebox) => {
      const deleteClickSubscription = messagebox.button1Clicked.subscribe(() => {
        this.confirmedDeletion();
      });
      this.subscriptions.push(deleteClickSubscription);
    });
  }

  private getOwnedSystems(): TSystemData[] {
    return this.systems.systems.filter((s) => s.amIMaster);
  }

  private populateLanguageDropdownItems() {
    if(this.ls.availableLanguages.length === 0) {
      this.ls.populateAvailablLanguagesList();
    }
    this.languageItems = this.ls.availableLanguages.map(obj => ({ value: obj.code, label: obj.name }));
  }

  private async postLanguageChange(): Promise<void> {
    const found = this.ls.availableLanguages.find((l) => l.code === this.currentLanguage);
    const that = this;
    return new Promise((resolve) => {
      if(!found) { resolve(); }
      const formData = {
        language: found.code,
        version: '-1',
      };
      this.api.post('/translations', formData, false).subscribe((data) => {
        if (data.success) {
          that.ls.selectedLanguage.value = data.code;
          that.ls.applyTranslations(data.translations, data.version, data.code);
          that.setFooterButtons();
          const languageSetListener = that.api.post('/language/set', { language: that.ls.selectedLanguage.value }, true).subscribe(
            async (saveRes) => {
              if (saveRes.success) {
                await that.reloadSystemEventsWithNewTranslations();
                resolve();
              } else {
                that.toaster.post(data.error);
                resolve();
              }
            }, () => {
              that.toaster.postError(that.trans('auth.errors.serverSideError'));
              resolve();
            }
          );
          that.subscriptions.push(languageSetListener);
        } else {
          that.toaster.post(data.error);
          resolve();
        }
      }, (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      });
    });
  }

  private confirmedDeletion() {
    const userEmail = this.us.currentUser.email;
    const accountDeletionListener = this.api.delete('/account?sum=true&acknowledged=true', true).subscribe(
      (result) => {
        if (result.success) {
          this.auth.forgetAccount(this.auth.getAccountByEmail(userEmail));
          this.api.setToken('');
          const loginType = this.us.getLoginType();
          if (loginType !== null && loginType === 'google') {
            this.google.signOut(true);
          }
          localStorage.removeItem('last_system');
          localStorage.removeItem('last_system_data');
          this.router.navigate(['/login']);
        } else {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Close,
            headerText: this.trans('general.titleError'),
            messageContent: result.error,
            iconType: this.messagebox.iconType.Error
          });
        }
      }
    );
    this.subscriptions.push(accountDeletionListener);
  }

  private async saveUserSettings(): Promise<boolean> {
    this.userSettingsData.name = this.userSettingsData.name.trim();
    this.userSettingsData.phone = this.userSettingsData.phone.trim();
    return new Promise((resolve) => {
      if (this.userSettingsData.phone !== null && this.userSettingsData.phone !== '' && !/^[0-9+\-\s]+$/.test(this.userSettingsData.phone)) {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Close,
          headerText: this.trans('general.titleError'),
          messageContent: this.trans('validation.phoneInvalid'),
          iconType: this.messagebox.iconType.Error
        });
        resolve(false);
      }
      const that = this;
      const old = JSON.stringify(this.userSettingsData);
      const formData = {
        name: this.userSettingsData.name,
        phone: this.userSettingsData.phone,
        dateFormat: this.userSettingsData.DateFormat,
        country: this.userSettingsData.country,
      };
      this.api.post('/profile', formData, true).subscribe((result) => {
        if (!result.success) {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleError'),
            messageContent: result.error,
            iconType: that.messagebox.iconType.Error
          });
          that.revertChanges(old);
          resolve(false);
        }
        resolve(true);
      }, () => {
        that.revertChanges(old);
        resolve(false);
      });
    });
  }

  private revertChanges(oldSettings: string) {
    if (this.us.currentUser.id === 0) { return; }
    const settings = JSON.parse(oldSettings);
    this.us.currentUser.name = settings.name;
    this.us.currentUser.phone = settings.phone;
    this.us.currentUser.date_format = settings.format;
    this.us.saveCurrentUser();
  }

  public doDownloadAccount() {
    const that = this;
    const accountDataDownloadListener = this.api.post('/download-account', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.getTheFile();
        } else {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleError'),
            messageContent: result.error,
            iconType: that.messagebox.iconType.Error
          });
        }
      },
      (error) => {
        that.messagebox.open({
          buttons: that.messagebox.buttons.Close,
          headerText: that.trans('general.titleError'),
          messageContent: that.trans('auth.errors.serverSideError'),
          iconType: that.messagebox.iconType.Error
        });
      }
    );
    this.subscriptions.push(accountDataDownloadListener);
  }

  private getTheFile() {
    document.location.href = this.api.getBaseUrl() + '/download-account-file?u=' + this.api.getToken() + (this.platform.isAndroid() ? '&an=1' : '');
  }

  public postPasswordChange(passwordData: {password: string; newPassword: string}) {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.showChangePasswordComponent = false;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const formData = {
      password: passwordData.password,
      newPassword: passwordData.newPassword,
      repeatPassword: passwordData.newPassword,
    };
    this.api.post('/change-password', formData, true).subscribe((result) => {
      if (result.success) {
        this.login.logout();
        this.router.navigate(['/login']);
      } else {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Close,
          headerText: this.trans('general.titleError'),
          messageContent: result.error,
          iconType: this.messagebox.iconType.Error
        });
      }
      this.miniStatus.hide();
    },
    () => {
      this.miniStatus.hide();
    });
  }

  private processLanguagesData() {
    const currentVersion = localStorage.getItem('language-list-version');
    const that = this;
    const languageVersionListener = this.api.get('/version', false, { keys: ['language-list'] }).subscribe(
      (response) => {
        if (response.success) {
          const values = response.result;
          if (values.length === 0) {
            that.loadLanguageList('');
          } else {
            if (values[0].version !== currentVersion) {
              that.loadLanguageList(values[0].version);
            } else {
              that.populateLanguageDropdownItems();
            }
          }
        } else {
          that.toaster.postError(response.error);
        }
      }
    );
    this.subscriptions.push(languageVersionListener);
  }

  private loadLanguageList(newVersion: string) {
    const that = this;
    const languageLoaderListener = this.api.get('/languages', false).subscribe(
      (result) => {
        const codes = Object.keys(result.list);
        that.ls.availableLanguages = [];
        for (const iCode of codes) {
          const minusPos = iCode.lastIndexOf('-');
          const dashPos = iCode.lastIndexOf('_');
          const lastPos = Math.max(minusPos, dashPos);
          const subCodePart = lastPos === -1 ? iCode : iCode.substr(lastPos + 1);
          that.ls.availableLanguages.push({
            code: iCode,
            name: result.list[iCode],
            version: result.versions[iCode],
            subCode: subCodePart.toUpperCase(),
          });
          localStorage.setItem('language-list-version', newVersion);
          localStorage.setItem('language-list-time', new Date().getTime().toString());
          localStorage.setItem('language-list', JSON.stringify(that.ls.availableLanguages));
        }
        that.populateLanguageDropdownItems();
      }
    );
    this.subscriptions.push(languageLoaderListener);
  }

  private async reloadSystemEventsWithNewTranslations(): Promise<void> {
    return new Promise((resolve) => {
      const promises = this.systems.systems.map(system => new Promise<void>((innerResolve) => {
        const personalPermissions = system.protegus_users.find(pu => pu.id === this.us.currentUser.id)?.personal_permissions;
        if(personalPermissions && !personalPermissions.events.view) {
          innerResolve();
        } else {
          const systemEventsReloadListener = requests.system.events.getEvents({ system_id: system.id }).subscribe((result) => {
            if (result.success) {
              const convertedEvents: TEventData[] = [];
              for (const iEvent of result.events) {
                const singleEvent = this.events.convertFromRaw(iEvent);
                convertedEvents.push(singleEvent);
              }
              system.events = convertedEvents;
            }
            innerResolve();
          }, () => {
            innerResolve();
          });
          this.subscriptions.push(systemEventsReloadListener);
        }
      }));
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  }

  private setFooterButtons() {
    this.footerButtons = [
      { label: this.trans('settings.buttons.save'),
        loadingLabel: this.trans('settings.buttons.saving'),
        loadingCompleteLabel: this.trans('settings.buttons.saved'),
        callback: () => { this.onSettingsSave(); }
      },
      { label: this.trans('general.cancel'),  callback: () => this.inputChanged = false },
    ];
  }

  public async validatePassword(): Promise<boolean> {
    return await this.valPasswordChange.validate(this);
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (mimeType !== 'image/png') {
      this.toaster.postError(this.trans('settings.errors.notAnImage'));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      this.fileToUploadUrl = reader.result;
    };
    this.fileToUpload = file;
    this.logoChanged = true;
    this.inputChanged = true;
  }

  public async removeLogo() {
    if ( this.miniStatus.isVisible() ) {
      this.miniStatus.flash();
      return;
    }
    if (!this.us.currentUser.logo && this.fileToUploadUrl !== null) {
      this.fileToUpload = null;
      this.fileToUploadUrl = null;
      this.fileInput.nativeElement.value = '';
      return;
    }
    try {
      await requests.user.removeInstallerLogo({ user_id: this.us.currentUser.id }).toPromise();
      this.us.currentUser.logo = null;
      this.fileToUpload = null;
      this.fileToUploadUrl = null;
    } catch(e) {
    }
  }

  public async saveLogo(): Promise<boolean> {
    if ( !this.fileToUpload ) {
      this.us.currentUser.logo = null;
      return true;
    }
    const headers = this.api.getAuthorizationHeader();
    headers.append('Content-type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('user_id', this.us.currentUser.id.toString());
    formData.append('logo_image', this.fileToUpload, this.fileToUpload.name);
    try {
      const result = await this.api.httpSender().post(this.api.getUrl('/installer/logo'), formData, { headers }).toPromise();
      this.us.currentUser.logo = (result as SaveInstallerLogoResponse).logoUrl;
      // const result = await requests.company.saveCompanyLogo({ company_id: this.companyToEdit.id, logo_image: this.fileToUpload }).toPromise();
      // this.companyToEdit.logo = this.companyService.logoBasePath + (result as SaveCompanyLogoResponse).logoUrl;
    } catch(e) {
      if ( e.error?.error ) {
        this.toaster.postError(e.error.error);
      }
      console.log('gavom klaida', e);
      return false;
    }
    return true;
  }

}

@Component({
    template: '<app-company-page-layout gridTemplateColumns="2fr 1fr"></app-company-page-layout>',
    imports: [CompanyPageLayoutComponent]
})
export class CompanyAccountSettingsLayoutComponent {}
