import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import requests from 'src/api/v3/requests';
import { AppSettings } from 'src/app-settings';
import Stripe from 'stripe';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { LanguageService } from './language.service';
import { ErrorResponse } from 'src/api/v3/common';
import { Company, StripeSettings } from 'src/api/v3/company';
import { UserService } from '../api/user.service';
import { RegionService } from '../api/region.service';
import { 
  AcceptStripeQuoteResponse,
  CreateCustomerPortalResponse,
  CreateStripeQuoteResponse,
  CreateStripeSettingsRequest,
  CreateStripeSettingsResponse,
  GetStripeAccountRequest,
  GetStripeAccountResponse,
  GetStripeWebhookEndpointRequest,
  GetStripeWebhookEndpointResponse,
  GetUpcomingInvoiceLinesResponse,
  UpdateStripeSettingsRequest,
  UpdateStripeSettingsResponse,
  UpdateSubscriptionResponse
} from 'src/api/v3/subscriptions';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public stripe: Stripe | undefined;
  public customers = new Map<string, Stripe.Customer>();
  public quotes = new Map<string, Stripe.Quote>();
  public subscriptions = new Map<string, Stripe.Subscription>();
  public productNames = new Map<string, string>();
  public productDropdownList: DropdownItem<string>[] = [];
  public products = new Map<string, Stripe.Product>();
  public coupons = new Map<string, Stripe.Coupon>();
  public invoices = new Map<string, Stripe.Invoice>();
  public upcomingInvoiceLines = new Map<string, Stripe.InvoiceLineItem[]>();
  public prices = new Map<string, Stripe.Price>();
  public taxRegistrations = new Map<string, Stripe.Tax.Registration>();
  public productPriceLists = new Map<string, DropdownItem<string>[]>();
  public couponList: DropdownItem<string>[] = [];
  public activeCompanyId: number = 0;
  public quoteListLineItems = new Map<string, Stripe.LineItem[]>();
  public activeStripeSettings: StripeSettings | undefined;
  public parentTestModeEnabled: boolean | null = null;
  public testModeEnabled: boolean = false;
  public stripeEnabled: boolean = false;
  public parentStripeEnabled: boolean | null = null;
  public subscriptionsListLoaded = false;
  public quotesListLoaded = false;
  public productAndPriceListLoaded = false;
  private onParentTestModeEnabledChangeSource = new Subject<boolean>();
  public onParentTestModeEnabledChange = this.onParentTestModeEnabledChangeSource.asObservable();
  private cleanUpSubscriber: Subscription;
  public accountCurrency: string = 'eur';
  public accountParentCurrency: string = 'eur';

  constructor(
    private auth: AuthService,
    private us: UserService,
    private lang: LanguageService,
    private regionService: RegionService,
  ) {
    this.resetStripeSettingParams();
    this.cleanUpSubscriber = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.clearServiceData();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public clearServiceData() {
    this.customers.clear();
    this.quotes.clear();
    this.subscriptions.clear();
    this.productNames.clear();
    this.products.clear();
    this.coupons.clear();
    this.invoices.clear();
    this.upcomingInvoiceLines.clear();
    this.prices.clear();
    this.productPriceLists.clear();
    this.quoteListLineItems.clear();
    this.taxRegistrations.clear();
    this.productDropdownList = [];
    this.couponList = [];
    this.activeCompanyId = 0;
    this.activeStripeSettings = undefined;
    this.stripeEnabled = false;
    this.testModeEnabled = false;
    this.parentTestModeEnabled = null;
    this.parentStripeEnabled = null;
    this.onParentTestModeEnabledChangeSource.next(null);
    this.subscriptionsListLoaded = false;
    this.quotesListLoaded = false;
    this.productAndPriceListLoaded = false;
    this.resetStripeSettingParams();
  }

  private async resetStripeSettingParams() {
    if(this.us.currentUser === null) {
      setTimeout(() => this.resetStripeSettingParams(), 300);
      return;
    }

    if(!this.us.currentUser.permissions?.permissions.company_payments.view || !AppSettings.companyPaymentsEnabled) {
      return;
    }

    const company = this.us.currentUser.belongsToCompany;

    if(company) {
      this.activeStripeSettings = this.us.currentUser.belongsToCompany.stripe_settings;
      this.testModeEnabled = this.activeStripeSettings?.test_mode ?? false;
      this.stripeEnabled = this.activeStripeSettings?.enabled ?? false;

      if(company.parent_id) {
        this.parentStripeEnabled = await this.getParentStripeEnabledStatus();
      } else {
        this.parentStripeEnabled = true;
      }

      const parentTestModeEnabled = await this.getParentTestModeStatus();
      this.onParentTestModeEnabledChangeSource.next(parentTestModeEnabled);
      this.parentTestModeEnabled = parentTestModeEnabled;
    } else {
      this.activeStripeSettings = undefined;
      this.testModeEnabled = false;
      this.stripeEnabled = true;
      this.parentTestModeEnabled = false;
      this.parentStripeEnabled = null;
    }

    this.getTaxRegistrations();
    this.setDefaultCurrency();
  }

  public async createCustomer(company: Company): Promise<string> {
    const createStripeCustomerParams: Stripe.CustomerCreateParams = this.getStripeCustomerParams(company);
    return new Promise((resolve, reject) => {
      requests.subscriptions.createCustomer({ company_id: company.id, test_mode: this.testModeEnabled, params: createStripeCustomerParams }).subscribe(response => {
        if(response.success) {
          const stripeCustomerData = response.data;
          this.customers.set(stripeCustomerData.id, stripeCustomerData);
          resolve(stripeCustomerData.id);
        } else {
          reject((response as ErrorResponse).error);
        }
      });
    });
  }

  private getStripeCustomerParams(company: Company): Stripe.CustomerCreateParams | Stripe.CustomerUpdateParams {
    const createStripeCustomerParams: Stripe.CustomerCreateParams = {
      name: company.name,
      email: company.email,
      address: {
        city: company.city,
        country: company.country,
        line1: company.address,
      },
      invoice_settings: {
        custom_fields: [
          {
            name: 'Seller VAT ID',
            value: this.us.currentUser.belongsToCompany ? this.us.currentUser.belongsToCompany.vat_id : AppSettings.taxId,
          }
        ]
      },
      phone: company.phone,
      tax_exempt: this.taxRegistrations.get(company.country) ? 'none' : 'reverse',
    }
    if(company.vat_id && createStripeCustomerParams['invoice_settings']['custom_fields']) {
      createStripeCustomerParams['invoice_settings']['custom_fields'].push({
          name: 'Buyer VAT ID',
          value: company.vat_id,
      });
    }

    return createStripeCustomerParams;
  }

  public async setStripeProductsAndPrices(): Promise<void> {
    this.productAndPriceListLoaded = true;
    return new Promise(async (resolve) => {
      await this.setStripeProducts();
      await this.setStripePrices();
      this.buildProductsDropdownList();
      resolve();
    });
  }

  public async setStripeQuote(id: string, use_parent_key: boolean = false): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getQuote({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          if(response.data.status === 'open' || (response.data.status === 'accepted' && response.data.subscription_schedule)) {
            this.quotes.set(response.data.id, response.data);
          }
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  public async setStripeQuotes(): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getQuotes().subscribe(response => {
        if(response.success) {
          const stripeQuotesData = response.data;
          stripeQuotesData.forEach(q => {
            if(q.status === 'open' || (q.status === 'accepted' && q.subscription_schedule)) {
              this.quotes.set(q.id, q);
            }
          });
          this.quotesListLoaded = true;
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  public async setStripeSubscription(id: string, use_parent_key: boolean = false): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getSubscription({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          this.subscriptions.set(response.data.id, response.data);
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  public async setStripeSubscriptions(): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getSubscriptions().subscribe(response => {
        if(response.success) {
          const stripeSubscriptionsData = response.data;
          stripeSubscriptionsData.forEach(s => {
            if(s.status !== 'canceled') { 
              const customerSubscription = this.subscriptions.get(s.id);
              if(!customerSubscription || customerSubscription.status !== 'active' && customerSubscription.status !== 'trialing') {
                this.subscriptions.set(s.id, s);
              }
            }
          });
          this.subscriptionsListLoaded = true;
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  public async getInvoice(id: string, use_parent_key: boolean = false): Promise<Stripe.Invoice> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.getInvoice({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          const invoice = response.data;
          this.invoices.set(invoice.id, invoice);
          resolve(invoice);
        } else {
          reject();
        }
      }, () => { reject(); });
    });
  }

  public async getUpcomingInvoiceLines(id: string, use_parent_key: boolean = false): Promise<GetUpcomingInvoiceLinesResponse> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.getUpcomingInvoiceLines({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          const upcomingInvoiceLines = response.data;
          this.upcomingInvoiceLines.set(id, upcomingInvoiceLines);
        }
        resolve(response);
      }, () => { reject(); });
    });
  }

  public async getCoupons() {
    return new Promise((resolve, reject) => {
      requests.subscriptions.getCoupons().subscribe(response => {
        if(response.success) {
          const stripeCouponsData = response.data;
          if(stripeCouponsData.length > 0) {
            this.couponList = [{ label: 'None', value: '' }];
            stripeCouponsData.forEach(c => {
              this.couponList.push({
                label: c.name,
                value: c.id,
              });
              this.coupons.set(c.id, c);
            });
          }
          resolve(stripeCouponsData);
        } else {
          reject();
        }
      }, () => { reject(); });
    });
  }

  private async getTaxRegistrations() {
    const taxRegistrationsResponse = await requests.subscriptions.getTaxRegistrations().toPromise();
    if(taxRegistrationsResponse.success) {
      const taxRegistrations = taxRegistrationsResponse.data;
      taxRegistrations.forEach(taxRegistration => {
        this.taxRegistrations.set(taxRegistration.country, taxRegistration);
      });
    }
  }

  public async getQuoteListLineItems(id: string, use_parent_key: boolean = false): Promise<Stripe.LineItem[]> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.getQuoteListLineItems({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          const stripeQuoteListLineItemsData = response.data;
          stripeQuoteListLineItemsData.forEach(q => {
            const existingQuoteListLineItem = this.quoteListLineItems.get(id);
            if(existingQuoteListLineItem) {
              existingQuoteListLineItem.push(q);
              this.quoteListLineItems.set(id, existingQuoteListLineItem);
            } else {
              this.quoteListLineItems.set(id, [q]);
            }
          });
          resolve(stripeQuoteListLineItemsData);
        } else {
          reject();
        }
      }, () => { reject(); });
    });
  }

  public async acceptQuoteAndFinalizeInvoice(id: string, navigateToPayment: boolean): Promise<Stripe.Subscription> {
    return new Promise(async (resolve, reject) => {
      const response = await this.acceptQuote(id, true);

      if(response.success) {
        const acceptedQuoteResponse = response.data;
        if(navigateToPayment) {
          window.open(acceptedQuoteResponse.invoice.hosted_invoice_url, '_blank');
        }
        await this.setStripeSubscription(acceptedQuoteResponse.invoice.subscription as string, true);
        resolve(this.subscriptions.get(acceptedQuoteResponse.invoice.subscription as string));
      }
      reject();
    });
  }

  public async acceptQuote(id: string, finalizeInvoice: boolean): Promise<AcceptStripeQuoteResponse> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.acceptQuote({ id, finalize_invoice: finalizeInvoice }).subscribe(response => {
        if(response.success) {
          const quote = response.data.quote;
          this.quotes.set(quote.id, quote);
          resolve(response);
        }
        reject();
      }, () => { reject(); });
    });
  }

  public async createCustomerPortal(id: string, return_url: string): Promise<CreateCustomerPortalResponse> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.createCustomerPortal({ customer: id, return_url }).subscribe(response => {
        if(response.success) {
          window.open(response.data.url, '_blank');
        }
        resolve(response);
      }, (e) => { reject(e); });
    });
  }

  public async cancelQuote(id: string, use_parent_key: boolean): Promise<Stripe.Quote> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.cancelQuote({ id, use_parent_key }).subscribe(response => {
        if(response.success) {
          const deletedQuote = response.data;
          this.quotes.delete(deletedQuote.id as string);
          resolve(deletedQuote);
        }
        reject();
      }, () => { reject(); });
    });
  }
  
  public async cancelSubscription(id: string, use_parent_key: boolean): Promise<UpdateSubscriptionResponse> {
    const delete_discount = false;
    const canceledSubscriptionResponse = await this.updateSubscription(id, { cancel_at_period_end: true }, use_parent_key, delete_discount);
    if(canceledSubscriptionResponse.success) {
      this.subscriptions.set(id, canceledSubscriptionResponse.data);
    }

    return canceledSubscriptionResponse;
  }

  public async createQuote(params: Stripe.QuoteCreateParams): Promise<CreateStripeQuoteResponse> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.createQuote({ params }).subscribe(async response => {
        if(response.success) {
          const finalizedQuoteData = response.data;
          this.quotes.set(finalizedQuoteData.id, finalizedQuoteData);
        }
        resolve(response);
      }, () => { reject(); });
    });
  }

  public async updateSubscription(id: string, params: Stripe.SubscriptionUpdateParams, use_parent_key: boolean, delete_discount: boolean): Promise<UpdateSubscriptionResponse> {
    return new Promise((resolve, reject) => {
      const subscriptionUpdateParams = {
        id,
        params,
        use_parent_key,
        delete_discount,
      }
      requests.subscriptions.updateSubscription(subscriptionUpdateParams).subscribe(response => {
        if(response.success) {
          this.subscriptions.set(response.data.id, response.data);
        }
        resolve(response);
      }, (error) => { reject(error) });
    });
  }

  public showSubscriptionDate(date: DateTime | number, addDays: number): string {
    let dateTime: DateTime;
    if (date instanceof DateTime) {
      dateTime = date;
    } else if (typeof date === 'number') {
      dateTime = DateTime.fromSeconds(date);
    } else {
      return '';
    }
    const modifiedDate = dateTime.set({ second: 0, millisecond: 0 }).plus({ days: addDays });
    
    return modifiedDate.toLocaleString({
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  public getUpcomingInvoiceItemsTotalPrice(items: Stripe.InvoiceLineItem[], includeDiscount: boolean): number {
    let sum = 0;
    items.forEach(i => {
      sum += this.getUpcomingInvoiceItemPrice(i, includeDiscount);
    });
    
    return sum;
  }

  public getUpcomingInvoiceItemsTotalTax(items: Stripe.InvoiceLineItem[]): number {
    let sum = 0;
    items.forEach(i => {
      sum += this.getUpcomingInvoiceItemTax(i);
    });
    
    return sum;
  }

  public subscriptionTotalPrice(items: Stripe.SubscriptionItem[] | Stripe.QuoteCreateParams.LineItem[] | Stripe.LineItem[] | Stripe.InvoiceLineItem[], coupon?: Stripe.Coupon): number {
    let sum = 0;
    if(!items) { return 0 }
    items.forEach(i => {
      if(i.deleted) { return; }
      sum += this.productTotalPrice(i);
    });
    
    return sum * (coupon ? ((100 - coupon.percent_off) / 100) : 1);
  }
  
  public subscriptionTotalPriceDiscount(items: Stripe.SubscriptionItem[] | Stripe.QuoteCreateParams.LineItem[] | Stripe.LineItem[] | Stripe.InvoiceLineItem[]): number {
    let sum = 0;
    if(!items) { return 0 }
    items.forEach(i => {
      if(i.deleted) { return; }
      sum += i.discount_amounts[0].amount;
    });
    
    return sum / 100;
  }

  public getUpcomingInvoiceItemPrice(item: Stripe.InvoiceLineItem, includeDiscount: boolean, fixedQuantity?: number): number {
    let discountAmount = 0;
    if(includeDiscount) {
      if(item.discount_amounts.length > 0) {
        item.discount_amounts.forEach(discount => discountAmount += discount.amount);
      }
    }

    return  (fixedQuantity ? (fixedQuantity * (item.plan.amount - discountAmount)) : (item.amount - discountAmount)) / 100;
  }

  public getUpcomingInvoiceItemTax(item: Stripe.InvoiceLineItem): number {
    let taxAmount = 0;
    if(item.tax_amounts.length > 0) {
      item.tax_amounts.forEach(tax => taxAmount += tax.amount);
    }

    return taxAmount / 100;
  }

  public productTotalPrice(productItem: Stripe.SubscriptionItem | Stripe.QuoteCreateParams.LineItem | Stripe.LineItem, fixedQuantity?: number, couponId?: string): number {
    const quantityToUse = fixedQuantity ?? productItem.quantity ?? 0;
    const coupon = this.coupons.get(couponId);
    let discountAmount = 0;
    if(productItem['discount_amounts']) {
      productItem['discount_amounts'].forEach(discount => discountAmount += discount.amount / (fixedQuantity ? productItem.quantity ?? 1 : 1));
    }
    const priceToUse = (productItem['amount_total'] ?? (productItem['price_data'] ? productItem['price_data'].unit_amount : (typeof productItem['price'] === 'string') ? (this.prices.get(productItem['price'])?.unit_amount ?? 0) : productItem['price']['unit_amount'] ?? 0));
    
    return ((quantityToUse * priceToUse) - (isNaN(discountAmount) ? 0 : discountAmount)) / 100 * (coupon ? ((100 - coupon.percent_off) / 100) : 1);
  }

  public trialDaysCount(start: number, end: number) {
    const startDate = DateTime.fromSeconds(start);
    const endDate = DateTime.fromSeconds(end);
    const diffInDays = endDate.diff(startDate, 'days').days;
  
    return Math.abs(diffInDays);
  }

  public subscriptionItemName(value: string): string {
    return this.productNames.get(value);
  }

  private async setStripeProducts(): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getProducts().subscribe(async response => {
        if(response.success) {
          const stripeProductsData = response.data;
          stripeProductsData.forEach(product => {
            this.products.set(product.id, product);
            this.productNames.set(product.id, product.name);
          });
          this.setParentStripeProductNames();
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  private async setParentStripeProductNames(): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getParentProductNames().subscribe(async response => {
        if(response.success) {
          const stripeProductNames = response.data;
          stripeProductNames.forEach(product => {
            this.productNames.set(product.id, product.name);
          });
          resolve();
        } else {
          resolve();
        }
      }, () => { resolve(); });
    });
  }

  private async setStripePrices(): Promise<void> {
    return new Promise((resolve) => {
      requests.subscriptions.getPrices().subscribe(response => {
        if(response.success) {
          const stripePricesData = response.data;
          this.buildPricesAndProductPriceList(stripePricesData);
          resolve();
        } else {
          resolve();
        };
      }, () => { resolve(); });
    });
  }

  private buildPricesAndProductPriceList(stripePricesData: Stripe.Price[]) {
    stripePricesData.forEach(p => {
      this.prices.set(p.id, p);
      const productPriceList = this.productPriceLists.get(p.product as string) ?? [];
      if(productPriceList.length === 0 && p.active) {
        this.productPriceLists.set(p.product as string, [{
          label: (p.unit_amount / 100).toString(),
          value: p.id,
          subLabels: (p.recurring && p.recurring.usage_type === 'metered') ? [p.currency.toUpperCase() + ' ' + this.lang.get('payments.labels.perUnit') + ' / ' + this.lang.get('payments.periods.short.' + p.recurring.interval_count)] : undefined,
        }]);
      } else {
        if(p.type !== 'recurring' || !p.active) { return; }
        productPriceList.push({
          label: (p.unit_amount / 100).toString(),
          value: p.id,
          subLabels: p.recurring.usage_type === 'metered' ? [p.currency.toUpperCase() + ' ' + this.lang.get('payments.labels.perUnit') + ' / ' + this.lang.get('payments.periods.short.' + p.recurring.interval_count)] : undefined,
        });
        this.productPriceLists.set(p.product as string, productPriceList);
      }
    });
  }

  private async buildProductsDropdownList() {
    this.productDropdownList = [];
    this.products.forEach(product => {
      this.productDropdownList.push({
        label: product.name,
        value: product.id,
        subLabels: this.prices.get(product.default_price as string)?.recurring.usage_type === 'metered' ? [this.lang.get('payments.labels.pricePerUsage')] : undefined
      });
    });
  }
  
  public isFixed(id: string): boolean {
    const product = this.products.get(id);

    return product ? this.prices.get(product.default_price as string)?.recurring.usage_type !== 'metered' : true;
  }

  public createStripeSettings(params: CreateStripeSettingsRequest): Promise<CreateStripeSettingsResponse> {
    return new Promise((resolve) => {
      requests.subscriptions.createStripeSettings(params).subscribe((response) => {
        resolve(response);
      }, () => { resolve( { success: false } as ErrorResponse ) });
    });
  }

  public updateStripeSettings(params: UpdateStripeSettingsRequest): Promise<UpdateStripeSettingsResponse> {
    params['region_base_url'] = this.regionService.regionBaseUrl;
    return new Promise((resolve) => {
      requests.subscriptions.updateStripeSettings(params).subscribe((response) => {
        resolve(response);
      }, () => { resolve( { success: false } as ErrorResponse ) });
    });
  }

  public getStripeAccount(request: GetStripeAccountRequest): Promise<GetStripeAccountResponse> {
    return new Promise((resolve) => {
      requests.subscriptions.getStripeAccount(request).subscribe((response) => {
        resolve(response);
      }, () => { resolve( { success: false } as ErrorResponse ); });
    });
  }

  public getStripeWebhookEndpoint(request: GetStripeWebhookEndpointRequest): Promise<GetStripeWebhookEndpointResponse> {
    return new Promise((resolve, reject) => {
      requests.subscriptions.getStripeWebhookEndpoint(request).subscribe((response) => {
        if(response.success) {
          resolve(response);
        } else {
          reject();
        }
      }, () => { reject(); });
    });
  }

  public async getParentTestModeStatus(): Promise<boolean> {
    return new Promise((resolve) => {
      requests.subscriptions.getParentParamStatus({ param: 'test_mode' }).subscribe((response) => {
        if(response.success) {
          resolve(response.data);
        } else {
          resolve(false);
        }
      }, () => { resolve(false); });
    });
  }

  public async getParentStripeEnabledStatus(): Promise<boolean> {
    return new Promise((resolve) => {
      requests.subscriptions.getParentParamStatus({ param: 'enabled' }).subscribe((response) => {
        if(response.success) {
          resolve(response.data);
        } else {
          resolve(false);
        }
      }, () => { resolve(false); });
    });
  }

  public setStripeEnabledValue(value: boolean): void {
    this.stripeEnabled = AppSettings.companyPaymentsEnabled && value;
  }

  private setDefaultCurrency() {
    if ( !this.us.currentUser?.permissions.permissions.company_settings.view ) {
      return;
    }
    requests.subscriptions.getStripeCurrencySettings().subscribe((response) => {
      if(response.success) {
        if(response.data.default_currency) {
          this.accountCurrency = response.data.default_currency;
        }
        if(response.data.default_currency_parent) {
          this.accountParentCurrency = response.data.default_currency_parent;
        }
      }
    });
  }

}
