import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnDestroy {
  public isLoading = true;
  private routerEventsSubscribtion = this.router.events.subscribe((event) => {
    if (event instanceof ResolveStart && event.id === 1) {
      this.isLoading = true;
    } else if (event instanceof ResolveEnd) {
      if (event.url !== '/') this.isLoading = false;
    } else if (event instanceof NavigationStart && event.id === 1) {
      this.isLoading = true;
    } else if (event instanceof NavigationEnd) {
      if (event.url !== '/') this.isLoading = false;
    }
  });

  constructor(private router: Router) {
    document.documentElement.style.setProperty('--color-primary', AppSettings.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', AppSettings.secondaryColor);
    
    window.backJsi = {
      doBack: () => { }, // Nebus klaidos tuose languose, kuriuose nėra nei BackButton, nei HeaderService, o vartotojas spaus Back telefono mygtuką (Android)
    };
  }

  ngOnDestroy(): void {
    this.routerEventsSubscribtion.unsubscribe();
  }
}
