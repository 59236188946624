import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
    selector: 'app-button-single-line-arrow',
    templateUrl: './button-single-line-arrow.component.html',
    styleUrls: ['./button-single-line-arrow.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [InlineSVGModule, CommonModule]
})
export class ButtonSingleLineArrowComponent implements OnInit {
  @Input() icon = '';
  @Input() iconObject = null;
  @Input() withPadding = true;
  @Input() showArrow = true;
  @Input() fixedColor = false;
  @Input() iconSecondary = false;

  constructor() {}

  ngOnInit(): void {}

  public showIcon(): boolean {
    return this.icon !== '' || this.iconObject !== null;
  }

  public get iconCss(): string {
    return `url('${this.icon}')`;
  }
}
