import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
    imports: []
})
export class FormErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
