import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/services/header.service';
import { MiniStatusService } from 'src/app/services/mini-status.service';
import { PlatformService } from 'src/app/api/platform.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { TDotMenuItem } from 'src/app/models/dot-menu-item';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { LoggerService } from 'src/app/api/logger.service';
import { TSwipeCoordinate } from 'src/app/models/swipe-coordinate';
import { GlobalService } from 'src/app/services/global.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { SystemsService } from 'src/app/services/systems.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { AppSettings } from 'src/app-settings';
import { UserService } from 'src/app/api/user.service';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/api/company.service';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { DotComponent } from '../dot/dot.component';
import { CompanyLogoComponent } from "../../components/company-logo/company-logo.component";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('spin', [state('1', style({ transform: 'rotate(0deg)' })), state('2', style({ transform: 'rotate(360deg)' })), transition('1 => 2', animate('1s'))]),
        trigger('flashMinistatus', [state('1', style({ background: 'none' })), state('2', style({ background: '#ffdb3b' })), transition('2 => 1', animate(500))]),
        trigger('learningIcon', [state('0', style({ opacity: 1 })), state('1', style({ opacity: 0.5 })), transition('0 <=> 1', animate(500)),]),
    ],
    imports: [CommonModule, InlineSVGModule, SidemenuComponent, DotComponent, ContextMenuComponent, CompanyLogoComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public spinState = '1';
  public touchStartedAt: TSwipeCoordinate = null;
  private lastMovementSample: Date = new Date();
  // % Nurodo iki kurios vietos nuo kairio ekrano krašto skaitosi, kad yra aktyvi zona nuo kurios atidaromas side meniu.
  private sideMenuSwipeThereshold = 10;
  // % Nurodo kiek turi pajudėti pelė/pirštas po to kai yra įspausta, kad užsiskaitytų kaip side meniu tempimas.
  private sideMenuSwipeActivationThereshold = 1;
  // % Nurodo kiek turi pajudėti pelė/pirštas kad po pakėlimo būtų automatiškai atidaromas meniu.
  private sideMenuSwipeDistanceToOpen = 15;
  // % Nurodo kiek turi pajudėti pelė/pirštas kad būtų automatiškai uždaromas meniu.
  private sideMenuSwipeDistanceToClose = 15;
  // % Nurodo pradinę sidemeniu padėtį (kai paslėptas). Side meniu yra 2x ekrano pločio todėl negalima naudoti -100
  private sideMenuStartingPostion = -40;
  // % Nurodo kokioje pozicijoje šiuo metu yra tempiamas side meniu.
  public sideMenuPosition = -40;
  private swipeDirection = 'r';
  private reenableRefresher = false; // Nurodo ar reikės įgalinti refresh komponentą kai baigsim swipe.
  public newVersionAvailable = false;
  public learningIconState = '0';
  /**
   * Naudojama tik tuo metu, kai side menu atidarytas.
   * Nurodo pikselių kiekį x ašimi, nuo kada galima fiksuoti, kad žmogus nori tempti sidemenu
   */
  private sideMenuGripHandleStart = 260;
  public visibility = {
    miniStatus: false,
    learningIcon: false,
    toaster: false,
    backButton: false,
    darkMode: true,
  };
  public finalVisibility = {
    backButton: false,
  };
  private subscribers: Subscription[] = [];
  private learningIconStateChange: Subscription;
  @ViewChild(SidemenuComponent, { read: null, static: false }) menu;
  @ViewChild(ContextMenuComponent, { read: null, static: false }) context;

  constructor(
    public headerService: HeaderService,
    public ms: MiniStatusService,
    public ps: PlatformService,
    public ts: ToasterService,
    public router: Router,
    private l: LoggerService,
    private g: GlobalService,
    private r: RefreshService,
    public us: UserService,
    public ss: SystemsService,
    private ws: WebSocketService,
    public companyService: CompanyService,
  ) {
    this.checkIfVersionIsNew();
    this.visibility.miniStatus = this.ms.isVisible();
    this.visibility.learningIcon = this.headerService.learningIconIsVisible();
    //this.visibility.toaster = this.ts.isVisible();
    this.handleBackButtonVisibility(this.headerService.backButtonIsVisible());
    this.visibility.darkMode = this.headerService.headerDarkMode();
    this.changeLearningIconState(this.visibility.learningIcon);
  }

  ngOnInit() {
    this.subscribers.push(this.g.onSwipeStarted.subscribe((coords: TSwipeCoordinate) => {
      this.handleStart(coords);
    }));
    this.subscribers.push(this.g.onSwipeContinue.subscribe((coords: TSwipeCoordinate) => {
      this.handleMove(coords);
    }));
    this.subscribers.push(this.g.onSwipeEnded.subscribe((coords: TSwipeCoordinate) => {
      this.handleEnd(coords);
    }));
    this.subscribers.push(this.g.onNewVersionAvailable.subscribe(() => {
      this.checkIfVersionIsNew();
    }));
    this.subscribers.push(this.us.onUserDataChanged.subscribe(() => {
      this.checkIfVersionIsNew();
    }));
    this.subscribers.push(this.headerService.onShowLearningIconValueChange.subscribe((value: boolean) => {
      this.changeLearningIconState(value);
    }));
    this.subscribers.push(this.ms.onVisibilityChange.subscribe((nowVisible: boolean) => {
      this.l.log('MiniStatus visible: ' + (nowVisible ? 'yes' : 'no'), 'Header');
      this.visibility.miniStatus = nowVisible;
      this.handleBackButtonVisibility(this.visibility.backButton);
    }));
    // this.subscribers.push(this.ts.onToasterVisibilityChange.subscribe((nowVisible: boolean) => {
    //   this.l.log('Toaster visible: ' + (nowVisible ? 'yes' : 'no'), 'Header');
    //   this.visibility.toaster = nowVisible;
    //   this.handleBackButtonVisibility(this.visibility.backButton);
    // }));
    this.subscribers.push(this.headerService.onBackButtonVisibilityChange.subscribe((nowVisible: boolean) => {
      this.l.log('Back button visible: ' + (nowVisible ? 'yes' : 'no'), 'Header');
      this.visibility.backButton = nowVisible;
      this.handleBackButtonVisibility(nowVisible);
    }));
    this.subscribers.push(this.headerService.onDarkModeChange.subscribe((isDark: boolean) => {
      this.l.log('Dark mode: ' + (isDark ? 'yes' : 'no'), 'Header');
      this.visibility.darkMode = isDark;
    }));
  }

  ngOnDestroy() {
    for ( const iSub of this.subscribers ) {
      iSub?.unsubscribe();
    }
    this.learningIconStateChange?.unsubscribe();
  }

  public hamburgerClick() {
    this.l.log('toggle menu', 'Header');
    this.showSidemenu();
  }

  public backButtonClicked() {
    if (this.visibility.miniStatus) {
      this.ms.flash();
      return;
    }
    this.headerService.doBack();
  }

  doRepeat(e) {
    if (this.spinState === '1') {
      this.spinState = '2';
    } else {
      this.spinState = '1';
    }
  }

  public closeToast() {
    this.ts.close();
  }

  public showSidemenu() {
    this.menu.show();
  }

  public hideSidemenu() {
    this.menu.hide();
  }

  public getDotMenuItems(): TDotMenuItem[] {
    return this.headerService.getDotMenuItems();
  }

  public contextClick(url: string | (() => void)) {
    this.context.hideMenu();
    if ( typeof url === 'function' ) {
      url();
    } else {
      this.router.navigateByUrl(url);
    }
  }

  public onAction() {
    if (this.headerService.onActionButtonClicked !== null) {
      this.headerService.onActionButtonClicked();
    }
  }

  public onXAction() {
    if (this.headerService.onXButtonClicked !== null) {
      this.headerService.onXButtonClicked();
    }
  }

  public onHomeAction() {
    if (this.headerService.onHomeButtonClicked !== null) {
      this.headerService.onHomeButtonClicked();
    }
  }

  private handleStart(coords: TSwipeCoordinate) {
    if (this.headerService.sideMenuIsDisabled) {
      return;
    }
    if (this.headerService.sideMenuOpen && coords.x < this.sideMenuGripHandleStart && coords.y > 80 && coords.y < window.innerHeight - 80) {
      return; // Nieko nedarom sitam diapazone, kad scrolinant nesitampytu
    }
    if (this.headerService.sideMenuOpen) {
      this.swipeDirection = 'l'; // left
    } else {
      this.swipeDirection = 'r'; // right
    }
    if (this.swipeDirection === 'r' && coords.leftPercent <= this.sideMenuSwipeThereshold) {
      this.touchStartedAt = coords;
      this.sideMenuPosition = this.sideMenuStartingPostion + coords.leftPercent / 2;
      this.reenableRefresher = this.r.refresherIsEnabled;
      this.r.disableRefresher();
    } else if (this.swipeDirection === 'l') {
      this.touchStartedAt = coords;
    }
  }

  private handleMove(coords: TSwipeCoordinate) {
    if (this.headerService.sideMenuIsDisabled) {
      return;
    }
    if (this.touchStartedAt === null) {
      return;
    }
    if (new Date().getTime() - this.lastMovementSample.getTime() < 100) {
      return;
    }
    if (this.swipeDirection === 'r') {
      if (coords.leftPercent - this.touchStartedAt.leftPercent <= this.sideMenuSwipeActivationThereshold) {
        return;
      }
      if (!this.headerService.sideMenuOpen) {
        this.headerService.sideMenuOpen = true;
      }
      this.sideMenuPosition = this.sideMenuStartingPostion + coords.leftPercent / 2;
    } else if (this.swipeDirection === 'l') {
      if (this.touchStartedAt.leftPercent - coords.leftPercent <= this.sideMenuSwipeActivationThereshold) {
        return;
      }
      this.sideMenuPosition = (coords.leftPercent - this.touchStartedAt.leftPercent) / 2;
    }
    if (this.sideMenuPosition > 0) {
      this.sideMenuPosition = 0;
    } else if (this.sideMenuPosition < this.sideMenuStartingPostion - 5) {
      this.sideMenuPosition = this.sideMenuStartingPostion - 5;
    }
  }

  private handleEnd(coords: TSwipeCoordinate) {
    if (this.headerService.sideMenuIsDisabled) {
      return;
    }
    if (this.touchStartedAt === null) {
      return;
    }
    if (this.reenableRefresher) {
      this.r.enableRefresher();
    }
    // Netempė pakankamai toli, kad būtų registruotas meniu atidarymas, todėl uždarom.
    if (this.swipeDirection === 'r' && coords.leftPercent - this.touchStartedAt.leftPercent < this.sideMenuSwipeDistanceToOpen) {
      this.touchStartedAt = null;
      if (this.headerService.sideMenuOpen) {
        this.hideSidemenu();
      }
      return;
      // Jeigu į kairę patempė pakankamai toli, tai uždarom meniu.
    } else if (this.swipeDirection === 'l' && this.touchStartedAt.leftPercent - coords.leftPercent >= this.sideMenuSwipeDistanceToClose) {
      this.touchStartedAt = null;
      this.hideSidemenu();
      return;
    }
    this.touchStartedAt = null;
    this.sideMenuPosition = 0; // Pilnai matomas sidemeniu.
  }

  public getHomeColor(): string {
    return this.headerService.getHomeButtonColor();
  }

  private checkIfVersionIsNew() {
    this.newVersionAvailable = this.g.isNewVersionAvailable();
  }

  public canShowBubble(): boolean {
    return AppSettings.showAppVersion;
  }

  private changeLearningIconState(value: boolean) {
    if( !this.visibility.learningIcon && value ) {
      this.learningIconStateChange = interval(500).subscribe(() => {
        this.learningIconState = this.learningIconState === '0' ? '1' : '0';
      });
    } else if ( !value ) {
      this.learningIconStateChange?.unsubscribe();
    }
    this.visibility.learningIcon = value;
  }

  private handleBackButtonVisibility(val: boolean) {
    this.finalVisibility.backButton = val && !this.visibility.miniStatus && !this.visibility.toaster;
  }

}
