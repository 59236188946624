import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CloudNotification, CreateCloudNotificationRequest, CreateCloudNotificationResponse, DeleteCloudNotificationRequest, GetCloudNotificationsResponse, UpdateCloudNotificationRequest, VisibilityValue } from 'src/api/v3/cloud-notifications';
import requests from 'src/api/v3/requests';
import { AuthService } from '../api/auth.service';
import { UserService } from '../api/user.service';
import { PermissionRole } from 'src/api/v3/permission';
import { ApiTranslation } from 'src/api/v3/language';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class CloudNotificationService {
  private loadedCount:              number = 0;
  public isFetchingNotifications:   boolean = false;
  public initialFetchComplete:      boolean = false;
  public isVisiblePreview:          boolean = false;
  public hasMore:                   boolean = true;
  public notifications:             Map<number, CloudNotification> = new Map();
  public activeNotifications:       Map<number, CloudNotification> = new Map();
  public translations:              Map<number, ApiTranslation> = new Map();
  public previewNotification:       CloudNotification = null;
  public showTranslationForIds:     Set<number> = new Set();
  private cleanUpSubscriber:        Subscription;

  constructor(
    private auth: AuthService,
    private us: UserService,
  ) {
    this.cleanUpSubscriber = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.resetServiceParams();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public fetchNotification(id: number): Promise<CloudNotification | null> {
    return new Promise((resolve) => {
      requests.cloudNotifications.getCloudNotification({ id }).subscribe({
        next: (response) => {
          if(!response.success) {
            this.removeNotificationFromExistingLists(id);
            resolve(null);
            return;
          }
          const notification = response.data.notification;
          if(response.data.hasAccess) {
            this.setNotification(notification, true);
          } else {
            if(this.activeNotifications.has(id)) {
              this.activeNotifications.delete(id);
              this.sortActiveNotifications();
            }
          }
          resolve(notification);
        },
        error: () => resolve(null),
      });
    });
  }

  public fetchNotifications(): void {
    if(this.isFetchingNotifications) return;

    this.isFetchingNotifications = true;
    if(!this.initialFetchComplete) this.notifications.clear();
    requests.cloudNotifications.getCloudNotifications({ loaded: this.loadedCount }).subscribe({
      next: (response) => this.handleFetchNotificationsResponse(response),
      complete: () => {
        this.isFetchingNotifications = false;
        this.initialFetchComplete = true;
      },
      error: () => this.isFetchingNotifications = false,
    });
  }

  public async create(req: CreateCloudNotificationRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.cloudNotifications.createCloudNotification(req).subscribe({
        next: (response) => {
          if(response.success) {
            const id = response.data.id;
            this.handleNotificationResponse(id, response, true);
          }
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  public async update(req: UpdateCloudNotificationRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.cloudNotifications.updateCloudNotification(req).subscribe({
        next: (response) => {
          this.handleNotificationResponse(req.id, response, true);
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  public async delete(req: DeleteCloudNotificationRequest): Promise<boolean> {
    return new Promise((resolve) => {
      requests.cloudNotifications.deleteCloudNotification(req).subscribe({
        next: (response) => {
          this.handleNotificationResponse(req.id, response, true);
          resolve(response.success);
        },
        error: () => resolve(false),
      });
    });
  }

  public setActiveNotification(notification: CloudNotification, sort: boolean): void {
    if(this.isCloudNotificationClosed(notification.id)) return;

    if(this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin && notification?.visibility !== VisibilityValue.All) return;

    if(notification.active) {
      this.activeNotifications.set(notification.id, notification);
    } else {
      this.activeNotifications.delete(notification.id);
    }
    if(!sort) return;
    this.sortActiveNotifications();
  }

  public sortActiveNotifications() {
    const sortedNotifications = new Map([...this.activeNotifications.entries()].sort((a, b) => b[1].valid_from - a[1].valid_from));
    this.activeNotifications = sortedNotifications;
  }

  private handleFetchNotificationsResponse(response: GetCloudNotificationsResponse) {
    if(response.success) {
      const sortingRequired = this.activeNotifications.size > 0;
      response.data.forEach((notification) => {
        this.setNotification(notification, false);
      });
      if(sortingRequired) {
        this.sortActiveNotifications();
      }
      this.loadedCount += response.data.length;
      this.hasMore = response.hasMore;
    }
  }

  private handleNotificationResponse(id: number, response: CreateCloudNotificationResponse, sort: boolean) {
    if(response.success) {
      const notification = response.data;
      this.setNotification(notification, sort);
    } else {
      this.removeNotificationFromExistingLists(id);
    }
  }

  public setNotification(notification: CloudNotification, sort: boolean) {
    this.setActiveNotification(notification, sort);
    if(!notification.active && notification.valid_until && notification.valid_until < DateTime.now().toSeconds()) {
      this.removeNotificationFromExistingLists(notification.id);
    } else if(notification.origin !== 'changelog') {
      this.notifications.set(notification.id, notification);
    }
    if(!sort) return;
    this.sortNotifications();
  }

  private sortNotifications() {
    const sortedNotifications = new Map([...this.notifications.entries()].sort((a, b) => b[1].valid_from - a[1].valid_from));
    this.notifications = sortedNotifications;
  }

  public deleteNotificationFromSet(notification: CloudNotification) {
    this.notifications.delete(notification.id);
  }

  public saveClosedCloudNotificationId(notificationId: number): void {
    const closedCloudNotifications = JSON.parse(localStorage.getItem('closedCloudNotifications') || '[]');
    if (!closedCloudNotifications.includes(notificationId)) {
      closedCloudNotifications.push(notificationId);
      localStorage.setItem('closedCloudNotifications', JSON.stringify(closedCloudNotifications));
    }
  }

  public isCloudNotificationClosed(notificationId: number): boolean {
    const closedNotifications = JSON.parse(localStorage.getItem('closedCloudNotifications') || '[]');
    return closedNotifications.includes(notificationId);
  }

  private resetServiceParams() {
    this.notifications.clear();
    this.isFetchingNotifications = false;
    this.initialFetchComplete = false;
    this.hasMore = true;
    this.loadedCount = 0;

    this.isVisiblePreview = false;
    this.activeNotifications.clear();
    this.previewNotification = null;
  }

  public removeNotificationFromExistingLists(id: number): void {
    if(this.notifications.has(id)) {
      this.notifications.delete(id);
      this.sortNotifications();
    }
    if(this.activeNotifications.has(id)) {
      this.activeNotifications.delete(id);
      this.sortActiveNotifications();
    }
  }

  public getNewNotificationsCountSinceLastChangelogVisit(): number {
    const lastChangelogVisit = localStorage.getItem('last_changelog_visit');
    if(!lastChangelogVisit) return this.activeNotifications.size;
    
    const userId = this.us.currentUser?.id;
    if(!userId) return 0;

    const changelogVisitData = JSON.parse(lastChangelogVisit);
    const currentUserChangelogVisitData = changelogVisitData.find((visit: { userId: number, timestamp: number }) => visit.userId === this.us.currentUser.id);
    if(!currentUserChangelogVisitData) return this.activeNotifications.size;

    const lastChangelogVisitTimestamp = parseInt(currentUserChangelogVisitData.timestamp, 10);
    return Array.from(this.activeNotifications.values()).filter((notification) => notification.valid_from > lastChangelogVisitTimestamp).length;
  }

}
