import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { HomeThermostatItemComponent } from '../home-thermostat-item/home-thermostat-item.component';

@Component({
    selector: 'app-home-thermostats',
    templateUrl: './home-thermostats.component.html',
    styleUrls: ['./home-thermostats.component.scss'],
    imports: [CommonModule, HomeThermostatItemComponent]
})
export class HomeThermostatsComponent extends LanguageAware implements OnInit, OnDestroy {
  public visibleThermostats = [];
  private changeSubscriber = null;
  private currentSystem = 0;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.tag = 'home-thermostats';

    const that = this;
    this.loadVisibleThermostats();
    this.changeSubscriber = this.systems.onActiveSystemChange.subscribe(() => {
      that.handleSystemChange();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.changeSubscriber !== null) {
      this.changeSubscriber.unsubscribe();
      this.changeSubscriber = null;
    }
  }

  private loadVisibleThermostats() {
    this.visibleThermostats = [];
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined) {
      this.log('systemConfig neegzistuoja.');
    } else {
      this.log('systemConfig.visibleSensors', systemConfig.visibleThermostats);
    }
    if (systemConfig === undefined || systemConfig.visibleThermostats.length === 0) {
      this.log('Filtruojam.', this.systems.activeSystem.thermostats);
      this.visibleThermostats = this.systems.activeSystem.thermostats;
      return;
    }
    for (const iThermostat of this.systems.activeSystem.thermostats) {
      if (systemConfig.visibleThermostats.indexOf(iThermostat.id) !== -1) {
        this.visibleThermostats.push(iThermostat);
      }
    }
  }

  private handleSystemChange() {
    if (this.systems.activeSystem === null) {
      return;
    }
    this.loadVisibleThermostats();
    if (this.currentSystem !== this.systems.activeSystem.id) {
      this.currentSystem = this.systems.activeSystem.id;
    }
  }
}
