import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformService } from 'src/app/api/platform.service';
import { FooterService } from 'src/app/services/footer.service';
import { PhoneKeyboardService } from 'src/app/services/phone-keyboard.service';
import { TeleportModule } from 'src/app/teleport/teleport.module';
import { DesktopFooterComponent } from 'src/app/ui/desktop-footer/desktop-footer.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [CommonModule, DesktopFooterComponent, TeleportModule]
})
export class FooterComponent implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public desktopFooterButtons = [];
  public randomNumber1 = 0;
  public randomNumber2 = 0;
  public randomSums = [0, 0, 0];
  public sumOk = false;
  public sumButton1Bad = false;
  public sumButton2Bad = false;
  public sumButton3Bad = false;
  private keyboardSubscription: Subscription;
  private onFooterShowSubscriber: Subscription;

  constructor(public footerService: FooterService, kb: PhoneKeyboardService, private platform: PlatformService) {
    this.keyboardSubscription = kb.onKeyboardStateChange.subscribe((val) => {
      this.keyboardStateChanged(val);
    });
    this.onFooterShowSubscriber = this.footerService.onFooterShow.subscribe(() => {
      this.setDesktopFooterButtons();
    });
    this.setDesktopFooterButtons();
  }

  ngOnInit() {
    this.randomNumber1 = Math.floor(Math.random() * 10);
    this.randomNumber2 = Math.floor(Math.random() * 10);
    const correctSum = this.randomNumber1 + this.randomNumber2;
    this.randomSums[0] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === correctSum) {
      this.randomSums[0] = Math.floor(Math.random() * 20);
    }
    this.randomSums[1] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === this.randomSums[1] || this.randomSums[1] === correctSum) {
      this.randomSums[1] = Math.floor(Math.random() * 20);
    }
    this.randomSums[2] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === this.randomSums[2] || this.randomSums[1] === this.randomSums[2] || this.randomSums[2] === correctSum) {
      this.randomSums[2] = Math.floor(Math.random() * 20);
    }
    const correctIndex = Math.floor(Math.random() * 3);
    this.randomSums[correctIndex] = correctSum;
  }

  ngOnDestroy() {
    this.keyboardSubscription?.unsubscribe();
    this.onFooterShowSubscriber?.unsubscribe();
  }

  public setDesktopFooterButtons() {
    this.desktopFooterButtons = [];
    if(this.showButton2 && this.footerService.getButton2Text() !== '') {
      this.desktopFooterButtons.push({
        label: this.footerService.getButton2Text(),
        callback: () => this.doButton2Click()
      });
    }
    if(this.showButton1 && this.footerService.getButton1Text() !== '') {
      this.desktopFooterButtons.push({
        label: this.footerService.getButton1Text(),
        callback: () => this.doButton1Click()
      });
    }
  }

  public showButton1(): boolean {
    return this.footerService.getButton1Text() !== '';
  }

  public showButton2(): boolean {
    return this.footerService.getButton2Text() !== '';
  }

  public doButton1Click() {
    if (this.footerService.onButton1Click !== null) {
      this.footerService.onButton1Click();
    }
  }

  public doButton2Click() {
    if (this.footerService.onButton2Click !== null) {
      this.footerService.onButton2Click();
    }
  }

  public onSumClick(index: number) {
    const correctSum = this.randomNumber1 + this.randomNumber2;
    if (correctSum === this.randomSums[index]) {
      this.sumOk = true;
      if (this.footerService.onSumCorrect !== null) {
        this.footerService.onSumCorrect();
      }
    } else {
      if (index === 0) {
        this.sumButton1Bad = true;
      } else if (index === 1) {
        this.sumButton2Bad = true;
      } else if (index === 2) {
        this.sumButton3Bad = true;
      }
    }
  }

  private keyboardStateChanged(value: boolean) {}
}
