<ng-container *ngIf="platform.isDesktopView()">
  <app-form-input
    #dropdownInput
    [name]="dropdownUniqueId"
    [size]="size ? size : ''"
    class="dropdown-input"
    [ngClass]="{
      'dropdown-input': selectedDropdownItem && (!selectedDropdownItem.subLabels || selectedDropdownItem.subLabels.length === 0),
      'dropdown-input-with-sublabel': selectedDropdownItem && selectedDropdownItem.subLabels && selectedDropdownItem.subLabels.length > 0,
      'pointer' : !shown && !disabled
    }"
    [label]="label"
    [hasError]="hasError"
    type="text"
    [(value)]="textBox"
    (valueChange)="filterItemsList()"
    autocomplete="off"
    (keydown.arrowdown)="$event.preventDefault(); shown ? updateCurrentActive(1) : onFocus()"
    (keydown.arrowup)="$event.preventDefault(); shown ? updateCurrentActive(-1) : onFocus()"
    (keydown.enter)="$event.preventDefault(); selectItem()"
    (keydown.esc)="closeSelection()"
    (Focus)="onFocus()"
    (Blur)="onBlur()"
    (click)="showSelection()"
    [disabled]="disabled"
  >
    <div *ngIf="selectedDropdownItem && selectedDropdownItem.subLabels && selectedDropdownItem.subLabels.length > 0 && size !== 'small'" class="sublabel">
      <p *ngFor="let sublabel of selectedDropdownItem.subLabels" class="sublabel-text">{{sublabel}}</p>
    </div>
    <span id="triangle" [ngClass]="{'disabled': disabled, 'pointer': !disabled, '-mt-04': hasError}" (click)="toggleSelection(); $event.stopPropagation()"></span>
    <div
      *ngIf="shown"
      id="dropdown-list"
      [ngClass]="{'dropdown' : true, 'up' : openAbove || shouldOpenUp, 'down' : !openAbove && !shouldOpenUp}"
      (mouseenter)="onMouseenter()"
      (mouseleave)="onMouseleave()"
    >
      <div
        *ngFor="let item of shownItems"
        class="item"
        [ngClass]="{'active' : item.active, 'item_disabled': !item.enabled}"
        (scroll)="$event.stopPropagation()"
        (click)="selectItem(item)"
      >
        {{ collapseLabel(item.label, item.value) }}
        <div *ngIf="item.subLabels && item.subLabels.length > 0" class="sublabels">
          <p *ngFor="let sublabel of item.subLabels" class="sublabels-text">{{sublabel}}</p>
        </div>
      </div>
      <div *ngIf="!shownItems?.length" class="item"><i>{{items.length === 0 ? trans('general.emptyList') : trans('general.noResults') }}</i></div>
    </div>
    <ng-content></ng-content>
  </app-form-input>
</ng-container>

<ng-container *ngIf="!platform.isDesktopView()">
  <app-name-value [nameText]="label" (clicked)="changeSlidoutValue()" [isEnabled]="!disabled">{{ textBox }}</app-name-value>
</ng-container>