import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ErrorResponse, FilterCriteria, UserTag } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { Tag } from 'src/api/v3/tag';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { LocatorService } from '../services/locator.service';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class TagService implements OnDestroy {
  private readonly tags = new Map<number, Tag>();

  private tag = 'TagService';
  private previousFilter: string = null;
  private cleanUpSubscriber: Subscription;
  private filterCriteria: FilterCriteria = null;
  private tagInEditIdChange = new Subject<number>();
  public onTagInEditIdChange = this.tagInEditIdChange.asObservable();

  // Nurodo ar esam nuskaitę pilną tag sąrašą.
  public hasTagsForList = false;
  public tableTags: number[] = [];
  public get allTags() { return this.tags; }

  constructor(private l: LoggerService, auth: AuthService) {
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.tags.clear();
      this.tableTags = [];
      this.filterCriteria = null;
      this.previousFilter = null;
      this.hasTagsForList = false;
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public ingestTag(tag: Tag): boolean {
    if ( this.tags.has(tag.id) ) { return false; }
    this.l.log('Ingesting tag ' + tag.id, this.tag, { tag });
    tag.textColor = TagService.getTextColor(tag.color);
    this.tags.set(tag.id, tag);
    return true;
  }

  public addTableTag(tagId: number, tag?: Tag) {
    if ( tag ) {
      this.ingestTag(tag);
    }
    this.tableTags.push(tagId);
  }

  public removeTag(tagId: number) {
    if ( this.tags.has(tagId) ) {
      this.tags.delete(tagId);
    }
    this.tableTags.splice(this.tableTags.indexOf(tagId), 1);
  }

  public static getTextColor(backgroundHexColor: string): string {
    const textColors = {
      light: '#ffffff',
      dark: '#282828'
    };
    const r = parseInt(backgroundHexColor.slice(1, 3), 16) / 255;
    const g = parseInt(backgroundHexColor.slice(3, 5), 16) / 255;
    const b = parseInt(backgroundHexColor.slice(5, 7), 16) / 255;
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 0.5 ? textColors.dark : textColors.light;
  }

  public generateNewColor(): string {
    const usedColors = [...this.tags.values()].map(t => t.color);
    let finalColor;
    let safeRepeatCounter = 0;
    while ( (!finalColor || usedColors.includes(finalColor)) && safeRepeatCounter < 30 ) {
      safeRepeatCounter++;
      const randomRed = Math.round(Math.random() * 255);
      const randomGreen = Math.round(Math.random() * 255);
      const randomBlue = Math.round(Math.random() * 255);
      finalColor = '#' + randomRed.toString(16).padStart(2, '0').toUpperCase()
                       + randomGreen.toString(16).padStart(2, '0').toUpperCase()
                       + randomBlue.toString(16).padStart(2, '0').toUpperCase();
    }
    return finalColor ?? '#FFFFFF';
  }

  /**
   * Patikrina ar yra bent vienas bendras tag.
   *
   * @param strict Nurodo, kad būtinai turi būti bent vienas tag ir netinka tušti/undefined masyvai.
   * @returns Ar bent vienas tag sutampa.
   */
  public static hasMatchingTags(strict: boolean, tags1?: Tag[]|UserTag[], tags2?: Tag[]): boolean {
    if ( !tags1 && !tags2 ) { return !strict; }
    if ( !tags1 || !tags2 ) { return false; }
    if ( tags1.length === 0 && tags2.length === 0 ) { return !strict; }
    if ( tags1.length === 0 || tags2.length === 0 ) { return false; }
    return tags1.some(t => tags2.find(t2 => t2.id === ((t as UserTag).tag_id || t.id)));
  }

  public getTag(id: number): Tag | undefined {
    if ( id === 0 ) { return undefined; }
    return this.tags.get(id);
  }

  public getTagsGetter(filterCriteria: FilterCriteria | null): DataTableGetter<Tag> {
    const tagService = this;
    if ( filterCriteria ) {
      if (
        !this.filterCriteria
        || this.filterCriteria.searchPhrase !== filterCriteria.searchPhrase
        || JSON.stringify(filterCriteria.searchFields) !== JSON.stringify(this.filterCriteria.searchFields)
      ) {
        this.tableTags = [];
      }
      this.previousFilter = JSON.stringify(this.filterCriteria);
      this.filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    } else if ( this.filterCriteria !== null ) {
      this.filterCriteria = null;
      this.tableTags = [];
    }
    return async (current, columns, more) => {
      this.l.log('Load tags', '', { current, more });
      if ( !more ) { // data table nori atsinaujinti duomenis is atminties.
        if ( tagService.tableTags.length > 0 ) {
          return tagService.tableTags.map(tagId => tagService.tags.get(tagId));
        }
        if ( JSON.stringify(tagService.filterCriteria) === tagService.previousFilter ) {
          return tagService.tableTags.map(tagId => tagService.tags.get(tagId));
        }
      }
      tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
      const result = await requests.tag.getTags(filterCriteria ? {
        searchPhrase: filterCriteria.searchPhrase,
        searchFields: filterCriteria.searchFields,
        paginationPage: filterCriteria.currentPage + 1,
      } : null).toPromise();
      if ( !result.success ) {
        const toaster = LocatorService.injector.get(ToasterService);
        toaster.postError((result as ErrorResponse).error);
        return;
      }
      if ( !tagService.filterCriteria ) {
        tagService.filterCriteria = {
          searchPhrase: '',
          searchFields: [],
          currentPage: result.list.current_page,
          lastPage: result.list.last_page,
        };
        tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
        if ( result.list.total === tagService.tableTags.length ) {
          tagService.filterCriteria.currentPage = result.list.last_page;
        }
      } else {
        tagService.filterCriteria.currentPage = result.list.current_page;
        tagService.filterCriteria.lastPage = result.list.last_page;
        tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
      }
      result.list.data.forEach((t) => {
        tagService.ingestTag(t);
        if ( !tagService.tableTags.includes(t.id) ) {
          tagService.tableTags.push(t.id);
        }
      });
      return tagService.tableTags.map(tagId => tagService.tags.get(tagId));
    };
  }

  public async loadAllTags() {
    const result = await requests.tag.getTags({forList: true}).toPromise();
    if ( !result.success ) {
      const toaster = LocatorService.injector.get(ToasterService);
      toaster.postError((result as ErrorResponse).error);
      return;
    }
    result.list.data.forEach((t) => this.ingestTag(t));
    this.hasTagsForList = true;
  }

  public getTagsByIds(tagIds: number[]): Tag[] {
    return tagIds.map(tid => this.tags.get(tid));
  }

  public getTagsByUserTags(uersTags: UserTag[]): Tag[] {
    return uersTags.map(ut => this.tags.get(ut.tag_id));
  }

  public changeTagInEditId(value: number) {
    this.tagInEditIdChange.next(value);
  }
}
