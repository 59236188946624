import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { PlatformService } from 'src/app/api/platform.service';
import { LanguageService } from 'src/app/services/language.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
    selector: 'app-status-banner',
    imports: [
        CommonModule,
    ],
    templateUrl: './status-banner.component.html',
    styleUrl: './status-banner.component.scss'
})
export class StatusBannerComponent {
  public readonly desktopView = this.platform.isDesktopView();

  @Input() status: string;
  @Input() cancelAtPeriodEnd: boolean = false;
  @Input() statusDate: DateTime | number = 0;

  constructor(
    public subscriptionService: SubscriptionService,
    public lang: LanguageService,
    private platform: PlatformService,
  ) { }

}
