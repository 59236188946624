import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CurrentUserData } from 'src/api/v3/common';
import { LocatorService } from 'src/app/services/locator.service';
import { useDebouncedFunction } from 'src/shim';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { UserService } from '../user.service';
import {
  RtcMessageAreaStatus,
  RtcMessageConfigurationEvent,
  RtcMessageEvent,
  RtcMessagePgm,
  RtcMessageSystemStatusReload,
  RtcMessageUserAccessControl,
  RtcMessageUserSystem,
  RtcMessageVersionUpdate,
  RtcService,
  WsCloudNotificationMessage,
  WsPendingSystemMessage,
  WsSystemTroublesMessage,
} from './rtc.service';
import { WebSocketOptions } from 'src/app/models/web-socket-options';

@Injectable()
export class AndroidRtcService implements RtcService, OnDestroy {
  private systemStatusChangeSubject = new Subject<RtcMessageSystemStatusReload>();
  private versionUpdateSubject = new Subject<RtcMessageVersionUpdate>();
  private eventsSubject = new Subject<RtcMessageEvent>();
  private areaStatusSubject = new Subject<RtcMessageAreaStatus>();
  private pgmSubject = new Subject<RtcMessagePgm>();
  private userSystemSubject = new Subject<RtcMessageUserSystem>();
  private configurationEventSubject = new Subject<RtcMessageConfigurationEvent>();
  private userAccessControlSubject = new Subject<RtcMessageUserAccessControl>();
  private pendingSystemSubject = new Subject<WsPendingSystemMessage>();
  private systemTroublesSubjet = new Subject<WsSystemTroublesMessage>();
  private cloudNotificationSubjet = new Subject<WsCloudNotificationMessage>();

  public systemStatusChange: Observable<RtcMessageSystemStatusReload> = this.systemStatusChangeSubject.asObservable();
  public versionUpdate: Observable<RtcMessageVersionUpdate> = this.versionUpdateSubject.asObservable();
  public events: Observable<RtcMessageEvent> = this.eventsSubject.asObservable();
  public areaStatus: Observable<RtcMessageAreaStatus> = this.areaStatusSubject.asObservable();
  public pgm: Observable<RtcMessagePgm> = this.pgmSubject.asObservable();
  public userSystem: Observable<RtcMessageUserSystem> = this.userSystemSubject.asObservable();
  public configurationEvent: Observable<RtcMessageConfigurationEvent> = this.configurationEventSubject.asObservable();
  public userAccessControl: Observable<RtcMessageUserAccessControl> = this.userAccessControlSubject.asObservable();
  public pendingSystems: Observable<WsPendingSystemMessage> = this.pendingSystemSubject.asObservable();
  public systemTroubles: Observable<WsSystemTroublesMessage> = this.systemTroublesSubjet.asObservable();
  public cloudNotification: Observable<WsCloudNotificationMessage> = this.cloudNotificationSubjet.asObservable();
  private zone = LocatorService.injector.get(NgZone);

  private get user() { return LocatorService.injector.get(UserService); }
  private get auth() { return LocatorService.injector.get(AuthService); }
  private get l() { return LocatorService.injector.get(LoggerService); }

  private lastIntrests: number[] = [];

  constructor() {
    window.statusJsi = {
      onSystem: (data: string[]) => {
        this.zone.run(() => this.systemStatusChangeSubject.next(JSON.parse(data[0])));
      },
      onArea: (data: string[]) => {
        this.zone.run(() => this.areaStatusSubject.next(JSON.parse(data[0])));
      },
      onPgm: (data: string[]) => {
        this.zone.run(() => this.pgmSubject.next(JSON.parse(data[0])));
      },
      onUserSystemEvent: (data: string[]) => {
        this.zone.run(() => this.userSystemSubject.next(JSON.parse(data[0])));
      },
      onEvent: (data: string[]) => {
        this.zone.run(() => this.eventsSubject.next(JSON.parse(data[0])));
      },
      onUserAccess: (data: string[]) => {
        this.zone.run(() => this.userAccessControlSubject.next(JSON.parse(data[0])));
      },
      onConfiguration: (data: string[]) => {
        this.zone.run(() => this.configurationEventSubject.next(JSON.parse(data[0])));
      },
      onVersion: (data: string[]) => {
        this.zone.run(() => this.versionUpdateSubject.next(JSON.parse(data[0])));
      },
      onPendingSystem: (data: string[]) => {
        this.zone.run(() => this.pendingSystemSubject.next(JSON.parse(data[0])));
      },
      onSystemTroubles: (data: string[]) => {
        this.zone.run(() => this.systemTroublesSubjet.next(JSON.parse(data[0])));
      },
      onCloudNotification: (data: string[]) => {
        this.zone.run(() => this.cloudNotificationSubjet.next(JSON.parse(data[0])));
      },
    };
    const [updateIntrests] = useDebouncedFunction(() => this.updateIntrestsInternal(), 1000);
    this.updateIntrests = (systemIds: number[]) => {
      this.lastIntrests = systemIds;
      updateIntrests();
    };
  }

  ngOnDestroy(): void {
    this.close();
  }

  public close(): void {
    if (window.jsi !== undefined) {
      window.jsi.onSocketClose();
    }
  }

  public connect(): void {
    this.updateIntrests([]);
  }
  public updateIntrests: (systemIds: number[]) => void;

  private async updateIntrestsInternal(): Promise<void> {
    if (window.jsi !== undefined) {
      if (!this.auth.hasToken()) {
        this.l.log('Vartotojas neprisijungę, nabandome prisijungti.', 'AndroidRtcService');
        return;
      }
      let user: CurrentUserData = this.user.currentUser;
      if (!user?.socket_token) {
        this.l.log('Vartotojas neturi socket tokeno, bandome gauti tokeną ir tada bandysime vėl..', 'AndroidRtcService');
        user = await this.auth.loadUserData(false);
      }
      this.l.log('Prisijungiama prie socketo...', 'AndroidRtcService');
      this.close();
      let options: WebSocketOptions = null;
      try {
        const versionCode = window.jsi.getAppVersionCode();
        options = {
          version: 3,
          systems: this.lastIntrests,
          user: user.id,
          company: user.company_id,
          companyRelations: user.belongsToCompany?.company_relations.map(cr => cr.company_id) ?? [],
          channels: {
            'auto-reload-channel': 'onArea',
            'system-status-reload': 'onSystem',
            'pgm-channel': 'onPgm',
            'events-channel': 'onEvent',
            'user-access-control': 'onUserAccess',
            'version-update': 'onVersion',
            'pending-system': 'onPendingSystem',
            'system-troubles': 'onSystemTroubles',
            'cloud-notification': 'onCloudNotification',
          },
        };
      } catch (eVersion ) {
        options = {
          version: 2,
          systems: this.lastIntrests,
          user: user.id,
          company: user.company_id,
          companyRelations: user.belongsToCompany?.company_relations.map(cr => cr.company_id) ?? [],
          channels: {},
        };
      }
      // Šita sritis telefonams
      
      window.jsi.onSocketParamsGot(
        JSON.stringify({
          port: user.socket_port,
          token: user.socket_token,
          clientOptions: options,
        })
      );
    }
  }
}
