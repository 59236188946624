import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    imports: []
})
export class ToggleButtonComponent implements OnInit {
  @Input() buttonId = '';
  @Input() initialState = false;
  @Input() isDisabled = false;
  @Output() toggled: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public doToggle() {
    if ( this.isDisabled ) { return; }
    this.initialState = !this.initialState;
    this.toggled.emit({checked: this.initialState});
  }
}
