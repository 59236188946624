import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-context-menu-data-table',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    imports: []
})
export class ContextMenuComponent implements OnInit, OnChanges {
  public top: number;
  public left: number;

  @Input() headerText = '';
  @Input() mouseEvent = undefined;
  @Output() contextVisibilityChange = new EventEmitter<boolean>();

  @HostListener('document:contextmenu', ['$event'])
  onContextMenu(event: MouseEvent, offset?: { x: number; y: number }) {
    const target = event.target as HTMLElement;
    const table = document.querySelector('.dataTable') as HTMLElement;
    if ( !table ) { return; }
    const dateSpacer = document.querySelector('.dateSpacer') as HTMLElement;
    const clickedInsideTable = table.contains(target);
    const clickedInsideDateSpacer = dateSpacer && dateSpacer.contains(target) ? true : false;
    if (!clickedInsideTable || target.classList.contains('headerItem') || clickedInsideDateSpacer) {
      this.contextVisibilityChange.emit(false);
    } else {
      const contextMenu = document.querySelector('#contextMenu') as HTMLElement;
      const menuHeight = contextMenu.offsetHeight;
      const menuWidth = contextMenu.offsetWidth;
      const spaceBelow = window.innerHeight - event.clientY;
      const spaceAbove = event.clientY;
      const spaceRight = window.innerWidth - event.clientX;
      if (spaceBelow >= menuHeight) {
        this.top = event.clientY;
      } else if (spaceAbove >= menuHeight) {
        this.top = event.clientY - menuHeight;
      } else {
        this.top = window.innerHeight - menuHeight;
      }
      if (spaceRight >= menuWidth) {
        this.left = event.clientX;
      } else {
        this.left = event.clientX - menuWidth;
      }
      if ( offset !== undefined ) {
        this.top += offset.y;
        this.left += offset.x;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onMouseButtonClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target === this.mouseEvent?.target) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'contextMenu') {
      this.contextVisibilityChange.emit(false);
    }
  }

  @HostListener('document:keydown.escape')
  onEscapeClick() {
    this.contextVisibilityChange.emit(false);
  }

  @HostListener('document:mousewheel', ['$event'])
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'contextMenu') {
      this.contextVisibilityChange.emit(false);
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.mouseEvent && changes.mouseEvent.currentValue !== undefined && changes.mouseEvent.previousValue?.target !== changes.mouseEvent.currentValue.target ) {
      this.onContextMenu(this.mouseEvent, { x: 15, y: 5 });
    }
  }
}
