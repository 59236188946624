import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { IPopup } from '../IPopup';
import { CommonModule } from '@angular/common';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { ButtonComponent } from 'src/app/ui/button/button.component';

@Component({
    selector: 'app-history-systems-popup',
    templateUrl: './history-systems-popup.component.html',
    styleUrls: ['./history-systems-popup.component.scss'],
    imports: [CommonModule, BasePopupComponent, ButtonSingleLineArrowComponent, ButtonComponent]
})
export class HistorySystemsPopupComponent implements OnInit, AfterViewInit, IPopup {
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  public popupName = 'SelectInstaller';
  public selection = -1;
  public closed: EventEmitter<void> = new EventEmitter();

  constructor(
    public us: UserService,
    public ls: LanguageService,
  ) { }

  ngOnInit(): void { }
  ngAfterViewInit() { }

  public closeWindow() {
    this.basePopup.hidePopup();
  }
}
