import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { ErrorResponse } from 'src/api/v3/common';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import requests from 'src/api/v3/requests';
import { TBackgroundType } from 'src/app/models/background-types';
import { UResolvable } from 'src/app/guards/UResolvable';
import { LocatorService } from 'src/app/services/locator.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { InstallerBasicData } from 'src/api/v3/user';
import { CommonModule } from '@angular/common';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { TextEditComponent } from 'src/app/ui/text-edit/text-edit.component';
import { ButtonDoubleLineArrowComponent } from 'src/app/ui/button-double-line-arrow/button-double-line-arrow.component';

@Component({
    selector: 'app-change-installer',
    templateUrl: './change-installer.component.html',
    styleUrls: ['./change-installer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, ButtonSingleLineArrowComponent, TextEditComponent, ButtonDoubleLineArrowComponent]
})
export class ChangeInstallerComponent extends LanguageAware implements OnInit, UResolvable<typeof ChangeInstallerComponent> {
  private chosenInstaller = 0;
  public targetEmail = '';
  public filteredPeople: InstallerBasicData[] = [];
  private installers: InstallerBasicData[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private r: ActivatedRoute,
    private router: Router,
    private pp: PopupService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    const installer = this.r.snapshot.paramMap.get('installer');
    if (installer !== null && isNumeric(installer)) {
      this.chosenInstaller = parseInt(installer, 10);
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.changeInstaller'),
      backUrl: '*',
    });
    this.installers = (this.r.snapshot.data.u ?? [[],[]])[1];
    this.footerBar.showFooter(this.trans('systems.buttons.changeInstaller'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.doChange();
    };
  }

  public static async resolve() {
    try {
      const res = await requests.user.getInstallersForAssistance().toPromise();
      if ( res.success ) {
        return res.list;
      } else {
        LocatorService.injector.get(ToasterService).postError((res as ErrorResponse).error);
      }
    } catch (e) {}
    return [];
  }

  ngOnInit(): void {}

  private doChange() {
    const chosen = this.installers.find(i => i.id === this.chosenInstaller);
    this.router.navigate(['../../request-assistance'], { relativeTo: this.r, replaceUrl: true, state: { chosen } });
  }

  public doFilter(value: string) {
    if (value === '') {
      this.filteredPeople = [];
      return;
    }
    this.targetEmail = value;
    this.filteredPeople = this.installers.filter((ci) => ci.email.indexOf(value) !== -1 || ci.name.indexOf(value) !== -1);
  }

  public selectPerson(installer: InstallerBasicData) {
    this.targetEmail = installer.email;
    this.chosenInstaller = installer.id;
    this.filteredPeople = [];
  }

  public selectFromList() {
    this.pp.showSlideout<DropdownItem<number>>({
      headerText: this.trans('systems.labels.listOfInstallers'),
      items: this.installers.map((ci) => ({
        value: ci.id,
        label: ci.email,
      })),
      onSubmit: (res) => {
        this.targetEmail = res.label as string;
        this.chosenInstaller = res.value;
      },
    }, PopupType.SlideoutWithValue);
  }
}
