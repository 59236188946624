import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

export type TabItem = {
  /**
   * Savotiškas ID. Neturi kartotis.
   */
  name: string;
  /**
   * Tekstas, kuris rodomas TAB mygtuke
   */
  title: string;
  /**
   * ng-template turinys, kuris bus rodomas aktyvavus konkretų TAB
   */
  content: TemplateRef<unknown>;
  /**
   * Nurodo ar TAB mygtukas yra matomas
   */
  show: boolean;
  /**
   * Nurodo ar TAB mygtukas yra šiuo metu aktyvus, t.y. jis buvo paspaustas.
   */
  active: boolean;
};

@Component({
    selector: 'app-tab-control',
    templateUrl: './tab-control.component.html',
    styleUrls: ['./tab-control.component.scss'],
    imports: [CommonModule]
})
export class TabControlComponent implements OnInit, OnChanges {
  @Input() tabs: TabItem[] = [];
  @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();
  public fakeTabs: TabItem[] = [];
  public activeTemplate: TemplateRef<unknown>;
  private activeTabName = '';

  constructor() { }

  ngOnInit(): void {
    if ( this.tabs.length === 0 ) { return; }
    this.activeTemplate = this.tabs.find(t => t.active)?.content;
  }

  public onTabClick(tab: TabItem) {
    this.tabs.forEach((t) => t.active = tab.name === t.name);
    this.activeTemplate = tab.content;
    this.activeTabName = tab.name;
    this.tabChanged.emit(tab.name);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.tabs ) {
      this.handleTabListChange();
    }
  }

  private handleTabListChange() {
    const tab = this.tabs.find(t => t.active);
    if ( tab.name !== this.activeTabName ) {
      this.activeTemplate = tab.content;
      this.activeTabName = tab.name;
      this.tabChanged.emit(tab.name);
    }
    this.fakeTabs = [];
    const visibleCount = this.tabs.filter(t => t.show).length;
    if ( visibleCount % 4 === 0 || visibleCount < 4 ) {
      return;
    }
    const fakesNeeded = 4 - (visibleCount % 4);
    for ( let i = fakesNeeded; i > 0; i-- ) {
      this.fakeTabs.push({
        active: true,
        content: undefined,
        name: 'fake_' + i.toString(),
        show: true,
        title: '',
      });
    }
  }
}
