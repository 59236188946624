import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-company-logo',
  imports: [CommonModule, InlineSVGModule],
  templateUrl: './company-logo.component.html',
  styleUrl: './company-logo.component.scss'
})
export class CompanyLogoComponent {
  @Input() public path: string;
  @Input() public type: 'header' | 'home' | 'preview' | 'data_table' = 'header';

  public isSvgLogo(): boolean {
    return this.path.toLowerCase().endsWith('.svg');
  }
}
