import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TBackgroundType } from 'src/app/models/background-types';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LanguageAware } from '../language-aware';
import { CommonModule } from '@angular/common';
import { HomeCamerasComponent } from '../home/home-cameras/home-cameras.component';
import { PlusButtonComponent } from 'src/app/components/plus-button/plus-button.component';

@Component({
    selector: 'app-quick-cameras',
    templateUrl: './quick-cameras.component.html',
    styleUrls: ['./quick-cameras.component.scss'],
    imports: [CommonModule, HomeCamerasComponent, PlusButtonComponent]
})
export class QuickCamerasComponent extends LanguageAware implements OnInit {
  public readonly cameraCount = this.systems.activeSystem.cameras.length;
  public readonly cameraLimit = this.us.currentUser.limits.camera_count;
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.myCameras'),
      backUrl: '*',
      showSettings: this.systems.activeSystemHelper.can.edit.cameras(),
    });
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.router.navigate([...this.g.getHomeUrl(), 'settings', 'camera-list']);
    };
  }

  ngOnInit(): void {}

  public addCamera() {
    localStorage.setItem('ref', 'quick');
    this.es.beginComponentEdit(TEditableComponent.Camera, 0);
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'add-camera']);
  }
}
