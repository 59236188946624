<div *ngIf="desktopView && us.currentUser" class="header">
    <div class="grid grid-cols-3 items-center">
        <app-back-button [style.display]="'inline-block'"></app-back-button>
        <h4 class="flex-1 title">{{ trans('help.changelog.whatsNewQ') }}</h4>
        <div *ngIf="isSuperAdmin || canCreateNotifications" class="ml-auto" (click)="onAddNewClick()">
            <app-icon-button [label]="trans('general.addNew')" icon="assets/images/company/plus.svg"></app-icon-button>
        </div>
    </div>
</div>
<ng-container *ngIf="canViewNotifications; else changelogData">
    <div class="separator16"></div>
    <app-tab-control #tabControl [tabs]="[
        { name: 'changelog', title: trans('help.changelog.titlePlural'), content: changelogData, show: true, active: activeTabName === 'changelog' },
        { name: 'notification', title: trans('help.cloudNotifications.notifications'), content: notificationData, show: canViewNotifications, active: activeTabName === 'notification' },
    ]" (tabChanged)="onTabSelected($event)"></app-tab-control>
</ng-container>
<ng-template #changelogData>
    <div class="white_round pt-4 pb-1">
        <div *ngFor="let changelog of changelogs; let first = first; let last = last" class="py-025 grid grid-cols-24">
            <div class="col-span-24">
                <div class="grid grid-cols-24 px-0">
                    <div class="col-1 mx-auto">
                        <div [ngClass]="[first ? 'icon-receipt-latest' : 'icon-receipt']" inlineSVG="assets/images/receipt.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div *ngIf="!last" class="connector"></div>
            </div>
            <div class="content relative col-start-2 col-span-23" [ngClass]="{ 'content-editable': isSuperAdmin }">
                <div class="flex items-center justify-between px-1">
                    <div (click)="onChangelogEditClick(changelog)" class="headline-p" [ngClass]="{ 'pointer': isSuperAdmin }">
                        <div class="flex items-center gap-05">
                            <p class="title my-0" [ngClass]="{ 'bold text-color-primary': first }">{{ headlineText(changelog) }}</p>
                            <div class="icon-edit-wrap">
                                <div class="icon-edit" [inlineSVG]="'assets/images/edit-square.svg'"></div>
                            </div>
                        </div>
                        <p *ngIf="changelog.headline !== null" class="title-sub my-0" [ngClass]="{ 'bold': true }">{{ changelogDateLabel(changelog) }}</p>
                    </div>
                    <div *ngIf="changelog.video_url" (click)="onHowItWorksClick(changelog)" [ngClass]="{ 'pointer': true, 'pl-1 flex gap-05 items-center': desktopView }">
                        <div class="icon-youtube mx-auto" [inlineSVG]="'assets/images/youtube.svg'"></div>
                        <div class="bold nowrap color-gray-500" [ngClass]="{ 'mt-025': !desktopView }">{{ trans('help.cloudNotifications.howItWorksQ') }}</div>
                    </div>
                </div>
                <div class="separator32"></div>
                <div *ngFor="let item of changelog.content; let last = last; let index = index" [ngClass]="{  'py-025': true, 'flex items-center gap-1': desktopView }">
                    <app-status-banner [status]="getContentItemType(item.type)"></app-status-banner>
                    <div class="w-full pr-1 py-025" [ngClass]="{ 'border-b': !last, 'text-sm': !desktopView }"><p class="my-0 py-05">{{ textToShow(changelog, item.text, index) }}</p></div>
                </div>
                <p *ngIf="showTranslateButton && !isTranslationShowing(changelog)" (click)="onTranslateClick(changelog); $event.stopPropagation();" class="text-translate my-0 pointer pr-1">{{ trans('help.translateTo') }} {{ userLanguageDescription }}</p>
                <p *ngIf="showTranslateButton && isTranslationShowing(changelog)" (click)="onSeeOriginalTextClick(changelog); $event.stopPropagation();" class="text-translate my-0 pointer pr-1">{{ trans('help.viewOriginal') }}</p>
            </div>
            <div class="col-1">
                <div *ngIf="!last" class="connector"></div>
            </div>
        </div>
        <p *ngIf="showLoadMoreButton" (click)="onLoadMoreClick()" class="bold link text-center pointer">{{ trans('general.loadMore') }}</p>
        <ng-container *ngIf="isFetchingChangelogs">
            <div *ngIf="changelogs.length > 0" class="separator16"></div>
            <div class="icon-loader" [inlineSVG]="'assets/images/company/loaderBubles.svg'"></div>
            <div class="separator16"></div>
        </ng-container>
        <ng-container *ngIf="!isFetchingChangelogs && changelogs.length === 0">
            <p class="my-0 bold text-center">{{ trans('help.changelog.noItems') }}</p>
            <div class="separator16"></div>
        </ng-container>
    </div>
    
</ng-template>
<ng-template #notificationData>
    <div *ngIf="canViewNotifications" class="white_round pt-4 pb-1">
        <div *ngFor="let notification of notifications; let last = last" class="py-025 grid grid-cols-24">
            <div class="col-span-24">
                <div class="grid grid-cols-24 px-0">
                    <div class="col-1 mx-auto">
                        <div [ngClass]="[notification.active ? 'icon-receipt-latest' : 'icon-receipt']" inlineSVG="assets/images/receipt.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div *ngIf="!last" class="connector"></div>
            </div>
            <div class="content relative col-start-2 col-span-23" [ngClass]="{ 'content-editable': canEditNotifications }">
                <div [ngClass]="{ 'flex items-start justify-between': desktopView }">
                    <div [ngClass]="{ 'pr-1': desktopView, 'flex items-center justify-between gap-1 pb-1': !desktopView }">
                        <app-status-banner [ngClass]="{ 'status-banner-mobile': !desktopView }" [status]="notification.type"></app-status-banner>
                        <div *ngIf="!desktopView && notification.video_url" (click)="onHowItWorksClick(notification)" class="px-1 flex gap-1 items-center pointer">
                            <div class="icon-youtube" [inlineSVG]="'assets/images/youtube.svg'"></div>
                        </div>
                    </div>
                    <div (click)="onCloudNotificationEditClick(notification)" class="flex-1 headline-p" [ngClass]="{ 'pointer': canEditNotifications }">
                        <div class="flex items-center justify-start gap-05">
                            <p class="title my-0" [ngClass]="{ 'bold text-color-primary': notification.active }">{{ notificationHeadlineText(notification) }}</p>
                            <div class="icon-edit-wrap">
                                <div class="icon-edit" [inlineSVG]="'assets/images/edit-square.svg'"></div>
                            </div>
                        </div>
                        <p class="title-sub my-0" [ngClass]="{ 'bold': notification.active, 'text-color-gray-light': !notification.active }">{{ notificationStatusLabel(notification) }}</p>
                    </div>
                    <div *ngIf="desktopView && notification.video_url" (click)="onHowItWorksClick(notification)" class="px-1 flex gap-1 items-center pointer">
                        <div class="icon-youtube" [inlineSVG]="'assets/images/youtube.svg'"></div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <div *ngIf="!last" class="connector"></div>
            </div>
        </div>
        <p *ngIf="showLoadMoreButton" (click)="onLoadMoreClick()" class="bold link text-center pointer">{{ trans('general.loadMore') }}</p>
        <ng-container *ngIf="isFetchingNotifications">
            <div *ngIf="notifications.length > 0" class="separator16"></div>
            <div class="icon-loader" [inlineSVG]="'assets/images/company/loaderBubles.svg'"></div>
            <div class="separator16"></div>
        </ng-container>
        <ng-container *ngIf="!isFetchingNotifications && notifications.length === 0">
            <p class="my-0 bold text-center">{{ trans('help.cloudNotifications.noActiveItems') }}</p>
            <div class="separator16"></div>
        </ng-container>
    </div>
</ng-template>