import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BasePopupComponent } from 'src/app/popups/base-popup/base-popup.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TAreaData } from 'src/app/models/area-data';
import { AreaService } from 'src/app/services/area.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { IPopup } from 'src/app/popups/IPopup';
import { AlarmType, AreaState } from 'src/api/v3/system.area';
import { PgmData } from 'src/app/models/pgm';
import { CommonModule } from '@angular/common';
import { PgmButtonComponent } from 'src/app/components/pgm-button/pgm-button.component';
import { AreaKeypadComponent } from '../area-keypad/area-keypad.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { ReactionService } from 'src/app/api/system/reaction.service';

@Component({
    selector: 'app-area-alarm-popup',
    templateUrl: './area-alarm-popup.component.html',
    styleUrls: ['./area-alarm-popup.component.scss'],
    imports: [CommonModule, PgmButtonComponent, BasePopupComponent, AreaKeypadComponent, InlineSVGModule]
})
export class AreaAlarmPopupComponent extends LanguageAware implements OnInit, IPopup, AfterViewInit {
  public readonly AlarmType = AlarmType;
  private reenableRefresher = false;
  public showPinKeypad = false;
  private actionPerformed = '';
  private pinToUse = '';
  private rememberToUse = false;
  public popupName = '';
  public fireInGroupsText = '';
  public fireInLoopsText = '';
  public visibleOutputs: PgmData[] = [];
  private systemChangedListener = null;
  private activeSystemId = 0;
  @Input() activeArea: TAreaData = null;
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() disarmClicked: EventEmitter<any> = new EventEmitter();
  @Output() actionStarted: EventEmitter<any> = new EventEmitter();
  @Output() actionCompleted: EventEmitter<any> = new EventEmitter();

  constructor(
    cdRef: ChangeDetectorRef,
    public areaService: AreaService,
    private r: RefreshService,
    private router: Router,
    private reactionService: ReactionService,
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.activeSystemId = this.systems.activeSystem?.id;
    if( this.areaIsVirtualArea() ) {
      const that = this;
      this.systemChangedListener = this.systems.onActiveSystemChange.subscribe(() => {
        if(that.activeSystemId !== that.systems.activeSystem.id) {
          that.closeWindow();
        }
        that.getVisibleOutputs();
        that.rebuildActiveGroupsText();
        that.rebuildActiveLoopText();
      });
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.systemChangedListener !== null) {
      this.systemChangedListener.unsubscribe();
      this.systemChangedListener = null;
    }
  }

  ngAfterViewInit() {
    this.popupName = 'TakeAction ' + this.activeArea?.id;
    this.reenableRefresher = this.r.refresherIsEnabled;
    this.r.disableRefresher();
    this.basePopup.show();
  }

  public closeWindow() {
    if (this.reenableRefresher) {
      this.r.enableRefresher();
    }
    this.basePopup.hidePopup();
    this.closed.emit();
  }

  public showWindow() {
    this.basePopup.show();
  }

  public doDisarm() {
    this.disarmClicked.emit();
  }

  private doPerform() {
    const that = this;
    this.closeWindow();
    this.areaService.alarmedActionInProgress = true;
    this.actionStarted.emit();
    const url = this.actionPerformed === 'silence' ? '/silence-siren' : '/cancel-alarm';
    const systemId = this.systems.activeSystem.id;
    const remember = this.rememberToUse;
    const areaId = this.activeArea.id;
    this.api
      .post(
        url,
        {
          system_id: this.systems.activeSystem.id,
          area_id: this.activeArea.id,
          pin: this.pinToUse,
          remember_pin: this.rememberToUse,
        },
        true
      )
      .subscribe(
        (result) => {
          that.areaService.alarmedActionInProgress = false;
          that.actionCompleted.emit();
          if (!result.success) {
            that.toaster.postError(result.error);
          } else if (remember) {
            that.saveRemember(systemId, areaId);
          }
        },
        (error) => {
          that.actionCompleted.emit();
          that.areaService.alarmedActionInProgress = false;
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }

  public doSilence() {
    this.actionPerformed = 'silence';
    if (this.activeArea.showKeypad) {
      this.showPinKeypad = true;
    } else {
      this.doPerform();
    }
  }

  public doCancel() {
    this.actionPerformed = 'cancel';
    if (this.activeArea.showKeypad && this.activeArea.status !== AreaState.PanicButtons) {
      this.showPinKeypad = true;
    } else {
      this.doPerform();
    }
  }

  public keypadCancelled() {
    this.showPinKeypad = false;
    this.pinToUse = '';
    this.rememberToUse = false;
    this.actionPerformed = '';
  }

  public pinConfirmed(pin: string, remember: boolean) {
    this.showPinKeypad = false;
    this.pinToUse = pin;
    this.rememberToUse = remember;
    this.doPerform();
  }

  private saveRemember(systemId: number, areaId: number) {
    const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.showKeypad = false;
    this.systems.saveActiveSystem(systemId);
  }

  public gotoFireReset() {
    this.closeWindow();
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'fire-reset']);
  }

  public areaIsPanicButtonsArea(): boolean {
    return this.activeArea.status === AreaState.PanicButtons;
  }

  private getVisibleOutputs() {
    if( !this.us.currentUser || !this.systems.activeSystem ) { return; }
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined || systemConfig.visibleOutputs.length === 0) {
      this.visibleOutputs = this.systems.activeSystem.pgms.filter((o) => !o.control_area && o.enabled);
      return;
    }
    this.visibleOutputs = [];
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.enabled && !iPgm.control_area && systemConfig.visibleOutputs.indexOf(iPgm.queue_no) !== -1) {
        this.visibleOutputs.push(iPgm);
      }
    }
  }

  private rebuildActiveGroupsText() {
    if( !this.systems.activeSystem ) { return; }
    const alarmedAreas = this.systems.activeSystem.areas.filter(a => a.alarmed === true);
    alarmedAreas.sort((a, b) => a.alarmTime - b.alarmTime);
    if(alarmedAreas.length > 0) {
      this.fireInGroupsText = '';
      for(let i = 0; i < alarmedAreas.length; i++) {
        if(i === 0) {
          this.fireInGroupsText += alarmedAreas[i].name;
        } else {
          this.fireInGroupsText += `, ${alarmedAreas[i].name}`;
        }
      }
    }
  }

  private rebuildActiveLoopText() {
    if( !this.systems.activeSystem ) { return; }
    const events = this.systems.activeSystem.events;
    const activeLoops = [];
    const earliestAlarmTime = this.systems.activeSystem.areas.reduce((earliest, current) => {
      return current.statusTime > earliest.statusTime ? current : earliest;
    }).statusTime;

    for(let i = 0; i < events.length; i++) {
      if(events[i].time < earliestAlarmTime) { continue; }
      if(activeLoops.includes(events[i].areaInfo) || events[i].areaId > 0) { continue; }
      
      const reaction = this.reactionService.basicReactions.get(events[i].reaction);
      if(reaction && reaction.group === 'alarms') {
        activeLoops.push(events[i].areaInfo);
      }
    }
    if(activeLoops.length > 0) {
      this.fireInLoopsText = activeLoops.join(', ');
    }
  }

  public areaIsVirtualArea(): boolean {
    return this.systems.activeSystem && ['G17F', 'FC'].includes(this.systems.activeSystem.hwType);
  }
}
