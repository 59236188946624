import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { ButtonComponent } from "../../ui/button/button.component";
import { InlineSVGModule } from 'ng-inline-svg-w';
import { NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { CloudNotificationService } from 'src/app/services/cloud-notification.service';
import { CloudNotification } from 'src/api/v3/cloud-notifications';
import { DateService } from 'src/app/services/date.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PlatformService } from 'src/app/api/platform.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppSettings } from 'src/app-settings';

@Component({
    selector: 'app-message-banner',
    imports: [ButtonComponent, InlineSVGModule, CommonModule],
    templateUrl: './message-banner.component.html',
    styleUrl: './message-banner.component.scss',
    animations: [
        trigger('activeNotificationList', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-100%)' }))
            ])
        ]),
    ]
})
export class MessageBannerComponent extends LanguageAware {
  public readonly bannerButtonText =      this.trans('help.changelog.whatsNewQ');
  public readonly bannerVideoButtonText = this.trans('help.cloudNotifications.howItWorksQ');
  public showAllActiveNotifications:      boolean = false;
  private _isVisible:                     boolean = true;

  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      this._isVisible = this.isVisibleRoute;
      this.showAllActiveNotifications = false;
    }
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private cloudNotificationService: CloudNotificationService,
    private dateService: DateService,
    private msg: MessageboxService,
    private ps: PlatformService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray, noVisualModifications: true });
    this._isVisible = this.isVisibleRoute;
  }

  ngOnInit() {
    this.ls.populateAvailablLanguagesList();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
  
  @HostListener('document:click', ['$event']) onClick(event: MouseEvent): void {
    if(!this.isVisible || this.showAllActiveNotifications === false) return;
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.checkIfClickedInside(target);
    if (!clickedInside) {
      this.showAllActiveNotifications = false;
    }
  }

  private get userLanguage(): string {
    return this.us.currentUser.language ?? 'en';
  }

  public get showTranslateButton(): boolean {
    return AppSettings.translationApiKey && !this.isPreview && this.userLanguage !== 'en';
  }

  public get userLanguageDescription(): string {
    return this.trans('language.language');
  }

  private get isVisibleRoute(): boolean {
    const route = this.router.url;
    return !route.startsWith('/d/company/help/changelog') && !route.startsWith('/d/help/changelog');
  }

  public get isDesktopView(): boolean {
    return this.ps.isDesktopView();
  }

  public get isPreview(): boolean {
    return this.cloudNotificationService.isVisiblePreview;
  }

  public get isVisible(): boolean {
    return this.cloudNotification && (this._isVisible || this.cloudNotificationService.isVisiblePreview);
  }

  public get cloudNotification(): CloudNotification {
    return this.isPreview ? this.cloudNotificationService.previewNotification : this.cloudNotificationService.activeNotifications.values().next().value;
  }

  public get hasMoreActiveNotifications(): boolean {
    return this.isPreview ? false : this.cloudNotificationService.activeNotifications.size > 1;
  }

  public get activeNotificationsCount(): number {
    return this.isPreview ? 1 : this.cloudNotificationService.activeNotifications.size;
  }

  public get otherActiveNotifications(): CloudNotification[] {
    if(!this.hasMoreActiveNotifications) return [];
    return Array.from(this.cloudNotificationService.activeNotifications.values()).slice(1);
  }

  public bannerVideoExists(notification: CloudNotification): boolean {
    if (!notification) { return false; }
    return this.isValidUrl(notification.video_url);
  }

  public isBannerButtonVisible(notification?: CloudNotification): boolean {
    const notificationToUse = notification ? notification : this.cloudNotification;
    return notificationToUse.origin === 'changelog';
  }

  public getBannerTheme(notification: CloudNotification): string {
    switch (notification.type) {
      case 'info':          return 'theme-info';
      case 'warning':       return 'theme-warning';
      case 'news':         return 'theme-news';
      default:              return 'theme-info';
    }
  }

  public getBannerText(notification?: CloudNotification): string {
    const notificationToUse = notification ? notification : this.cloudNotification;
    let originalText = '';
    if(notificationToUse.origin === 'changelog') {
      originalText = notificationToUse.content !== '' ? 
        notificationToUse.content.replace(':date', this.formatDate(notificationToUse.valid_from)) :
        `${this.trans('help.changelog.newUpdate')} ${this.formatDate(notificationToUse.valid_from)}`;
    } else {
      originalText = notificationToUse.content !== '' ?
        notificationToUse.content :
        this.trans('help.cloudNotifications.notification');
    }
    return this.textToShow(notificationToUse, originalText);
  }

  private textToShow(notification: CloudNotification, originalText: string): string {
    if(!this.cloudNotificationService.showTranslationForIds.has(notification.id)) return originalText;

    const translation = this.cloudNotificationService.translations.get(notification.id);
    return translation && translation.language === this.userLanguage ? translation.text[0] : originalText;
  }

  public onButtonClick() {
    if(this.isPreview) { return; }
    
    this.navigateToRoute(['help', 'changelog']);
  }

  public onDismissClick(notification?: CloudNotification) {
    if(this.isPreview) {
      this.msg.open({
        buttons: this.msg.buttons.Ok,
        headerText: 'Toaster notification',
        iconType: this.msg.iconType.Warning,
        messageContent: 'This is only a toaster preview. If you want to hide it, toggle \'Show preview\' selection',
      });
    } else {
      if(notification) {
        this.cloudNotificationService.saveClosedCloudNotificationId(notification.id);
        this.cloudNotificationService.activeNotifications.delete(notification.id);
        this.cloudNotificationService.sortActiveNotifications();
      } else { // uždaromi visi pranešimai
        this.cloudNotificationService.activeNotifications.forEach(notification => {
          this.cloudNotificationService.saveClosedCloudNotificationId(notification.id);
          this.cloudNotificationService.activeNotifications.delete(notification.id);
        });
        localStorage.removeItem('last_changelog_visit');
      }
    }
  }

  public onVideoButtonClick(notification: CloudNotification) {
    if(!notification.video_url) return;

    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl(notification.video_url);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage(notification.video_url);
    } else {
      window.open(notification.video_url, '_blank');
    }
  }

  public navigateToRoute(route: string[]) {
    this.router.navigate([...this.g.resolveRootRoute(), ...route]);
  }

  protected isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
  
  private formatDate(ts: number) {
    const dateFromSeconds = new Date(ts * 1000);
    return this.dateService.formatDate(dateFromSeconds);
  }

  private checkIfClickedInside(element: HTMLElement): boolean {
    const filterSectionId = 'notificationsWrapper';
    if (element.id === filterSectionId) {
      return true;
    }
    if (element.parentElement) {
      return this.checkIfClickedInside(element.parentElement);
    }
    return false;
  }
  
  public async onTranslateClick(notification?: CloudNotification) {
    if(this.isPreview || this.isTranslationShowing(notification)) return;

    const notificationId = notification ? notification.id : this.cloudNotification.id;
    this.cloudNotificationService.showTranslationForIds.add(notificationId);
    const translationExists = this.cloudNotificationService.translations.get(notificationId)?.language === this.userLanguage;
    if(translationExists) return;

    const textToTranslate = this.getBannerText(notification);
    const translationText = await this.ls.translateTextsToUserLanguage([textToTranslate], this.userLanguage);
    const translation = { language: this.userLanguage, text: [translationText[0]] }
    this.cloudNotificationService.translations.set(notificationId, translation);
  }

  public onSeeOriginalTextClick(notification?: CloudNotification) {
    const notificationId = notification ? notification.id : this.cloudNotification.id;
    this.cloudNotificationService.showTranslationForIds.delete(notificationId);
  }

  public isTranslationShowing(notification?: CloudNotification): boolean {
    const notificationId = notification ? notification.id : this.cloudNotification.id;
    return this.cloudNotificationService.showTranslationForIds.has(notificationId);
  }

  public onNotificationsClick() {
    this.showAllActiveNotifications = !this.showAllActiveNotifications;
  }

}
