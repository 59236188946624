import { OwnerPrivacySetting } from "src/api/v3/system.user";

export class TDeviceUser {
  // id laukas system_persons (arba users_gv) lentelėje
  id: number;
  name: string;
  email: string;
  phone: string;
  // Eiles numeris modulyje "user_id", zonos numeris G moduliai, CG17, SP3
  zone_number: number;
  /** CG17, SP3, G. Eilutėje surinktos visos sritys.
   * '0101' reiškia, pirma nepriklauso, antrai sričiai priklauso, trečiai ne, ketvirtai taip
   */
  areas: string;
  // klaviaturos kodas CG17, SP3; "pin" GV17, Gator
  code: string | '•';
  protegus_user_id: number;
  enable_data: number; // GV17, Gator
  schedule_no: number; // GV17, Gator
  pgms: number | null; // GV17, Gator, Nuo 2024-06 jau visiems galioja, bet null reikšmė negalioja Vartų moduliams
  present: boolean; // GV17, Gator
  isOwner: boolean; // visiems
  ownerPermissions: OwnerPrivacySetting; // visiems
  attributes_in_panel: number;
  monas_id: number | undefined | 0;
  key: string | undefined | ''; // Monas person key
  queue_no: number | 0; // Eilės numeris Monas sistemoje
  monas_person_id: number | 0;

  public constructor(init?: Partial<TDeviceUser>) {
    Object.assign(this, init);
  }
}
