import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { companyRootRoute } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { LocatorService } from 'src/app/services/locator.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { CompanyHeaderSettingsComponent } from '../company-header-settings/company-header-settings.component';
import { ButtonComponent } from '../button/button.component';
import { RouterLink } from '@angular/router';
import { DotComponent } from 'src/app/general/dot/dot.component';
import { CompanyHeaderButtonComponent } from '../company-header-button/company-header-button.component';
import { SettingsListComponent } from '../settings-list/settings-list.component';
import { RegionSwitcherComponent } from '../region-switcher/region-switcher.component';
import { PendingSystemService } from 'src/app/api/system/pending-system.service';
import { IconsService } from 'src/app/services/icons.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CompanyLogoComponent } from "../../components/company-logo/company-logo.component";

@Component({
    selector: 'app-company-header',
    templateUrl: './company-header.component.html',
    styleUrls: ['./company-header.component.scss'],
    imports: [CommonModule, CompanyHeaderSettingsComponent, ButtonComponent, RouterLink, DotComponent, CompanyHeaderButtonComponent, SettingsListComponent, RegionSwitcherComponent, CompanyLogoComponent]
})
export class CompanyHeaderComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly AppSettings = AppSettings;
  public ProtegusLogo = '';
  public CompanyHeaderButton = '';
  public CompanyDashButton = '';
  public CompanySystemsButton = '';
  public CompanyEventsButton = '';
  public CompanyUsersButton = '';
  public CompanyTagsButton = '';
  public CompanySettingsButton = '';
  public UserIcon = '';
  public showSettings = false;
  public showProfileSettings = false;
  public rootRoute = companyRootRoute;
  public newVersionAvailable = false;
  public readonly roles = PermissionRole;
  public hasAnySettingsAvailable = false;
  private userDataChangeListener = null;
  private newVersionSubscriber: Subscription;
  public readonly urlButtonEnabled = this.us.currentUser.settings.togglable.find(t => t.field === 'url_button_enabled' && t.value) !== undefined;
  public readonly urlButtonTitle = this.us.currentUser.settings.textual.find(t => t.field === 'url_button_title' && t.value)?.value ?? '';
  public readonly urlButtonUrl = this.us.currentUser.settings.textual.find(t => t.field === 'url_button_url' && t.value)?.value ?? '';
  public readonly urlButtonIcon = this.ic.getUrlButtonIcon(this.us.currentUser.settings.textual.find(t => t.field === 'url_button_icon' && t.value)?.value ?? '');

  constructor(
    cdRef: ChangeDetectorRef,
    public login: LoginService,
    public pendingSystemService: PendingSystemService,
    public ic: IconsService,
    private dashboardService: DashboardService,
  ) {
    super(cdRef);
    this.reinitVariables();
    this.userDataChangeListener = this.us.onUserDataChanged.subscribe(() => { this.reinitVariables(); });
    this.newVersionSubscriber = this.g.onNewVersionAvailable.subscribe(() => {
      this.newVersionAvailable = AppSettings.showAppVersion && this.g.isNewVersionAvailable();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.userDataChangeListener?.unsubscribe();
    this.newVersionSubscriber?.unsubscribe();
  }

  public get isDashboardVisible(): boolean {
    return this.dashboardService.canViewDashboard(this.us.currentUser);
  }

  toggleSettings(): void {
    this.showSettings = !this.showSettings;
    this.showProfileSettings = false;
  }

  toggleProfileSettings(): void {
    this.showProfileSettings = !this.showProfileSettings;
  }

  onShowSettingsChange(value: boolean): void {
    this.showProfileSettings = value;
  }

  private reinitVariables() {
    const userPermissions = this.us.currentUser?.permissions?.permissions;
    if ( !userPermissions ) { return; }
    this.hasAnySettingsAvailable = [
      userPermissions.company_settings.view && this.us.currentUser?.company_id !== 0,
      userPermissions.ipcom_settings.view,
      userPermissions.reactions.view,
      userPermissions.global_settings.view,
      userPermissions.panic_settings.view,
      userPermissions.regions.view,
      userPermissions.transfer_device.execute
    ].includes(true);
  }

  public onAddNewSystemClick() {
    const ns = LocatorService.injector.get(NewSystemService);
    ns.setDefaults();
  }

  public urlButtonClicked() {
    if ( !this.urlButtonUrl && this.urlButtonUrl === '' ) {
      return;
    }
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl(this.urlButtonUrl);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage(this.urlButtonUrl);
    } else {
      window.open(this.urlButtonUrl, '_blank');
    }
  }
}
