import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { BackButtonComponent } from 'src/app/ui/back-button/back-button.component';
import { IconButtonComponent } from 'src/app/ui/icon-button/icon-button.component';
import { StatusBannerComponent } from "../../../components/subscription/status-banner/status-banner.component";
import { PermissionRole } from 'src/api/v3/permission';
import { TabControlComponent } from "../../../ui/tab-control/tab-control.component";
import { ChangelogService } from 'src/app/services/changelog.service';
import { CloudNotificationService } from 'src/app/services/cloud-notification.service';
import { Changelog } from 'src/api/v3/changelog';
import { CloudNotification } from 'src/api/v3/cloud-notifications';
import { DateTime } from 'luxon';
import { DateService } from 'src/app/services/date.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TeleportModule } from "../../../teleport/teleport.module";
import { WebSocketService } from 'src/app/services/websocket.service';
import { AppSettings } from 'src/app-settings';

@Component({
    selector: 'app-changelog',
    imports: [BackButtonComponent, CommonModule, IconButtonComponent, InlineSVGModule, StatusBannerComponent, TabControlComponent, TeleportModule],
    templateUrl: './changelog.component.html',
    styleUrl: './changelog.component.scss'
})
export class ChangelogComponent extends LanguageAware {
  public readonly desktopView = this.platform.isDesktopView();
  public activeTabName = 'changelog';

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private changelogService: ChangelogService,
    private cloudNotificationService: CloudNotificationService,
    private dateService: DateService,
    private editService: EditSystemService,
    private ws: WebSocketService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    if( !this.desktopView ) {
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('help.changelog.whatsNewQ'),
      });
    }
  }

  ngOnInit() {
    if(!this.changelogService.initialFetchComplete) {
      this.changelogService.fetchChangelogs()
    };
    this.ls.populateAvailablLanguagesList();
    this.setPageVisitTimestamp();
  }

  public getContentItemType(type: string) {
    switch(type) {
      case 'new':     return 'new_success';
      case 'update':  return 'update';
      case 'fix':     return 'fix';
      default:        return 'new_success';
    }
  }

  public get userLanguage(): string {
    return this.us.currentUser.language ?? 'en';
  }

  public get showTranslateButton(): boolean {
    return AppSettings.translationApiKey && this.userLanguage !== 'en';
  }

  public get userLanguageDescription(): string {
    return this.trans('language.language');
  }

  public get showLoadMoreButton() {
    return this.activeTabName === 'changelog' ?
      (!this.isFetchingChangelogs && this.changelogService.hasMore) :
      (!this.isFetchingNotifications && this.cloudNotificationService.hasMore);
  }

  public get changelogs(): Changelog[] {
    return Array.from(this.changelogService.changelogs.values());
  }

  public get notifications(): CloudNotification[] {
    return Array.from(this.cloudNotificationService.notifications.values());
  }

  public get isFetchingChangelogs(): boolean {
    return this.changelogService.isFetchingChangelogs;
  }

  public get isFetchingNotifications(): boolean {
    return this.cloudNotificationService.isFetchingNotifications;
  }

  public get isSuperAdmin() {
    return this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin;
  }

  public get canViewNotifications() {
    return true;
  }

  public get canCreateNotifications() {
    return this.isSuperAdmin;
  }

  public get canEditNotifications() {
    return this.isSuperAdmin;
  }

  public showDate(ts: number) {
    const dateFromSeconds = new Date(ts * 1000);
    return this.dateService.formatDate(dateFromSeconds);
  }

  public onAddNewClick() {
    if( !this.isSuperAdmin && !this.canCreateNotifications ) return;
  
    const routeAddon = ['changelog', 'new'];
    this.router.navigate([...this.g.resolveRootRoute(), 'help', ...routeAddon]);
  }

  public onChangelogEditClick(changelog: Changelog) {
    if(!this.isSuperAdmin) return;

    this.editService.beginComponentEdit(TEditableComponent.Changelog, changelog.id, changelog);
    const routeAddon = ['changelog', 'edit', changelog.id];
    this.router.navigate([...this.g.resolveRootRoute(), 'help', ...routeAddon]);
  }

  public onCloudNotificationEditClick(notification: CloudNotification) {
    if(!this.canEditNotifications) return;

    this.editService.beginComponentEdit(TEditableComponent.CloudNotification, notification.id, notification);
    const routeAddon = ['changelog', 'notification', 'edit', notification.id];
    this.router.navigate([...this.g.resolveRootRoute(), 'help', ...routeAddon]);
  }

  public onTabSelected(name: string) {
    this.activeTabName = name;
    
    if(name === 'notification' && !this.cloudNotificationService.initialFetchComplete) {
      this.cloudNotificationService.fetchNotifications();
    }
  }

  public onHowItWorksClick(item: Changelog | CloudNotification) {
    if(!item.video_url) return;

    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl(item.video_url);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage(item.video_url);
    } else {
      window.open(item.video_url, '_blank');
    }
  }

  public onLoadMoreClick() {
    if(this.activeTabName === 'changelog') {
      this.changelogService.fetchChangelogs();
    } else if(this.activeTabName === 'notification') {
      this.cloudNotificationService.fetchNotifications();
    }
  }

  public notificationStatusLabel(notification: CloudNotification): string {
    if(notification.active) {
      if(notification.valid_until === null) {
        return this.trans('help.cloudNotifications.status.active');
      } else {
        const date = DateTime.fromSeconds(notification.valid_until);
        return this.trans('help.cloudNotifications.status.expires') + ' ' + date.toRelative({ locale: this.userLanguage });
      }
    } else {
      if(notification.valid_until && notification.valid_until < DateTime.now().toSeconds()) {
        return this.trans('help.cloudNotifications.status.expired');
      } else if (notification.valid_from > DateTime.now().toSeconds()) {
        const date = DateTime.fromSeconds(notification.valid_from);
        return this.trans('help.cloudNotifications.status.scheduled') + ' ' + date.toRelative({ locale: this.userLanguage });
      } else {
        return this.trans('help.cloudNotifications.status.inactive');
      }
    }
  }

  public changelogDateLabel(changelog: Changelog): string {
    const date = DateTime.fromSeconds(changelog.date);
    return date.toRelative({ locale: this.userLanguage });
  }

  private originalHeadlineText(changelog: Changelog): string {
    if(changelog.headline !== '') {
      return changelog.headline.replace(':date', this.formatDate(changelog.date));
    } else {
      return `${this.trans('help.changelog.newUpdate')} ${this.formatDate(changelog.date)}`;
    }
  }

  public headlineText(changelog: Changelog): string {
    const originalText = this.originalHeadlineText(changelog);
    if(!this.changelogService.showTranslationForIds.has(changelog.id)) return originalText;

    const translation = this.changelogService.translations.get(changelog.id);
    return translation && translation.language === this.userLanguage ? translation.text[0] : originalText;
    
  }

  public textToShow(notification: CloudNotification, originalText: string, index: number): string {
    if(!this.changelogService.showTranslationForIds.has(notification.id)) return originalText;
    
    const translation = this.changelogService.translations.get(notification.id);
    if(translation && translation.language === this.userLanguage) {
      const indexToUse = index + 1;
      return indexToUse < translation.text.length ? translation.text[indexToUse] : originalText;
    }
    return originalText;
  }

  public notificationHeadlineText(notification: CloudNotification): string {
    if(notification.content !== '') {
      return notification.content.replace(':date', this.formatDate(notification.valid_from));
    } else {
      return `${this.trans('help.cloudNotifications.notification')} ${this.formatDate(notification.valid_from)}`;
    }
  }

  private formatDate(ts: number) {
    const dateFromSeconds = new Date(ts * 1000);
    return this.dateService.formatDate(dateFromSeconds);
  }

  public isTranslationShowing(changelog: Changelog): boolean {
    return this.changelogService.showTranslationForIds.has(changelog.id);
  }

    
  public async onTranslateClick(changelog: Changelog) {
    if(this.isTranslationShowing(changelog)) return;

    this.changelogService.showTranslationForIds.add(changelog.id);
    const translationExists = this.changelogService.translations.get(changelog.id)?.language === this.userLanguage;
    if(translationExists) return;

    const textsToTranslate = [];
    const headline = this.originalHeadlineText(changelog);
    textsToTranslate.push(headline);
    changelog.content.forEach(item => textsToTranslate.push(item.text));

    const translationText = await this.ls.translateTextsToUserLanguage(textsToTranslate, this.userLanguage);
    const translation = { language: this.userLanguage, text: translationText }
    this.changelogService.translations.set(changelog.id, translation);
  }

  public onSeeOriginalTextClick(changelog: Changelog) {
    this.changelogService.showTranslationForIds.delete(changelog.id);
  }

  protected setPageVisitTimestamp() {
    if(this.desktopView) return;

    const dataToLog = { userId: this.us.currentUser.id, timestamp: DateTime.now().toSeconds() };
    const lastChangelogVisit = localStorage.getItem('last_changelog_visit');
    if(!lastChangelogVisit) {
      localStorage.setItem('last_changelog_visit', JSON.stringify([dataToLog]));
      return;
    }
    const changelogVisitData = JSON.parse(lastChangelogVisit);
    const currentUserChangelogVisitData = changelogVisitData.find((visit: { userId: number, timestamp: number }) => visit.userId === this.us.currentUser.id);
    if(currentUserChangelogVisitData) {
      currentUserChangelogVisitData.timestamp = dataToLog.timestamp;
    } else {
      changelogVisitData.push(dataToLog);
    }
    localStorage.setItem('last_changelog_visit', JSON.stringify(changelogVisitData));
  }

}
