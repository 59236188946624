import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';
import { DashboardWidgetType } from 'src/app/models/dashboard-types';

export type DashboardWidgetItemsData = { id: number; timestamp?: number; system_status?: string; }

export type DashboardWidgetData = SystemsWidgetData | SystemStatusesWidgetData;

export type SystemsWidgetData = {
    total:     number;
    systems:   DashboardWidgetItemsData[];
    devices:   DashboardWidgetItemsData[];
};

export type SystemStatusesWidgetData = {
    total:      number;
    online:     number;
    offline:    number;
};

export type GetWidgetDataRequest =   { types: DashboardWidgetType[], company_id?: number };

export type GetWidgetDataResponse = ErrorResponse | (BasicSuccessResponse & { data: GetWidgetDataResponseData });

export type GetWidgetDataResponseData = { [key in DashboardWidgetType]: DashboardWidgetData };

const getWidgetData =   (req: GetWidgetDataRequest) =>   http.get<GetWidgetDataResponse, URLSearchParams>('/v3/api/dashboard/widget-data', objectToParams(req));

export default {
    getWidgetData,
};
