import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { FormErrorComponent } from 'src/app/ui/form-error/form-error.component';

@Component({
    selector: 'app-name-value',
    templateUrl: './name-value.component.html',
    styleUrls: ['./name-value.component.scss'],
    imports: [FormErrorComponent, InlineSVGModule, CommonModule]
})
export class NameValueComponent implements OnInit {
  @Input() nameText = '';
  @Input() showUnderline = true;
  @Input() isEnabled = true;
  @Input() icon = '';
  @Input() showEdit = false;
  @Input() showArrow = false;
  @Input() showError = false;
  @Input() errorText = '';
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public doClick() {
    if (this.isEnabled) {
      this.clicked.emit();
    }
  }

  public doEditClick($event) {
    if (this.isEnabled) {
      this.editClicked.emit();
    }
    $event.stopPropagation();
  }
}
