import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { TDeviceInfo } from '../models/device-info';
import { TDeviceStatus } from '../models/device-status';
import { PgmData } from '../models/pgm';
import { TAreaData } from '../models/area-data';
import { TZoneData } from '../models/zone-data';
import { LanguageService } from './language.service';
import { TSensorData } from '../models/sensor-data';
import { TDeviceUser } from '../models/device-user';
import { TimezoneService } from './timezone.service';
import { LoggerService } from '../api/logger.service';
import { THomeTheme } from '../models/home-theme';
import { DeviceSetupTemplate } from 'src/api/v3/device-setup-templates';
import { LocatorService } from './locator.service';
import { MiniStatusService } from './mini-status.service';
import { AlarmType, AreaState } from 'src/api/v3/system.area';

export type PanelManufacturer = {
  name: string;
  logo: string;
  ranges: PanelIdRange[];
  panels: PanelData[];
};

export type PanelData = {
  id: number;
  name: string;
  canBeControlledRemotely: boolean;
  canReceiveEvents: boolean;
  pcCodeNeeded: boolean;
};

export type DeviceQuickSettings = {
  /**
   * Į modulį neįsirašo, bet reikalingas operacijoms įvairioms.
   */
  hwId: number;
  /**
   * Visiems moduliams galioja
   */
  objectId: string;
  /**
   * G/E16 serijai. Negalioja T serijai.
   */
  remoteControl: boolean;
  /**
   * G/E16 serijai. Negalioja T serijai.
   */
  pcCode: string;
  /**
   * Naudojama modulias veikiantiems TRK8 bei dirbantiems SIA protokolu
   */
  moduleId: string;
  /**
   * GET/GTM/GTS.
   */
  receiveEvents: boolean;
  /**
   * Palaikom, ką gavom per read operaciją.
   */
  directControlCellValue: string;
  /**
   * GET/GTM/GTS
   */
  valueAuto: string;
  /**
   * GET/GTM/GTS
   */
  valueManual: string;
  /**
   * GET/GTM/GTS
   */
  telephonePanel: string;
  /**
   * trk8 G moduliams. Nurodo ar nuskaityti pirminiai duomenys (centralių sąrašas, esami telefoniniai pasirinkimai)
   */
  deviceDataRead: boolean;
  panelLists: {
    serialPanels: { value: string; canRemoteControl: boolean; canReceiveEvents: boolean; text: string; pcCodeNeeded: boolean }[];
    toneOptions: { value: string; text: string }[];
    manualToneOptions: { value: string; text: string }[];
    autoToneOptions: { value: string; text: string }[];
  };
  /**
   * G serija
   */
  extraFeaturesValue: string;
  /**
   * G serija
   */
  usePanelAccountId: boolean;
  /**
   * G serija
   */
  waitForAck: boolean;
  /**
   * _telephonePanel_ reikšmė iškarto po nuskaitymo, prieš tai, kaip vartotojas pasirinks.
   */
  originalPanelValue: string;
};

type PanelIdRange = {
  start: number;
  end: number;
};
export const panelData: PanelManufacturer[] = [
  {
    name: 'DSC',
    logo: 'assets/images/dsc-logo.png',
    ranges: [{start: 0x10, end: 0x1F}],
    panels: [
      { id: 0x11, name: 'PC1616', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x11, name: 'PC1832', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x11, name: 'PC1864', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x13, name: 'PC1404', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x12, name: 'PC585', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x12, name: 'PC1565', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x11, name: 'PC5020', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'PARADOX',
    logo: 'assets/images/paradox-logo.png',
    ranges: [{start: 0x20, end: 0x2F}, {start: 0xA1, end: 0xA1}],
    panels: [
      { id: 0x21, name: 'SP4000', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x21, name: 'SP5500', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x28, name: 'SP5500+', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x21, name: 'SP6000', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x28, name: 'SP6000+', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x21, name: 'SP7000', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x28, name: 'SP7000+', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x21, name: 'SP65', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x22, name: 'MG5000', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x22, name: 'MG5050', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x28, name: 'MG5050+', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x22, name: 'MG5050E', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x22, name: 'MG5075', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x23, name: 'Digiplex EVO48', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x23, name: 'Digiplex EVO192', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x23, name: 'Digiplex EVOHD', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x29, name: 'Digiplex EVOHD+', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x24, name: 'SPECTRA 1727', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x24, name: 'SPECTRA 1728', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x24, name: 'SPECTRA 1738', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x25, name: 'ESPRIT E55', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x26, name: 'Digiplex NE96', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x26, name: 'Digiplex EVO96', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x27, name: 'ESPRIT 728ULT', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x27, name: 'ESPRIT 738ULT', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'PYRONIX',
    logo: 'assets/images/pyronix-logo.png',
    ranges: [{start: 0x30, end: 0x3F}],
    panels: [
      { id: 0x31, name: 'Matrix 424', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x31, name: 'Matrix 832', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x31, name: 'Matrix 832+', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x32, name: 'Matrix 6', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x32, name: 'Matrix 816', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'INTERLOGIX',
    logo: 'assets/images/interlogix-logo.png',
    ranges: [{start: 0x40, end: 0x4F}],
    panels: [
      { id: 0x41, name: 'NX-4', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x41, name: 'NX-6', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x41, name: 'NX-8', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x41, name: 'NX-8E', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'TEXECOM',
    logo: 'assets/images/texecom-logo.png',
    ranges: [{start: 0x50, end: 0x5F}],
    panels: [
      { id: 0x51, name: 'Premier 24', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x51, name: 'Premier 48', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x51, name: 'Premier 88', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x51, name: 'Premier 168', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 12', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 24', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 48', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 64', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 88', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x52, name: 'Premier Elite 168', canBeControlledRemotely: true, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x53, name: 'Premier 412', canBeControlledRemotely: false, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x53, name: 'Premier 816', canBeControlledRemotely: false, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x53, name: 'Premier 832', canBeControlledRemotely: false, pcCodeNeeded: true, canReceiveEvents: false },
      { id: 0x53, name: 'Premier 832+', canBeControlledRemotely: false, pcCodeNeeded: true, canReceiveEvents: false },
    ],
  },
  {
    name: 'Inner range',
    logo: 'assets/images/inner-range-logo.png',
    ranges: [{start: 0x60, end: 0x6F}],
    panels: [
      { id: 0x61, name: '', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'HONEYWELL',
    logo: 'assets/images/honeywell-logo.png',
    ranges: [{start: 0x70, end: 0x7F}],
    panels: [
      { id: 0x71, name: 'VISTA 15', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x71, name: 'VISTA 20', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x71, name: 'VISTA 48', canBeControlledRemotely: true, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
  {
    name: 'CROW',
    logo: 'assets/images/crow-logo.png',
    ranges: [{start: 0x80, end: 0x8F}],
    panels: [
      { id: 0x81, name: 'RUNNER 4/8', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
      { id: 0x81, name: 'RUNNER 8/16', canBeControlledRemotely: false, pcCodeNeeded: false, canReceiveEvents: false },
    ],
  },
];

const defaultQuickSettings: DeviceQuickSettings = {
  hwId: 0,
  objectId: '',
  pcCode: '',
  remoteControl: true,
  moduleId: '',
  receiveEvents: true,
  directControlCellValue: '',
  valueAuto: '',
  telephonePanel: '',
  valueManual: '',
  deviceDataRead: false,
  panelLists: {
    autoToneOptions: [],
    manualToneOptions: [],
    serialPanels: [],
    toneOptions: [],
  },
  extraFeaturesValue: '',
  usePanelAccountId: false,
  waitForAck: false,
  originalPanelValue: '',
};

@Injectable({
  providedIn: 'root',
})
export class NewSystemService {
  private systemUid = '';
  private systemTimeZone = '';
  private systemName = '';
  private systemLocationAddress = '';
  private systemLocationCoords = '';
  private systemTheme: THomeTheme = {
    startColor: '#0070A7',
    endColor: '#79BAD9',
    fullBackground: '',
  };
  private mpass = '';
  private deviceInfo: TDeviceInfo = null;
  private deviceStatus: TDeviceStatus = null;
  private currentPgm = 0;
  private currentPgmObj: PgmData = null;
  private currentArea = 0;
  private currentAreaObj: TAreaData = null;
  private currentZone = 0;
  private currentZoneObj: TZoneData = null;
  private directControl = false;
  private ipcom = 0;
  private objectId = '';
  public cgUsers: TDeviceUser[] = [];
  public systemMaxUsers = 0;
  public systemCharset = 0;
  public userListRead = false;
  public foreignSystem?: any;
  public foreignRegionName?: any;
  public ownRegion?: any;
  public selectedSetupTemplate: DeviceSetupTemplate | undefined;
  public loadedSetupTemplates: DeviceSetupTemplate[] = [];
  public selectedManufacturer: PanelManufacturer = null;
  public selectedPanel: PanelData = null;
  public deviceSettings: DeviceQuickSettings;
  /**
   * Nurodo ar atlikta device setup operacija, tam kad jeigu grįžtama atgal į langą nebūtų vėl pradedama rašyti duomenis į modulį.
   */
  public deviceSetupIsComplete: boolean;
  /**
   * Padaroma kopija specialiai tam, kad vartotojas galėtų įeiti ir išeiti iš advanced setings kol vyksta device setup.
   */
  public copyOfConfigurableDevice: string;
  public allowInfoForExistingSystem = false;
  public readonly availableThemes = [
    { start: '#0070A7', end: '#79BAD9', full: '' },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #00C9FF 0%, #92FE9D 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #1CB5E0 0%, #000851 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #4b134f, #c94b4b)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #b20a2c, #fffbd5)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #24243e, #302b63, #0f0c29)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #96c93d, #00b09b)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #f7b733, #fc4a1a)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #ACB6E5, #74ebd5)',
    },
  ];

  constructor(private rs: ApiRequestService, private ls: LanguageService, private ts: TimezoneService, private l: LoggerService) {
    this.systemTimeZone = this.ts.userTimeZone;
    this.setDefaults();
  }

  public setDefaults() {
    this.l.log('Defaults.', 'NewSystemService');
    this.systemUid = '';
    this.systemTimeZone = this.ts.userTimeZone;
    this.systemName = '';
    this.systemLocationAddress = '';
    this.systemLocationCoords = '';
    this.systemTheme = {
      startColor: '#0070A7',
      endColor: '#79BAD9',
      fullBackground: '',
    };
    this.mpass = '123456';
    this.deviceInfo = null;
    this.deviceStatus = null;
    this.currentPgm = 0;
    this.currentArea = 0;
    this.currentZone = 0;
    this.currentPgmObj = null;
    this.currentAreaObj = null;
    this.currentZoneObj = null;
    this.cgUsers = [];
    this.userListRead = false;
    this.systemCharset = 0;
    this.systemMaxUsers = 0;
    this.ipcom = 0;
    this.foreignSystem = null;
    this.foreignRegionName = null;
    this.ownRegion = null;
    this.selectedSetupTemplate = undefined;
    this.loadedSetupTemplates = [];
    this.selectedManufacturer = null;
    this.selectedPanel = null;
    this.deviceSettings = JSON.parse(JSON.stringify(defaultQuickSettings));
    this.deviceSetupIsComplete = false;
    this.copyOfConfigurableDevice = '';
    this.allowInfoForExistingSystem = false;
    const ms = LocatorService.injector.get(MiniStatusService);
    ms.hide();
  }

  public get() {
    return {
      systemUid: this.systemUid,
      systemTimeZone: this.systemTimeZone,
      systemName: this.systemName,
      systemLocationAddress: this.systemLocationAddress,
      systemLocationCoords: this.systemLocationCoords,
      systemTheme: this.systemTheme,
      mpass: this.mpass,
      deviceInfo: this.deviceInfo,
      deviceStatus: this.deviceStatus,
      direct: this.directControl,
      cgUsers: this.cgUsers,
      maxUsers: this.systemMaxUsers,
      charset: this.systemCharset,
    };
  }

  public getUserTimeZone(): string {
    return this.ts.userTimeZone;
  }

  public getSystemTimeZone(): string {
    return this.systemTimeZone;
  }

  public setSystemTimeZone(value: string) {
    this.systemTimeZone = value;
  }

  public setUID(value: string) {
    this.systemUid = value;
  }

  public getUID(): string {
    return this.systemUid;
  }

  public setName(value: string) {
    this.systemName = value;
  }

  public getName(): string {
    return this.systemName;
  }

  public getAddress(): string {
    return this.systemLocationAddress;
  }

  public setAddress(value: string) {
    this.systemLocationAddress = value;
  }

  public getTheme(): THomeTheme {
    return this.systemTheme;
  }

  public setTheme(value: THomeTheme) {
    this.systemTheme = value;
  }

  public setMpass(value: string) {
    this.mpass = value;
  }

  public getMpass(): string {
    return this.mpass;
  }

  public setDeviceInfo(value: TDeviceInfo) {
    this.deviceInfo = value;
    if (this.deviceInfo.supported_commands.length !== 0) {
      this.deviceInfo.supported_commands = '.' + this.deviceInfo.supported_commands + '.';
    }
    this.systemUid = value.uid;
    if (this.systemUid.length < 12) {
      this.systemUid = this.systemUid.padStart(12, '0');
    }
    this.deviceSettings.hwId = parseInt(value.hwId, 16);
  }

  public getDeviceInfo(): TDeviceInfo {
    return this.deviceInfo;
  }

  public setDeviceStatus(value: TDeviceStatus) {
    this.l.log('', 'NewSystem', JSON.parse(JSON.stringify(value)));
    this.deviceStatus = value;

    const pgms = value.pgms as any;
    const pgmNumbers = Object.keys(pgms);
    this.deviceStatus.pgms = [];
    for (const i of pgmNumbers) {
      const pgm = pgms[i];
      if (!pgm.enabled) {
        continue;
      }
      const pgmData: PgmData = {
        queue_no: parseInt(i, 10),
        area_no: 0,
        control_area: false,
        enabled: pgm.enabled,
        on: pgm.on,
        name: 'PGM' + parseInt(i, 10),
        icon: -1,
        pulseTime: 3,
        type: ['24' /* GV17 Gator */, '37' /* WP17, CWI */].includes(this.deviceInfo.hwId) ? 'pulse' : 'level',
        id: 0,
        area_id: 0,
        pulse_activated_at: 0,
        io_ability: pgm.io_ability === undefined ? '' : pgm.io_ability,
        io_type: pgm.io_type === undefined ? 0 : pgm.io_type,
      };
      this.deviceStatus.pgms.push(pgmData);
    }
    const zones = value.zones;
    const zoneNumbers = Object.keys(zones);
    this.deviceStatus.zones = [];
    for (const i of zoneNumbers) {
      const zone = zones[i];
      const zoneData: TZoneData = {
        id: 0,
        queue_no: parseInt(i, 10),
        alarmed: zone.alarm,
        areas: [],
        bypassed: zone.bypass,
        enabled: zone.enabled,
        failed: zone.failure,
        name: this.ls.get('systems.titles.zone').replace(':no', i),
        native: true,
        visible: true,
      };
      this.deviceStatus.zones.push(zoneData);
    }

    const sensors = value.sensors;
    const sensorNumbers = Object.keys(sensors);
    this.deviceStatus.sensors = [];
    for (const i of sensorNumbers) {
      const sensor = sensors[i];
      const sensorData: TSensorData = {
        active: sensor.active,
        alarmHigh: sensor.alarm_high,
        alarmLow: sensor.alarm_low,
        enabled: sensor.enabled,
        queue_no: parseInt(i, 10),
        temperature: sensor.temperature,
        name: this.ls.get('systems.titles.sensor').replace(':no', i),
        type: 0,
        notificationAboutHigh: true,
        notificationAboutLow: true,
        highValue: 0,
        lowValue: 0,
      };
      this.deviceStatus.sensors.push(sensorData);
    }

    const areas = value.areas;
    const areaNumbers = Object.keys(areas);
    this.deviceStatus.areas = [];
    for (const iArea of areaNumbers) {
      const area = areas[iArea];
      const panicButtonsAreaStatus = '12';
      const areaData: TAreaData = {
        canBypass: false,
        id: 0,
        name: area === panicButtonsAreaStatus ? this.ls.get('systems.titles.panicButtons') : this.ls.get('events.titles.area').replace(':area', iArea),
        queue_no: parseInt(iArea, 10),
        showKeypad: false,
        status: this.convertAreaStatus(area),
        zones: [],
        statusTime: 0,
        alarmed: false,
        lastPerson: '',
        alarmTime: 0,
        alarmType: AlarmType.General,
        monas_id: null,
      };
      this.deviceStatus.areas.push(areaData);
    }
  }

  private convertAreaStatus(statusFromDevice: string): number {
    if (statusFromDevice === '00') {
      return AreaState.Armed;
    } // Armed
    if (statusFromDevice === '01') {
      return AreaState.ArmingInProgress;
    } // Arming in progress
    if (statusFromDevice === '04') {
      return AreaState.Disarmed;
    } // Disarmed
    if (statusFromDevice === '05') {
      return AreaState.DisarmingInProgress;
    } // Disarming in progress
    if (statusFromDevice === '0D') {
      return AreaState.Sleep;
    } // Sleep
    if (statusFromDevice === '09') {
      return AreaState.Stay;
    } // Stay
    if (statusFromDevice === '12') {
      return AreaState.PanicButtons;
    } // Panic buttons
    if (statusFromDevice === '13') {
      return AreaState.Group;
    } // Group
    if ( statusFromDevice === '14' ) {
      return AreaState.DisarmedReady;
    }
    if ( statusFromDevice === '15' ) {
      return AreaState.DisarmedNotReady;
    }
    return AreaState.Unknown;
  }

  public getDeviceStatus(): TDeviceStatus {
    return this.deviceStatus;
  }

  public setCurrentPgm(value: number, pgm?: PgmData) {
    this.currentPgm = value;
    this.currentPgmObj = pgm === undefined ? null : pgm;
  }

  public getCurrentPgm(): number {
    return this.currentPgm;
  }

  public getCurrentPgmObj(): PgmData {
    return this.currentPgmObj;
  }

  public getPgm(pgmNumber: number): PgmData {
    let result: PgmData = null;
    for (const i of this.deviceStatus.pgms) {
      if (i.queue_no === pgmNumber) {
        result = i;
        break;
      }
    }
    return result;
  }

  public getPgms(): PgmData[] {
    return this.deviceStatus.pgms;
  }

  public setCurrentArea(value: number, obj?: TAreaData) {
    this.currentArea = value;
    this.currentAreaObj = obj === undefined ? null : obj;
  }

  public getCurrentArea(): number {
    return this.currentArea;
  }
  public getCurrentAreaObj(): TAreaData {
    return this.currentAreaObj;
  }

  public getArea(areaNumber: number) {
    return this.deviceStatus.areas.find((a) => a.queue_no === areaNumber);
  }

  public addArea(value: TAreaData) {
    this.deviceStatus.areas.push(value);
  }

  public deleteArea(value: number) {
    this.deviceStatus.areas = this.deviceStatus.areas.filter((a) => a.queue_no !== value);
  }

  public getAreas(): TAreaData[] {
    return this.deviceStatus.areas.sort((a1, a2) => a1.queue_no - a2.queue_no);
  }

  public setCurrentZone(zoneNumber: number, obj?: TZoneData) {
    this.currentZone = zoneNumber;
    this.currentZoneObj = obj === undefined ? null : obj;
  }

  public getCurrentZone(): number {
    return this.currentZone;
  }

  public getCurrentZoneObj(): TZoneData {
    return this.currentZoneObj;
  }

  public getZone(zoneNumber: number) {
    return this.deviceStatus.zones.find((z) => z.queue_no === zoneNumber);
  }

  public getZones(): TZoneData[] {
    return this.deviceStatus.zones.sort((z1, z2) => z1.queue_no - z2.queue_no);
  }

  public addZone(zone: TZoneData) {
    this.deviceStatus.zones.push(zone);
  }

  public deleteZone(value: number) {
    const zone = this.getZone(value);
    if (zone !== undefined) {
      if (zone.native) {
        zone.visible = false;
      } else {
        this.deviceStatus.zones = this.deviceStatus.zones.filter((z) => z.queue_no !== value);
      }
    }
  }

  public setDirectControl(value: boolean) {
    this.directControl = value;
  }

  public isDirect(): boolean {
    return this.directControl;
  }

  public setCoordinates(lat: number, lon: number) {
    this.systemLocationCoords = lat.toString() + ' ' + lon.toString();
  }

  public hasCoordinates(): boolean {
    return this.systemLocationCoords !== '';
  }

  public getCoordinates(): string {
    return this.systemLocationCoords;
  }

  public setIpcom(value: number) {
    this.ipcom = value;
  }

  public getIpcom(): number {
    return this.ipcom;
  }

  public isSystemBeingCreated(): boolean {
    return this.systemUid !== '';
  }
}
