import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { SmallSystemDataWithComputation } from 'src/api/v3/system';
import { AppSettings } from 'src/app-settings';
import { SystemService } from 'src/app/api/system/system.service';
import { ContextMenuComponent } from 'src/app/company/components/context-menu/context-menu.component';
import { TSystemData } from 'src/app/models/system-data';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { AreaService } from 'src/app/services/area.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
    selector: 'app-cp-system-list-item',
    templateUrl: './cp-system-list-item.component.html',
    styleUrls: ['./cp-system-list-item.component.scss'],
    imports: [CommonModule, InlineSVGModule, ContextMenuComponent]
})
export class CpSystemListItemComponent implements OnInit, OnChanges {
  @Input() showArrow = true;
  @Input() showBorder = true;
  @Input() isClickable = true;
  @Input() showDelete = false;
  @Input() showAddress = false;
  @Input() showBookmark = false;
  @Input() showInstaller = false;
  @Input() systemItem: TSystemData = null;
  @Input() systemWithDevice: SmallSystemDataWithComputation = null;

  public isBookmarked = false;
  public installerNameText = '';
  public canDelete = AppSettings.canDeleteSystemFromList;
  public areaStatusMenu = {
    isVisible: false,
    event: undefined,
  };

  constructor(
    private router: Router,
    private g: GlobalService,
    private ss: SystemsService,
    public lang: LanguageService,
    private ws: WebSocketService,
    private rs: ApiRequestService,
    private es: EditSystemService,
    private toaster: ToasterService,
    private systemService: SystemService,
    public areaService: AreaService,
  ) { }

  ngOnInit(): void {
    this.checkIfBookmarked();
    this.canDelete = this.canDelete && this.isClickable && this.showDelete;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.systemWithDevice || changes.systemItem ) {
      this.rebuildInstallerName();
    }
  }

  public async gotoSystem() {
    if (!this.isClickable || (!this.systemItem && !this.systemWithDevice) ) {
      return;
    }
    if ( this.systemItem ) {
      this.ss.setCurrentSystem(this.systemItem);
      this.ws.websockInit();
      this.router.navigate(this.g.getHomeUrl());
    } else if ( this.systemWithDevice.system_id !== null ) {
      const sys = await this.systemService.getSystem(this.systemWithDevice.system_id);
      this.ss.setCurrentSystem(sys);
      this.ws.websockInit();
      this.router.navigate(this.g.getHomeUrl());
    } else {
      this.ss.setCurrentSystem(null);
      this.router.navigate([...this.g.resolveRootRoute(), this.systemWithDevice.registration_id.toString(), 'device']);
    }
  }

  private checkIfBookmarked() {
    this.isBookmarked = this.ss.systemBookmarks.includes(this.systemItem?.id ?? this.systemWithDevice?.system_id);
  }

  public toggleBookmark() {
    const that = this;
    let added = true;
    let url = '/bookmark/add';
    if (this.ss.systemBookmarks.includes(this.systemItem?.id ?? this.systemWithDevice?.system_id)) {
      this.ss.systemBookmarks.splice(this.ss.systemBookmarks.indexOf(this.systemItem?.id ?? this.systemWithDevice?.system_id), 1);
      url = '/bookmark/remove';
      added = false;
    } else {
      this.ss.systemBookmarks.push(this.systemItem?.id ?? this.systemWithDevice?.system_id);
    }
    this.checkIfBookmarked();
    const systemIdToUse = this.systemItem?.id ?? this.systemWithDevice?.system_id;
    this.rs.post(url, { id: systemIdToUse }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revertBookmark(added, systemIdToUse);
        }
      },
      (error) => {
        that.revertBookmark(added, systemIdToUse);
      }
    );
  }

  private revertBookmark(wasAdded: boolean, systemId: number) {
    if (wasAdded) {
      this.ss.systemBookmarks.splice(this.ss.systemBookmarks.indexOf(systemId), 1);
    } else {
      this.ss.systemBookmarks.push(systemId);
    }
    this.checkIfBookmarked();
  }

  public rebuildInstallerName() {
    if ( !this.systemItem && !this.systemWithDevice ) {
      this.installerNameText = '';
      return;
    }
    if ( this.systemItem?.assistedById ?? this.systemWithDevice?.assistedById ) {
      this.installerNameText = (this.systemItem?.assistedByEmail ?? this.systemWithDevice.assistedByEmail) + ' [' + this.lang.get('users.labels.isAssisting') + ']';
    } else if ( this.systemItem?.installerId ?? this.systemWithDevice?.installer_id ) {
      this.installerNameText = (this.systemItem?.installerName ?? this.systemWithDevice.installerName) + ' (' + (this.systemItem?.installerEmail ?? this.systemWithDevice.installerEmail) + ')';
    } else {
      this.installerNameText = this.lang.get('systems.labels.none');
    }
  }

  public deleteSystem() {
    this.es.deleteSystem(this.systemItem?.id ?? this.systemWithDevice?.system_id);
  }

  public iconClicked(event) {
    this.areaStatusMenu.isVisible = true;
    this.areaStatusMenu.event = event;
  }
}
