import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    imports: [CommonModule]
})
export class ButtonComponent implements OnInit {
  @Input() rounded?: 'full' | 'partial' | 'none';
  @Input() type:
    | 'filled-primary'
    | 'filled-secondary'
    | 'filled-gray'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-gray'
    | 'bare-primary'
    | 'bare-secondary'
    | 'bare-gray' = 'filled-primary';
  @Input() active?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() isLoading?: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
