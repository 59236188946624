import { ErrorHandler, Injectable } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { sendError } from './services/error-reporter';
import { HttpRequestError, HttpRequestErrorType } from './api/request.service';
import { LocatorService } from './services/locator.service';
import { MessageboxService } from './services/messagebox.service';
import { LanguageService } from './services/language.service';
import { captureException } from '@sentry/angular';

@Injectable()
export class GlobalErrorCatcher implements ErrorHandler {
  constructor() {}

  handleError(error: { message?: string; stack?: string; } | HttpRequestError) {
    console.error('[ERROR]', error);
    if (!error) {
      console.warn('[ERROR] Buvo klaida, bet neaisku kokia, nes objektas null.');
      return;
    }
    if (error.message?.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }
    if ( error instanceof HttpRequestError && error.type === HttpRequestErrorType.NetworkUnstable ) {
      return; // Vartotojui jau parodytas pranešimas, o serveriui apie tai nereikia žinoti.
    }
    if (error.message) {
      if (/Loading chunk [\d]+ failed/.test(error.message)) {
        this.showInfoAndReload();
        return;
      }
      const lastMessage = localStorage.getItem('last_error_reported');
      if (lastMessage !== null && lastMessage === error.message) {
        return;
      }
      localStorage.setItem('last_error_reported', error.message);
    }
    if ( error instanceof HttpRequestError && error.message === '' && (!error.cause || !error.cause.message || error.cause.message === '')) {
      return;
    }
    if (AppSettings.localReport) { sendError(error); }

    for ( const ignore of ['JSON Parse error', 'Unexpected end of script', 'Bad control character in string'] ) { // Mažinam srautą einantį į sentry
      if ( error.message?.includes(ignore)) { return; }
    }
    captureException(error);
  }

  private showInfoAndReload() {
    const msg = LocatorService.injector.get(MessageboxService);
    const lang = LocatorService.injector.get(LanguageService);
    if ( msg.isOpen() ) { return; }
    msg.open({
      buttons: msg.buttons.CustomButton1,
      alignCenter: true,
      button1Text: lang.get('settings.buttons.reload'),
      canCloseByOverlay: false,
      headerText: lang.get('general.titleError'),
      iconType: msg.iconType.Error,
      messageContent: lang.get('settings.errors.reloadAppInfo'),
      showCloseButton: false
    }, (dlg) => {
      const doReload = dlg.button1Clicked.subscribe(() => {
        doReload.unsubscribe();
        window.location.reload();
      });
    });
  }
}
