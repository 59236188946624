<div class="status" [ngClass]="{
    'status-default': status === 'incomplete' ||
    status === 'incomplete_expired' ||
    status === 'canceled' ||
    status === 'unpaid' ||
    status === 'paused' ||
    status === 'accepted' ||
    status === 'uncollectible' ||
    status === 'void' ||
    status === 'draft' ||
    status === 'new' ||
    status === 'open' ||
    status === 'active' && cancelAtPeriodEnd,
    'status-trialing':  status === 'trialing' || status === 'update' || status === 'news',
    'status-active':    status === 'paid' || (status === 'active' && !cancelAtPeriodEnd) || status === 'new_success',
    'status-past_due':  status === 'past_due' || status === 'fix' || status === 'warning',
    'status-info':  status === 'info',
    'status-mobile': !desktopView,
}">
    {{ status === 'new' ?
        lang.get('payments.labels.expires') :
        status === 'active' && cancelAtPeriodEnd ?
            lang.get('payments.labels.cancelsAtDate').replace(':date', subscriptionService.showSubscriptionDate(statusDate, 0)) :
            lang.get('payments.status.' + status)
    }} {{ status === 'trialing' || status === 'new' ? subscriptionService.showSubscriptionDate(statusDate, 0) : '' }}
</div>