<div *ngIf="desktopView && us.currentUser" class="header">
    <div class="grid grid-cols-3 items-center">
        <app-back-button [style.display]="'inline-block'"></app-back-button>
        <h4 class="flex-1 title">{{ isChangelog ? trans('help.changelog.title') : trans('help.cloudNotifications.cloudNotification') }}</h4>
        <div *ngIf="canDeleteItem" (click)="onDeleteClick()" class='icon-delete' [inlineSVG]="'assets/images/remove.svg'"></div>
    </div>
</div>
<div class="white_round padding16 pb-4" [ngClass]="{ 'px-2': desktopView }">
    <div class="flex items-center w-full py-025 gap-1 border-b">
       <div class="icon-receipt" inlineSVG="assets/images/receipt.svg"></div>
       <ng-container *ngIf="!isNew">
           <p *ngIf="isChangelog" class="text-lg text-gray bold my-0">{{ trans('help.changelog.modify') }}</p>
           <p *ngIf="!isChangelog" class="text-lg text-gray bold my-0">{{ trans('help.cloudNotifications.modifyNotification') }}</p>
       </ng-container>
       <app-dropdown *ngIf="isNew" class="type-dropdown w-full" [ngClass]="{ 'type-dropdown-mobile': !desktopView }" [items]="typesList" [(value)]="type" [disabled]="typesList.length === 1" (valueChange)="onTypeChange()"></app-dropdown>
    </div>
    <p class="px-1 label label-margin">{{ trans('general.date') }}</p>
    <div class="flex gap-1 w-fit px-1 border-changelog" [ngClass]="[isChangelog ? 'pt-025' : 'py-025']">
        <app-datetime-input type="date" [ngClass]="{'datetime-label-hidden': isChangelog}" [label]="trans('general.validFrom')" [(value)]="startDate" (valueChange)="startDateChanged($event)"></app-datetime-input>
        <app-datetime-input *ngIf="!isChangelog" type="date" [label]="trans('general.validUntil')" [(value)]="expirationDate" (valueChange)="expirationDateChanged($event)"></app-datetime-input>
    </div>
    <ng-container *ngIf="!isChangelog">
        <div class="flex items-center px-1">
            <p *ngIf="!shouldStartNow" class="text-sm">{{ trans('general.starts') }}: {{ startDate.toRelative({ locale: userLanguage }) }}</p>
            <p *ngIf="shouldStartNow" class="text-sm">{{ trans('general.startsImmediately') }}</p>
        </div>
    </ng-container>
    <div class="hr"></div>
    <p class="px-1 label label-margin">{{ trans('general.text') }}</p>
    <ng-container *ngIf="isChangelog; else NotificationMessage">
        <div class="list">
            <p *ngIf="changelogTextItems.length === 0" class="text-center my-0">{{ trans('general.noItems') }}</p>
            <div *ngFor="let item of changelogTextItems; let index = index; let last = last" class="flex gap-1" [ngClass]="{'pb-2': !last}">
                <app-dropdown (valueChange)="item.type = $event" [items]="changelogItemTypes" [(value)]="item.type" (valueChange)="onValueChanged()" [ngClass]="{ 'type-dropdown-mobile': !desktopView }" size="small"></app-dropdown>
                <app-string-input class="w-full string" [type]="'string'" [(value)]="item.text" (valueChange)="onValueChanged()"></app-string-input>
                <div (click)="onDelecteChangelogTextItemClick(index)" class="icon-close" inlineSVG="assets/images/close.svg"></div>
            </div>
        </div>
    </ng-container>
    <ng-template #NotificationMessage>
        <div class="list">
            <div class="flex gap-1">
                <app-dropdown (valueChange)="onNotificationItemTypeChange($event)" [ngClass]="{ 'type-dropdown-mobile': !desktopView }" [items]="notificationItemTypes" [(value)]="notificationType" size="small"></app-dropdown>
                <app-string-input class="w-full string" [type]="'string'" [(value)]="notificationText" (valueChange)="onValueChanged()"></app-string-input>
            </div>
        </div>
    </ng-template>
    <p *ngIf="isChangelog" (click)="onAddTextItem()" class="text-right link bold pointer decoration-none">{{ trans('general.addMore') }}</p>
    <div class="hr"></div>
    
    <ng-container *ngIf="isChangelog">
        <p class="px-1 label label-margin">{{ trans('general.headline') }}</p>
        <div [ngClass]="{ 'w-75p' : desktopView }">
            <app-name-value [nameText]="trans('general.customHeadline')" [showUnderline]="false">
                <app-toggle-button [buttonId]="'headline'" [initialState]="customHeadlineEnabled" (toggled)="onCustomHeadlineEnabledValueChange($event.checked)"></app-toggle-button>
            </app-name-value>
        </div>
        <ng-container *ngIf="customHeadlineEnabled">
            <div class="flex py-025 gap-1">
                <p class="my-0 bold text-sm">{{ trans('general.text') }}:</p>
                <app-string-input class="w-75p pt-05" [type]="'text'" [(value)]="changelogHeadline" (valueChange)="onValueChanged()"></app-string-input>
            </div>
        </ng-container>
        <div class="hr"></div>
    </ng-container>

    <p class="px-1 label label-margin">{{ trans('help.cloudNotifications.youtubeVideo') }}</p>
     <div class="flex py-025 gap-1">
        <p class="my-0 bold text-sm">{{ trans('general.link') }}:</p>
        <app-string-input class="w-75p pt-05" [type]="'text'" [(value)]="videoUrl" (valueChange)="onValueChanged()"></app-string-input>
     </div>
    <div class="hr"></div>
    <p class="px-1 label label-margin">{{ trans('help.cloudNotifications.notification') }}</p>
    <div [ngClass]="{ 'w-75p' : desktopView }">
        <app-name-value *ngIf="isChangelog" [nameText]="trans('help.cloudNotifications.toasterNotification')" [showUnderline]="desktopView && toasterNotificationEnabled">
            <app-toggle-button [buttonId]="'toaster'" [initialState]="toasterNotificationEnabled" (toggled)="onToasterNotificationEnabledValueChange($event.checked)"></app-toggle-button>
        </app-name-value>
        <app-name-value *ngIf="desktopView && isChangelog && toasterNotificationEnabled || !isChangelog" [nameText]="trans('general.showPreview')" [showUnderline]="false">
            <app-toggle-button [buttonId]="'preview'" [initialState]="showNotificationPreview" (toggled)="onPreviewValueChange($event.checked)"></app-toggle-button>
        </app-name-value>
        <!-- <app-name-value [nameText]="trans('help.cloudNotifications.pushNotification')" [showUnderline]="false">
            <app-toggle-button [buttonId]="'push'" [initialState]="pushNotificationEnabled" (toggled)="onPushNotificationEnabledValueChange($event.checked)"></app-toggle-button>
        </app-name-value> -->
    </div>
    <ng-container *ngIf="showVisibilitySelection">
        <div class="hr"></div>
        <p class="px-1 label label-margin">{{ trans('help.cloudNotifications.visibility') }}</p>
        <app-dropdown class="w-50p visibility py-025" [ngClass]="{ 'type-dropdown-mobile': !desktopView }" (valueChange)="onVisibilityValueChange($event)" [items]="visibilityValueList" [value]="visibilityValue" size="small"></app-dropdown>
        <div [ngClass]="{ 'w-75p' : desktopView }">
            <app-name-value [nameText]="'Cascade visibility to subcompanies'" [showUnderline]="false">
                <app-toggle-button [buttonId]="'cascade'" [initialState]="visibilityValueCascaded" (toggled)="onVisibilityCascadedValueChange($event.checked)"></app-toggle-button>
            </app-name-value>
        </div>
    </ng-container>
</div>
<ng-template appTeleport="footer">
    <app-desktop-footer *ngIf="desktopView" [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
</ng-template>