import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from 'src/api/v3/common';
import { HelpComponent } from './help.component';
import { TicketComponent } from './ticket/ticket.component';
import { ChangelogComponent } from 'src/app/company/pages/changelog/changelog.component';
import { ChangelogItemComponent } from 'src/app/company/pages/changelog/changelog-item/changelog-item.component';

@Component({
    selector: 'app-help-container',
    imports: [RouterModule],
    template: `<router-outlet></router-outlet>`,
    styles: ``
})
export class HelpContainerComponent {
  constructor() {
    console.log('help container +');
  }
}

export const routes: Routes = [
  { path: '', component: HelpContainerComponent, children: [
    { path: '', component: HelpComponent, data: { animation: 1 } },
    { path: 'ticket', component: TicketComponent, data: { animation: 2 } },
  ]},
  { path: 'changelog', component: ChangelogComponent, data: { animation: 2 } },
  { path: 'changelog/new', component: ChangelogItemComponent, data: { animation: 3 } },
  { path: 'changelog/edit/:changelogId', component: ChangelogItemComponent, data: { animation: 3 } },
  { path: 'changelog/notification/edit/:notificationId', component: ChangelogItemComponent, data: { animation: 3 } },
];