import http from '../http';
import { CloudNotification } from './cloud-notifications';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';

export type ChangelogContentItem = { type: string, text: string };

export type Changelog = {
    id: number,
    headline: string | null,
    content: ChangelogContentItem[],
    date: number,
    video_url: string,
    cloud_notification?: CloudNotification | null,
    created_at: number;
    updated_at: number;
}

export type GetChangelogsRequest =      { loaded: number; };
export type GetChangelogRequest =       { id: number; };
export type DeleteChangelogRequest =    { id: number; };

export type CreateChangelogRequest = {
    headline: string | null,
    content: ChangelogContentItem[],
    date: number,
    video_url: string,
    cloud_notification_enabled: boolean,
    send_push: boolean,
    visibility: number,
};

export type UpdateChangelogRequest = {
    id: number,
    headline: string | null,
    content: ChangelogContentItem[],
    date: number,
    video_url: string,
    cloud_notification_enabled: boolean,
    send_push: boolean,
    visibility: number,
};

export type GetChangelogResponse =          ErrorResponse | (BasicSuccessResponse & { data: Changelog });
export type CreateChangelogResponse =       ErrorResponse | (BasicSuccessResponse & { data: Changelog });
export type UpdateChangelogResponse =       ErrorResponse | (BasicSuccessResponse & { data: Changelog });
export type GetChangelogsResponse =         ErrorResponse | (BasicSuccessResponse & { data: Changelog[], hasMore: boolean });
export type GetActiveChangelogsResponse =   ErrorResponse | (BasicSuccessResponse & { data: Changelog[] });

const getChangelog =    (req: GetChangelogRequest) =>       http.get<GetChangelogResponse, URLSearchParams>('/v3/api/changelogs/get', objectToParams(req));
const getChangelogs =   (req: GetChangelogsRequest) =>      http.get<GetChangelogsResponse, URLSearchParams>('/v3/api/changelogs/all', objectToParams(req));
const createChangelog = (req: CreateChangelogRequest) =>    http.post<CreateChangelogResponse, CreateChangelogRequest>('/v3/api/changelogs/create', req);
const updateChangelog = (req: UpdateChangelogRequest) =>    http.post<UpdateChangelogResponse, UpdateChangelogRequest>('/v3/api/changelogs/update', req);
const deleteChangelog = (req: DeleteChangelogRequest) =>    http.delete<ErrorResponse | BasicSuccessResponse, DeleteChangelogRequest>('/v3/api/changelogs/delete', req);

export default {
    getChangelog,
    getChangelogs,
    createChangelog,
    updateChangelog,
    deleteChangelog,
};
