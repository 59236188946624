import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponse } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { SmallSystemData } from 'src/api/v3/system';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { TEditableComponent } from 'src/app/models/editable-component';
import { DeviceService } from 'src/app/services/device.service';
import { LocatorService } from 'src/app/services/locator.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { BackButtonComponent } from 'src/app/ui/back-button/back-button.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { DropdownComponent } from 'src/app/ui/dropdown/dropdown.component';
import { TagInputComponent } from 'src/app/ui/tag-input/tag-input.component';
import { TextEditComponent } from 'src/app/ui/text-edit/text-edit.component';

@Component({
    selector: 'app-device-information',
    templateUrl: './device-information.component.html',
    styleUrls: ['./device-information.component.scss'],
    imports: [CommonModule, BackButtonComponent, TextEditComponent, TagInputComponent, DropdownComponent, ButtonSingleLineArrowComponent]
})
export class DeviceInformationComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('tags') tags: TagInputComponent;

  public readonly desktopView = this.platform.isDesktopView();
  public readonly canSeeTags = this.us.currentUser.permissions?.permissions.tags.view;
  public readonly canEditTags = this.us.currentUser.permissions?.permissions.tags.edit;
  public readonly canEdit = this.us.currentUser.permissions?.permissions.unassigned_devices.edit;
  public readonly canSeeCompany = this.us.currentUser.permissions.permissions.company_settings.view;
  public readonly canCreateTags = this.us.currentUser.permissions.permissions.tags.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);

  private deviceIdChangeSubscription = this.route.params.subscribe((params) => {
    this.revert();
    const deviceId = Number(params.deviceId);
    if (deviceId && !isNaN(deviceId)) {
      let device = (LocatorService.injector.get(SystemService)).systemsWithDevices.find(d => d.registration_id === deviceId && d.system_id === null);
      if ( !device ) {
        device = (LocatorService.injector.get(SystemService)).filteredSystemsWithDevices.find(d => d.registration_id === deviceId && d.system_id === null);
      }
      if ( !device ) {
        this.backButtonPressed();
        return;
      }
      this.device = device;
      this.editSystemService.beginComponentEdit(TEditableComponent.RegisteredDevice, this.device.registration_id, this.device);
    }
  });
  public saving = false;
  public device: SmallSystemData;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageboxService,
    private sanitizer: DomSanitizer,
    private deviceService: DeviceService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    if ( !this.desktopView ) {
      this.headerBar.showHeader({
        headerText: '',
        backUrl: '*',
      });
      this.footerBar.showFooter(this.trans('general.ok'), '', true, false);
      this.footerBar.onButton1Click = () => {
        history.back();
      };
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.deviceIdChangeSubscription?.unsubscribe();
    this.revert();
  }

  private revert() {
    if ( this.editSystemService.hasChanged(TEditableComponent.RegisteredDevice) ) {
      const before = this.editSystemService.getComponentBeforeModification(TEditableComponent.RegisteredDevice) as SmallSystemData;
      this.device.tags = before.tags;
      this.device.company_id = before.company_id;
      this.device.companyName = before.companyName;
    }
    this.editSystemService.endComponentEdit(TEditableComponent.RegisteredDevice);
    this.footerBar.hideFooter();
  }

  public backButtonPressed() {
    history.back();
  }

  public openAdvancedSettings() {
    this.deviceService.deviceData = {
      adminCode: '123456',
      hwId: 0,
      ipcom: 0,
      mpass: '123456',
      uid: this.device.imei,
      id: this.device.registration_id,
    };
    localStorage.setItem('ref', 'device-information');
    this.systems.setCurrentArea(0);
    this.systems.setCurrentSystem(null);
    if ( this.desktopView ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'systems', this.device.registration_id, 'device', 'advanced-settings']);
    } else {
      this.router.navigate([...this.g.resolveRootRoute(), this.device.registration_id, 'device', 'advanced-settings']);
    }
  }

  public deleteDevice() {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('systems.deviceRegistration.deleteRegistrationQuestion')} <strong>${this.device.imei}</strong>. ${this.trans('general.areYouSure')}`
    );
    this.msg.open({
      buttons: this.msg.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.msg.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        yesClickSubscription?.unsubscribe();
        requests.system.deleteDeviceRegistration({id: this.device.registration_id}).toPromise().then(result => {
          if(result.success) {
            const systemService = LocatorService.injector.get(SystemService);
            systemService.removeDeviceLocally(this.device.registration_id);
            this.backButtonPressed();
          } else {
            this.toaster.postError(`${this.trans('systems.errors.failedToDelete')}.`);
          }
        });
      });
    });
  }

  private async doSave() {
    if ( this.saving ) { return; }
    this.saving = true;
    try {
      const result = await requests.system.saveRegisteredDevice({id: this.device.registration_id, company_id: this.device.company_id, tags: this.device.tags.map(t => t.id)}).toPromise();
      if ( !result.success ) {
        this.toaster.postError((result as ErrorResponse).error);
      } else {
        this.editSystemService.beginComponentEdit(TEditableComponent.RegisteredDevice, this.device.registration_id, this.device);
        this.footerBar.hideFooter();
      }
    } catch (e) { }
    this.saving = false;
  }

  public changed() {
    if ( this.editSystemService.hasChanged(TEditableComponent.RegisteredDevice) ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      this.footerBar.onButton1Click = () => {
        this.backButtonPressed();
      };
      this.footerBar.onButton2Click = () => {
        this.doSave();
      };
    } else {
      this.footerBar.hideFooter();
    }
  }

  public rebuildCompanyName() {
    this.device.companyName = this.companyService.listStorage.find(c => c.value === this.device.company_id).label as string;
  }

  public copyImei(imei: string|undefined) {
    if ( imei === undefined ) { return; }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = imei;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toaster.postSuccess(this.trans('configurators.labels.uidCopy'));
  }

  public setupSystem() {
    this.router.navigate([...this.g.resolveRootRoute(), 'new-system', 'add-system'], { state: { imei: this.device.imei }});
  }
}
