import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable } from '@angular/core';
import { MessageBoxButtons, MessageBoxIcon, MessageBoxInputs } from '../popups/messagebox/messagebox-config';
import { MessageboxComponent } from '../popups/messagebox/messagebox.component';
import { LocatorService } from './locator.service';
import { RefreshService } from './refresh.service';


@Injectable({
  providedIn: 'root'
})
export class MessageboxService {
  private messageboxComponentRef: ComponentRef<MessageboxComponent>;
  private instance: MessageboxComponent;
  public buttons = MessageBoxButtons;
  public iconType = MessageBoxIcon;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private refresher: RefreshService,
  ) {}

  public open(messageboxInputs: MessageBoxInputs, callback?: (instance: MessageboxComponent) => void) {
    const refresherIsEnabled = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.messageboxComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(MessageboxComponent)
      .create(LocatorService.injector);
    this.instance = this.messageboxComponentRef.instance;

    this.instance.buttons = messageboxInputs.buttons;
    this.instance.headerText = messageboxInputs.headerText;
    this.instance.messageContent = messageboxInputs.messageContent;
    this.instance.youtubeVideoId = messageboxInputs.youtubeVideoId;
    this.instance.iconType = messageboxInputs.iconType;
    this.instance.button1Text = messageboxInputs.button1Text;
    this.instance.button2Text = messageboxInputs.button2Text;
    this.instance.showCloseButton = messageboxInputs.showCloseButton !== undefined ? messageboxInputs.showCloseButton : true;
    this.instance.canCloseByOverlay = messageboxInputs.canCloseByOverlay !== undefined ? messageboxInputs.canCloseByOverlay : false;
    this.instance.messageboxComponentRef = this.messageboxComponentRef;
    this.instance.alignCenter = messageboxInputs.alignCenter;
    this.instance.mustScrollToBottom = messageboxInputs.mustScrollToBottom;
    this.instance.removeSidePadding = messageboxInputs.removeSidePadding;
    this.instance.checkboxes = messageboxInputs.checkboxes ?? [];
    this.instance.subtextTopRight = messageboxInputs.subtextTopRight;
    this.instance.subtextTopLeft = messageboxInputs.subtextTopLeft;
    this.instance.subtextBottomLeft = messageboxInputs.subtextBottomLeft;
    this.instance.subtextBottomRight = messageboxInputs.subtextBottomRight;
    this.instance.placeButtonsVertically = messageboxInputs.placeButtonsVertically;
    const closeSubscriber = this.instance.messageboxClose.subscribe(() => {
      if ( refresherIsEnabled ) { this.refresher.enableRefresher(); }
      closeSubscriber.unsubscribe();
    });

    if(callback) { callback(this.instance); };

    this.appRef.attachView(this.messageboxComponentRef.hostView);

    const domElem = (this.messageboxComponentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);
  }

  public isOpen(): boolean {
    return !!this.instance;
  }

  public close() {
    if ( !this.isOpen() ) { return; }
    this.instance.clickCancel();
  }
}
