import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-signal-level-v2',
    templateUrl: './signal-level-v2.component.html',
    styleUrls: ['./signal-level-v2.component.scss'],
    imports: [CommonModule]
})
export class SignalLevelV2Component implements OnInit {
  @Input() currentLevel = 0;
  @Input() width = 25;
  @Input() height = 25;

  constructor() {}

  ngOnInit(): void {}
}
