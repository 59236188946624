import http from '../http';
import { Changelog } from './changelog';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';

export type CloudNotificationType = 'info' | 'warning' | 'error' | 'news';

export enum VisibilityValue {
    SystemMaster = 1 << 0, // 1
    Company = 1 << 1, // 2
    Installer = 1 << 2, // 4
    CompanyInstaller = Company | Installer, // 2 | 4 = 6
    CompanyInstallerSystemMaster = Company | Installer | SystemMaster, // 2 | 4 | 1 = 7
    All = 1 << 5, // 32
}

export const CASCADE_FLAG = 1 << 6; // 64

export type GetCloudNotificationRequest =       { id: number };
export type GetCloudNotificationsRequest =      { loaded: number; };
export type DeleteCloudNotificationRequest =    { id: number; };

export type CloudNotification = {
    id: number,
    type: CloudNotificationType,
    content: string | null,
    origin: string | null,
    valid_from: number,
    valid_until: number | null,
    video_url: string | null,
    active: boolean,
    visibility: number | null,
    company_id: number,
    created_at: number;
    updated_at: number;
    changelog?: Changelog | null;
};

export type CreateCloudNotificationRequest = {
    type: CloudNotificationType,
    content: string,
    valid_from: number,
    valid_until: number | null,
    video_url: string | null,
    visibility: number | null,
    send_push: boolean,
};

export type UpdateCloudNotificationRequest = {
    id: number,
    type?: CloudNotificationType,
    content?: string,
    valid_from?: number,
    valid_until?: number | null,
    video_url?: string | null,
    visibility?: number | null,
    send_push?: boolean,
    active?: boolean,
};

export type GetCloudNotificationResponse =                  ErrorResponse | (BasicSuccessResponse & { data: { hasAccess: boolean, canEdit: boolean, notification: CloudNotification } });
export type CreateCloudNotificationResponse =               ErrorResponse | (BasicSuccessResponse & { data: CloudNotification });
export type UpdateCloudNotificationResponse =               ErrorResponse | (BasicSuccessResponse & { data: CloudNotification });
export type DeleteCloudNotificationResponse =               ErrorResponse | (BasicSuccessResponse & { data: CloudNotification });
export type GetCloudNotificationsResponse =                 ErrorResponse | (BasicSuccessResponse & { data: CloudNotification[], hasMore: boolean });
export type GetActiveCloudNotificationsResponse =           ErrorResponse | (BasicSuccessResponse & { data: CloudNotification[] });

const getCloudNotification =    (req: GetCloudNotificationRequest) =>       http.get<GetCloudNotificationResponse, URLSearchParams>('/v3/api/cloud-notifications/get', objectToParams(req));
const getCloudNotifications =   (req: GetCloudNotificationsRequest) =>      http.get<GetCloudNotificationsResponse, URLSearchParams>('/v3/api/cloud-notifications/all', objectToParams(req));
const createCloudNotification = (req: CreateCloudNotificationRequest) =>    http.post<CreateCloudNotificationResponse, CreateCloudNotificationRequest>('/v3/api/cloud-notifications/create', req);
const updateCloudNotification = (req: UpdateCloudNotificationRequest) =>    http.post<UpdateCloudNotificationResponse, UpdateCloudNotificationRequest>('/v3/api/cloud-notifications/update', req);
const deleteCloudNotification = (req: DeleteCloudNotificationRequest) =>    http.delete<DeleteCloudNotificationResponse, DeleteCloudNotificationRequest>('/v3/api/cloud-notifications/delete', req);

export default {
    getCloudNotification,
    getCloudNotifications,
    createCloudNotification,
    updateCloudNotification,
    deleteCloudNotification,
};
