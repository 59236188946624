import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-company-page-layout',
    templateUrl: './company-page-layout.component.html',
    styleUrls: ['./company-page-layout.component.scss'],
    imports: [CommonModule, RouterOutlet]
})
export class CompanyPageLayoutComponent implements OnInit {
  @Input() gridTemplateColumns?: string;
  public hasSide = false;

  constructor() {}

  ngOnInit(): void {}
}
