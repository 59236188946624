import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TZoneData } from 'src/app/models/zone-data';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AreaService } from 'src/app/services/area.service';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from 'src/app/general/checkbox/checkbox.component';

@Component({
    selector: 'app-bypass-zones',
    templateUrl: './bypass-zones.component.html',
    styleUrls: ['./bypass-zones.component.scss'],
    animations: [
        trigger('showWindow', [
            state('hidden', style({ opacity: 0, transform: 'translateY(100%)' })),
            state('visible', style({ opacity: 1, transform: 'translateY(0px)' })),
            transition('hidden => visible', animate('0.2s linear')),
        ]),
    ],
    imports: [CommonModule, CheckboxComponent]
})
export class BypassZonesComponent extends LanguageAware implements OnInit {
  public isVisible = 'hidden';
  public allSelected = false;
  @Input() dataSource = [];
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
  @Output() confirmClicked: EventEmitter<void> = new EventEmitter();

  constructor(cdRef: ChangeDetectorRef, private areaService: AreaService) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.tag = 'Bypass';
  }

  ngOnInit(): void {
    const that = this;
    setTimeout(() => {
      that.isVisible = 'visible';
    }, 50);
  }

  public onCancelClick() {
    this.areaService.zoneBypassCancelled();
    this.cancelClicked.emit();
  }

  public onConfirmClick() {
    this.areaService.zoneBypassConfirmed();
    this.confirmClicked.emit();
  }

  public onZoneSelected(zoneNumber: number, value: boolean) {
    const zone = this.dataSource.find((z) => z.number === zoneNumber);
    if (zone === undefined) {
      return;
    }
    zone.isChecked = !value;
    this.checkForAllChecked();
  }

  public onAllClicked(value: boolean) {
    this.allSelected = !value;
    for (const iZone of this.dataSource) {
      iZone.isChecked = this.allSelected;
    }
  }

  private checkForAllChecked() {
    let allChecked = true;
    for (const iZone of this.dataSource) {
      if (!iZone.isChecked) {
        allChecked = false;
        break;
      }
    }

    this.allSelected = allChecked;
  }
}
