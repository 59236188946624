<div class="page">
  <app-company-header></app-company-header>
  <div class="mainContainer grid gap-1 h-full grid-cols-2">
    <div *ngIf="columnCount !== 1" class="h-full overflow-y-auto" (scroll)="contentIsScrolling($event)" #sidebar>
      <div class="sidebar bg-white">
        <app-cp-system-list-item *ngFor="let iSystem of systems.systems; let i = index" [systemItem]='iSystem' [showBookmark]="false" [showArrow]="false"  [ngClass]="{'selected': systems.activeSystem?.id === iSystem.id}"></app-cp-system-list-item>
        <div class="history-link" *ngIf="us.currentUser?.historySystems?.length > 0">
          <div class="separator16"></div>
          <a (click)="openHistorySystems()">{{trans('systems.titles.history')}}</a>
          <div class="separator16"></div>
        </div>
      </div>
    </div>
    <div class="h-full overflow-y-auto" [ngClass]="{ 'col-span-2': columnCount === 1 }">
      <app-message-banner></app-message-banner>
      <div [ngClass]="{'page-grid': true,
        'page-grid-one': columnCount === 1,
        'page-grid-two': columnCount === 2,
        'page-grid-three': columnCount === 3
      }">
        <ng-container *ngIf="columnCount === 2"><div></div></ng-container>
        <ng-container *ngIf="columnCount === 3">
          <ng-container *ngIf="!isCenterRouteActive; else routerCenter">
            <div class="column">
              <h4>{{trans('systems.titles.menu')}}</h4>
              <div class='white_round' (click)="setRef()">
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.information()' [routerLink]="[systems.activeSystem?.id, 'settings', 'info']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.systemInformation')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf="systems.activeSystemHelper.can.see.notificationSettings()" [routerLink]="[systems.activeSystem?.id, 'settings', 'system-notifications']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.notifications')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.areas()' [routerLink]="[systems.activeSystem?.id, 'settings', 'areas']" [replaceUrl]="!isOnHomeRoute">{{ systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions') }}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.zones()' [routerLink]="[systems.activeSystem?.id, 'settings', 'zones']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.zones')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.outputs()' [routerLink]="[systems.activeSystem?.id, 'settings', 'outputs']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.outputs')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.sensors()' [routerLink]="[systems.activeSystem?.id, 'settings', 'sensors']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.sensors')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.resetFireSensors()' [routerLink]="[systems.activeSystem?.id, 'settings', 'fire-reset']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.resetFireSensors')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.users()' [routerLink]="[systems.activeSystem?.id, 'settings', 'users']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.users')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.cameras() || systems.activeSystemHelper.can.add.cameras()' [routerLink]="[systems.activeSystem?.id, 'settings', 'camera-list']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.myCameras')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.advancedSettings()' [routerLink]="[systems.activeSystem?.id, 'settings', 'advanced']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.advancedSettings')}}</app-button-single-line-arrow>
              </div>
              <ng-container *ngIf='systems.activeSystemHelper.can.transferOwnership() || systems.activeSystemHelper.can.transferDevice() || systems.activeSystemHelper.can.transferToCompany()'>
                <div class="separator32"></div>
                <div class="white_round">
                  <app-button-single-line-arrow *ngIf="systems.activeSystemHelper.can.transferOwnership() || systems.activeSystemHelper.can.transferDevice()" [routerLink]="[systems.activeSystem?.id, 'settings', 'transfer-type']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.menu.transferSystem')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf="systems.activeSystemHelper.can.transferToCompany()" [routerLink]="[systems.activeSystem?.id, 'settings', 'transfer-to-company']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.transferToCompany')}}</app-button-single-line-arrow>
                </div>
              </ng-container>
              <div class="separator32"></div>
              <div class="white_round" *ngIf="systems.activeSystemHelper.can.synchronize() || systems.activeSystemHelper.can.delete.system()">
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.synchronize()' [icon]="'assets/images/question_round.svg'" [routerLink]="[systems.activeSystem?.id, 'settings', 'reread']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.outOfSync')}}</app-button-single-line-arrow>
                <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.delete.system()' [routerLink]="[systems.activeSystem?.id, 'settings', 'delete']" [replaceUrl]="!isOnHomeRoute">{{trans('systems.titles.removeSystem')}}</app-button-single-line-arrow>
              </div>
              <div class="separator32"></div>
            </div>
          </ng-container>
          <ng-template #routerCenter>
            <div class="column">
              <router-outlet name="center"></router-outlet>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="columnCount === 1"><div></div></ng-container>
        <div class="column">
          <ng-container *ngIf="isOnHomeRoute && columnCount === 1; else pageRouter">
            <div class="noSystemsContainer">
              <h1>{{trans('systems.noSystemsAdded')}}</h1>
              <h3 *ngIf="!us.currentUser?.permissions.permissions.dev_setup_templates.execute">{{trans('systems.addFirstSystem')}}</h3>
              <div id="home-empty-plus" (click)="newSystemClick()"><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
              <p class="addSystem" (click)="newSystemClick()">{{trans('systems.titles.' + (us.currentUser?.permissions.permissions.dev_setup_templates.execute ? 'setupDevice' : 'addSystem'))}}</p>
              <a (click)="openHistorySystems()" class="history-link" *ngIf="us.currentUser?.historySystems?.length > 0">{{trans('systems.titles.history')}}</a>
            </div>
          </ng-container>
          <ng-template #pageRouter>
            <ng-container *ngIf="hasSystems && isCenterRouteActive && columnCount === 1; else homeScreenRouter">
              <router-outlet name="center"></router-outlet>
              <app-footer></app-footer>
            </ng-container>
            <ng-template #homeScreenRouter>
              <h4 *ngIf="!isCenterRouteActive && isOnHomeRoute">{{trans('systems.titles.homeScreen')}}</h4>
              <router-outlet></router-outlet>
            </ng-template>
          </ng-template>
        </div>
        <ng-container *ngIf="columnCount === 1"><div></div></ng-container>
      </div>
    </div>
  </div>
  <app-portal name="footer"></app-portal>
</div>
