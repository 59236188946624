import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { AccountSwitcherComponent } from 'src/app/login/components/account-switcher/account-switcher.component';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-company-header-settings',
    templateUrl: './company-header-settings.component.html',
    styleUrls: ['./company-header-settings.component.scss'],
    imports: [RouterLink, InlineSVGModule, AccountSwitcherComponent]
})
export class CompanyHeaderSettingsComponent implements OnInit {
  @Output() showSettingsChange = new EventEmitter<boolean>();

  constructor(
    private elementRef: ElementRef,
    public login: LoginService,
    public lang: LanguageService,
    private router: Router,
  ) {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'toggleSettings') {
      this.showSettingsChange.emit(false);
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showSettingsChange.emit(false);
    }
  }

  ngOnInit(): void {
  }

  onAddAccountClick() {
    this.router.navigate(['/login/email']);
  }
}
