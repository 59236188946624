import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { TBackgroundType } from 'src/app/models/background-types';
import { DateService } from 'src/app/services/date.service';
import { LanguageAware } from '../language-aware';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/ui/button/button.component';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
    imports: [CommonModule, ButtonComponent]
})
export class VersionComponent extends LanguageAware implements OnInit {
  public oldVersion = null;
  public newerVersion = null;

  constructor(cdRef: ChangeDetectorRef, public dt: DateService) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      headerText: this.trans('help.appVersionTitle'),
      backUrl: '*',
    });
    this.l.log('Current ver: ', this.tag, { major: AppSettings.appVersionMajor, minor: AppSettings.appVersionMinor, build: AppSettings.appVersionBuild, date: AppSettings.appVersionDate });
    const savedOldVersion = localStorage.getItem('last_version');
    this.l.log('Old version: ', this.tag, {savedOldVersion});
    const savedOldBuild = localStorage.getItem('last_version_build');
    this.l.log('Old build: ', this.tag, {savedOldBuild});
    if (savedOldBuild !== null && parseInt(savedOldBuild, 10) !== AppSettings.appVersionBuild) {
      this.oldVersion = JSON.parse(savedOldVersion);
    }

    localStorage.setItem('last_version_build', AppSettings.appVersionBuild.toString());
    localStorage.setItem('last_version',
      JSON.stringify({
        major: AppSettings.appVersionMajor,
        minor: AppSettings.appVersionMinor,
        build: AppSettings.appVersionBuild,
        date: AppSettings.appVersionDate,
      })
    );

    this.l.log('Version from server: ', this.tag, this.us.appVersionDetails);
    if (this.us.appVersionDetails !== null && AppSettings.appVersionBuild < this.us.appVersionDetails.build) {
      this.newerVersion = this.us.appVersionDetails;
    }
  }

  ngOnInit(): void {}

  public getTitle(): string {
    return AppSettings.title;
  }

  public getCurrentVersion(): string {
    return AppSettings.appVersionMajor + '.' + AppSettings.appVersionMinor;
  }

  public getBuild(): string {
    return AppSettings.appVersionBuild.toString();
  }

  public getDate(): number {
    return AppSettings.appVersionDate;
  }

  public clickUpdate() {
    location.reload();
  }
}
