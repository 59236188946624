import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { IPopup } from '../IPopup';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-base-popup',
    templateUrl: './base-popup.component.html',
    styleUrls: ['./base-popup.component.scss'],
    imports: [CommonModule]
})
export class BasePopupComponent implements OnInit, IPopup, AfterViewInit, OnDestroy {
  private accountChangeSubscription: Subscription;
  @Input() popupVisible = true;
  @Input() canCancel = true;
  @Input() useDefaultContainerClass = true;
  @Output() popupCancelled: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();
  public popupName = '';
  constructor(private auth: AuthService) {
    this.accountChangeSubscription = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.canCancel = true;
      this.cancelPopup();
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  public cancelPopup() {
    if (!this.canCancel) {
      return;
    }
    this.popupCancelled.emit();
    this.hidePopup();
  }

  public hidePopup() {
    this.popupVisible = false;
    this.closed.emit();
  }

  public show() {
    this.popupVisible = true;
  }

  public closeWindow() {} // turini uzpildys kiti popup, kurie naudasi base popup'u

  ngOnDestroy() {
    this.accountChangeSubscription.unsubscribe();
  }
}
