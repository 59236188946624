import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponse } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { SensorService } from 'src/app/api/system/sensor.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TThermostatData } from 'src/app/models/thermostat-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
    selector: 'app-home-thermostat-item',
    templateUrl: './home-thermostat-item.component.html',
    styleUrls: ['./home-thermostat-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('actualFade', [
            state('in', style({ opacity: 1 })),
            state('out', style({ opacity: 0 })),
            transition('in => out', animate('0.2s')),
            transition('out => in', animate('0.2s')),
        ]),
        trigger('targetFade', [
            state('in', style({ opacity: 1 })),
            state('out', style({ opacity: 0 })),
            transition('in => out', animate('0.2s')),
            transition('out => in', animate('0.2s')),
        ]),
    ],
    imports: [CommonModule, InlineSVGModule]
})
export class HomeThermostatItemComponent extends LanguageAware implements OnInit {
  private EDIT_MODE_TIMEOUT = 8000;
  private clickTimer = null;
  private tempBefore = 0;
  public isInEditMode = false;
  private editModeTimer = null;
  public actualTempState = 'in';
  public targetTempState = 'out';
  public sensorName = '';
  @Input() thermostat: TThermostatData;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    public senS: SensorService,
    private ar: ActivatedRoute,
  ) {
    super(cdRef, { noVisualModifications: true });
  }

  ngOnInit(): void {
    this.tag = 'home-therm-item-' + this.thermostat.queueNo;
    const sensorNo = this.thermostat.assignedSensors[this.thermostat.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === sensorNo);
    if (sensor === undefined) {
      this.sensorName = this.thermostat.name;
    } else {
      this.sensorName = sensor.name;
    }
  }

  public getThermostatType(): number {
    const sensorNo = this.thermostat.assignedSensors[this.thermostat.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === sensorNo);
    if (sensor === undefined) {
      return 0;
    }
    return sensor.type;
  }

  public plusClicked() {
    const that = this;
    if (this.clickTimer !== null) {
      clearTimeout(this.clickTimer);
    }
    if (this.goIntoEditMode()) {
      return;
    }
    this.thermostat.temperatures[this.thermostat.activeSensor - 1] += 0.1;
    if (this.thermostat.temperatures[this.thermostat.activeSensor - 1] > GlobalService.MAX_THERMOSTAT_TEMPERATURE) {
      this.thermostat.temperatures[this.thermostat.activeSensor - 1] = GlobalService.MAX_THERMOSTAT_TEMPERATURE;
    }
    this.clickTimer = setTimeout(() => {
      that.submitNewTemp();
    }, 2000);
  }

  public minusClicked() {
    const that = this;
    if (this.clickTimer !== null) {
      clearTimeout(this.clickTimer);
    }
    if (this.goIntoEditMode()) {
      return;
    }
    this.thermostat.temperatures[this.thermostat.activeSensor - 1] -= 0.1;
    if (this.thermostat.temperatures[this.thermostat.activeSensor - 1] < GlobalService.MIN_THERMOSTAT_TEMPERATURE) {
      this.thermostat.temperatures[this.thermostat.activeSensor - 1] = GlobalService.MIN_THERMOSTAT_TEMPERATURE;
    }
    this.clickTimer = setTimeout(() => {
      that.submitNewTemp();
    }, 2000);
  }

  private submitNewTemp() {
    const that = this;
    const activeSystemId = this.systems.activeSystem.id;
    this.thermostat.temperatures[this.thermostat.activeSensor - 1] = this.parseTemperatureNumber(this.thermostat.temperatures[this.thermostat.activeSensor - 1]);
    this.log('Saugom termostata', this.thermostat);
    this.clickTimer = null;
    requests.system.thermostat.save({
      systemId: activeSystemId,
      thermostatId: this.thermostat.id,
      name: this.thermostat.name,
      value: this.thermostat.temperatures[this.thermostat.activeSensor - 1],
    }).subscribe(
      (result) => {
        if (!result.success) {
          that.revertChanges(activeSystemId, that.thermostat.id, that.tempBefore);
          that.toaster.postError((result as ErrorResponse).error);
        } else {
          that.systems.saveActiveSystem(activeSystemId);
          that.log('Sėkmingai išsaugota.');
        }
      },
      (error) => {
        that.revertChanges(activeSystemId, that.thermostat.id, that.tempBefore);
      }
    );
  }

  private parseTemperatureNumber(num: number): number {
    return parseFloat(num.toFixed(1));
  }

  private revertChanges(systemId: number, thermId: number, temp: number) {
    const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
    if (system === undefined) {
      return;
    }
    const therm = system.thermostats.find((t) => t.id === thermId);
    if (therm === undefined) {
      return;
    }
    therm.temperatures[therm.activeSensor - 1] = temp;
    this.systems.saveActiveSystem(systemId);
  }

  public getTaragetTemperature(therm: TThermostatData) {
    return Math.round(therm.temperatures[therm.activeSensor - 1] * 10) / 10;
  }

  public getSensorTemperatureBase(therm: TThermostatData) {
    const sensorNumber = therm.assignedSensors[therm.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === sensorNumber);
    if (sensor === undefined) {
      return '--';
    }
    if (sensor.temperature === '') {
      return '--';
    }
    return sensor.temperature.includes('.') ? sensor.temperature.split('.')[0] : sensor.temperature;
  }
  public getSensorTemperatureDecimals(therm: TThermostatData) {
    const sensorNumber = therm.assignedSensors[therm.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === sensorNumber);
    if (sensor === undefined) {
      return '-';
    }
    if (sensor.temperature.includes('/')) {
      return '';
    }
    return sensor.temperature.includes('.') ? sensor.temperature.split('.')[1] : '0';
  }

  public getThermostatTemperatureBase(therm: TThermostatData) {
    const rounded = Math.round(therm.temperatures[therm.activeSensor - 1] * 10) / 10;
    const stringTemp = rounded.toString();
    return stringTemp.includes('.') ? stringTemp.split('.')[0] : stringTemp;
  }
  public getThermostatTemperatureDecimals(therm: TThermostatData) {
    const rounded = Math.round(therm.temperatures[therm.activeSensor - 1] * 10) / 10;
    const stringTemp = rounded.toString();
    return stringTemp.includes('.') ? stringTemp.split('.')[1] : '0';
  }

  public openThermostat(therm: TThermostatData) {
    if (!this.systems.activeSystemHelper.can.edit.thermostats()) {
      return;
    }
    this.es.beginComponentEdit(TEditableComponent.Thermostat, therm.id, therm);
    this.router.navigate(['settings', 'system-thermostat'], { relativeTo: this.ar });
  }

  public isOutputOn(therm: TThermostatData) {
    const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === therm.assignedOutput);
    if (pgm === undefined) {
      return false;
    }
    return pgm.on;
  }

  private goIntoEditMode(): boolean {
    if (this.editModeTimer !== null) {
      clearTimeout(this.editModeTimer);
    }
    const that = this;
    this.editModeTimer = setTimeout(() => {
      this.targetTempState = 'out';
      setTimeout(() => {
        that.isInEditMode = false;
        setTimeout(() => {
          that.actualTempState = 'in';
        }, 50);
      }, 200);
      that.editModeTimer = null;
    }, this.EDIT_MODE_TIMEOUT);

    if (!this.isInEditMode) {
      this.tempBefore = this.thermostat.temperatures[this.thermostat.activeSensor - 1];
      this.actualTempState = 'out';
      setTimeout(() => {
        that.isInEditMode = true;
        setTimeout(() => {
          that.targetTempState = 'in';
        }, 50);
      }, 200);
      return true;
    }
    return false;
  }
}
