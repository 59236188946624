import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-loader-round',
    templateUrl: './loader-round.component.html',
    styleUrls: ['./loader-round.component.scss'],
    imports: [CommonModule]
})
export class LoaderRoundComponent implements OnInit {
  @Input() displayText = '';
  @Input() isVisible = false;
  @Input() overlayIsGray = false;
  @Input() showCancelButton = false;
  @Input() cancelButtonText = '';
  @Input() isInline = false;
  @Input() height = 200;
  @Input() heightUnits: 'px' | 'em' = 'px';
  @Input() backgroundColor = '#FFFFFF';
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('messageContainer') messageContainer: ElementRef;
  public messages: { text: string; subText: string }[] = [];

  constructor() {}

  ngOnInit(): void {}

  public clearMessges() {
    this.messages = [];
  }

  public addMessage(text: string, subText: string) {
    this.messages.push({
      text,
      subText,
    });
  }
}
