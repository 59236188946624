import { ChangeDetectorRef, Component } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TAreaData } from 'src/app/models/area-data';
import { AreaService } from 'src/app/services/area.service';
import { DateService } from 'src/app/services/date.service';
import { PopupService } from 'src/app/services/popup.service';
import { AreaAlarmPopupComponent } from '../area-alarm-popup/area-alarm-popup.component';
import { PgmData } from 'src/app/models/pgm';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { ReactionService } from 'src/app/api/system/reaction.service';

@Component({
    selector: 'app-virtual-area-view',
    templateUrl: './virtual-area-view.component.html',
    styleUrls: ['./virtual-area-view.component.scss'],
    imports: [CommonModule, InlineSVGModule]
})
export class VirtualAreaViewComponent extends LanguageAware {
  public systemAreas: TAreaData[] = [];
  public isAlarmed = false;
  public activeGroupText = '';
  public activeLoopText = '';
  public alarmTime = 0;
  private visibleOutputs: PgmData[] = [];
  private activeSystemId = 0;
  private systemChangeListener = null;
  private takeActionButtonVisibilitySubscriber = null;

  constructor(
    cdRef: ChangeDetectorRef,
    public areaService: AreaService,
    public dt: DateService,
    private pp: PopupService,
    private reactionService: ReactionService
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    if(this.systems.activeSystem !== null) {
      this.systemAreas = this.systems.activeSystem.areas;
      this.checkIfSystemHasAlarmedLoops();
    }
    const that = this;
    this.systemChangeListener = this.systems.onActiveSystemChange.subscribe(() => {
      if ( !that.systems.activeSystem ) { return; }
      that.systemAreas = that.systems.activeSystem.areas;
      that.checkIfSystemHasAlarmedLoops();
      if(that.activeSystemId !== that.systems.activeSystem.id) {
        that.activeSystemId = that.systems.activeSystem.id;
      }
    });
    this.takeActionButtonVisibilitySubscriber = this.areaService.onTakeActionButtonVisibilityChanged.subscribe(() => {
      that.checkIfSystemHasAlarmedLoops();
    });
  }

  ngOnDestroy() {
    if (this.systemChangeListener !== null) {
      this.systemChangeListener.unsubscribe();
      this.systemChangeListener = null;
    }
    if (this.takeActionButtonVisibilitySubscriber !== null) {
      this.takeActionButtonVisibilitySubscriber.unsubscribe();
      this.takeActionButtonVisibilitySubscriber = null;
    }
  }

  private checkIfSystemHasAlarmedLoops() {
    if(this.systemAreas.length === 0) { return; }
    const alarmedAreas = this.systemAreas.filter(a => a.alarmed === true);
    alarmedAreas.sort((a, b) => a.alarmTime - b.alarmTime);
    this.isAlarmed = alarmedAreas.length > 0;
    if(this.isAlarmed) {
      this.rebuildStatusTime(alarmedAreas);
      this.rebuildActiveGroupText(alarmedAreas);
    } else {
      this.alarmTime = 0;
      this.activeGroupText = '';
      this.activeLoopText = '';
    }
    this.rebuildActiveLoopText();
  }

  private rebuildActiveGroupText(alarmedAreas: TAreaData[]) {
    if(this.systems.activeSystem.hwType !== 'FC') { return; }
    this.activeGroupText = '';
    if(alarmedAreas.length > 0) {
      for(let i = 0; i < alarmedAreas.length; i++) {
        if(i === 0) {
          this.activeGroupText += alarmedAreas[i].name;
        } else {
          this.activeGroupText += `, ${alarmedAreas[i].name}`;
        }
      }
    }
  }

  private rebuildActiveLoopText() {
    const events = this.systems.activeSystem.events;
    const activeLoops = [];
    const earliestAlarmTime = this.systemAreas.reduce((earliest, current) => {
      return current.statusTime > earliest.statusTime ? current : earliest;
    }).statusTime;

    for(let i = 0; i < events.length; i++) {
      if(events[i].time < earliestAlarmTime) { continue; }
      if(activeLoops.includes(events[i].areaInfo) || events[i].areaId > 0) { continue; }
      
      const reaction = this.reactionService.basicReactions.get(events[i].reaction);
      if(reaction && reaction.group === 'alarms') {
        activeLoops.push(events[i].areaInfo);
        if(this.alarmTime === 0 || this.alarmTime < events[i].time) {
          this.alarmTime = events[i].time;
        }
      }
    }
    if(activeLoops.length > 0) {
      this.activeLoopText = activeLoops.join(', ');
    }
  }

  private rebuildStatusTime(alarmedAreas: TAreaData[]) {
    if ( alarmedAreas.length === 0 ) { return; }
    this.alarmTime = alarmedAreas[alarmedAreas.length - 1].alarmTime;
  }

  private showAlarmWindow() {
    const activeAreas = this.systemAreas.filter(a => a.alarmed === true);
    if (activeAreas.length === 0 && this.activeLoopText === '') {
      return;
    }
    this.pp.openPopup(AreaAlarmPopupComponent, {
      paramSetter: (p) => {
        p.activeArea = activeAreas.length > 0 ? activeAreas[0] : this.systemAreas[0];
        p.fireInGroupsText = this.activeGroupText;
        p.fireInLoopsText = this.activeLoopText;
        p.visibleOutputs = this.visibleOutputs;
        p.disarmClicked.subscribe(() => { this.silenceAlarmedAreas(); p.closeWindow(); });
      },
      attachToClass: 'mobile-app'
    });
  }

  private silenceAlarmedAreas() {
    const alarmedAreas = this.systemAreas.filter(a => a.alarmed === true);
    if(alarmedAreas.length > 0) {
      const areaIds = alarmedAreas.map(a => a.id);
      this.areaService.resetStatusAreaAlarm(areaIds);
    } else {
      this.areaService.resetStatusAreaAlarm([this.systemAreas[0].id]);
    }
    this.activeLoopText = '';
  }

  public onAreaStatusClick() {
    if(!this.isAlarmed && this.activeLoopText === '') { return; }
    this.getVisibleOutputs();
    this.showAlarmWindow();
  }

  private getVisibleOutputs() {
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined || systemConfig.visibleOutputs.length === 0) {
      this.visibleOutputs = this.systems.activeSystem.pgms.filter((o) => !o.control_area && o.enabled);
      return;
    }
    this.visibleOutputs = [];
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.enabled && !iPgm.control_area && systemConfig.visibleOutputs.indexOf(iPgm.queue_no) !== -1) {
        this.visibleOutputs.push(iPgm);
      }
    }
  }
  
}
